import {gql} from '@deezer/graphql-client';
import type * as ApolloReactCommon from '@deezer/graphql-client';
import * as ApolloReactHooks from '@deezer/graphql-client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends {[key: string]: unknown}> = {[K in keyof T]: T[K]};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
	[SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
	[SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<T extends {[key: string]: unknown}, K extends keyof T> = {
	[_ in K]?: never;
};
export type Incremental<T> =
	| T
	| {[P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never};
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
	ID: {input: string; output: string};
	String: {input: string; output: string};
	Boolean: {input: boolean; output: boolean};
	Int: {input: number; output: number};
	Float: {input: number; output: number};
	Date: {input: Date; output: Date};
}

export interface Access {
	readonly __typename: 'Access';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly hasAccess: Scalars['Boolean']['output'];
}

export interface AdSection {
	readonly __typename: 'AdSection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly androidId: Maybe<Scalars['String']['output']>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly androidTabletId: Maybe<Scalars['String']['output']>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly ipadId: Maybe<Scalars['String']['output']>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly iphoneId: Maybe<Scalars['String']['output']>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly title: Maybe<Scalars['String']['output']>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly webId: Maybe<Scalars['String']['output']>;
}

export interface AddTracksToPartnerPlaylistError {
	readonly __typename: 'AddTracksToPartnerPlaylistError';
	/**
	 * Indicates if the user is not allowed to add tracks
	 *
	 * Access: This field requires one of these scopes: [partner-playlist-modify].
	 */
	readonly isNotAllowed: Maybe<Scalars['Boolean']['output']>;
}

export interface AddTracksToPartnerPlaylistInput {
	/** The track ids to add */
	readonly trackIds: ReadonlyArray<Scalars['String']['input']>;
}

export interface AddTracksToPartnerPlaylistOutput {
	readonly __typename: 'AddTracksToPartnerPlaylistOutput';
	/**
	 * The track ids that were added
	 *
	 * Access: This field requires one of these scopes: [partner-playlist-modify].
	 */
	readonly addedTrackIds: ReadonlyArray<Scalars['String']['output']>;
	/**
	 * The tracks ids that were not added because playlist limit was reached
	 *
	 * Access: This field requires one of these scopes: [partner-playlist-modify].
	 */
	readonly beyondLimitTrackIds: ReadonlyArray<Scalars['String']['output']>;
	/**
	 * The tracks ids that were not added because already present in the playlist
	 *
	 * Access: This field requires one of these scopes: [partner-playlist-modify].
	 */
	readonly duplicatedTrackIds: ReadonlyArray<Scalars['String']['output']>;
}

export type AddTracksToPartnerPlaylistResult =
	| AddTracksToPartnerPlaylistError
	| AddTracksToPartnerPlaylistOutput;

export interface AdsRights {
	readonly __typename: 'AdsRights';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly available: Scalars['Boolean']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly availableAfter: Scalars['Date']['output'];
}

/**
 * Represents album information.
 *
 * Some fields can vary depending on the languages accepted by the client.
 */
export interface Album extends Node {
	readonly __typename: 'Album';
	/**
	 * WARNING!!
	 * It returns fake data and should only be used for development at the moment.
	 *
	 * The alternative versions are albums sharing a certain percentage of identical titles and serving a similar artistic purpose.
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly alternativeVersions: Maybe<AlbumAlternativeVersionsConnection>;
	/**
	 * Paginated list of artists who contributed to the current album.
	 *
	 * Those artists can be filtered by their role in the album. See ContributorRoles for existing role list.
	 */
	readonly contributors: AlbumContributorsConnection;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly copyright: Maybe<Scalars['String']['output']>;
	readonly cover: Maybe<Picture>;
	/** Number of discs in album */
	readonly discsCount: Scalars['Int']['output'];
	/** Title of the album, in the first language in order of preferred languages (defaults to original name) */
	readonly displayTitle: Scalars['String']['output'];
	/** Duration of the album in seconds */
	readonly duration: Scalars['Int']['output'];
	/**
	 * Album exclusions
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly exclusions: Maybe<AlbumExclusions>;
	/**
	 * If this album is taken down, but an alternative (called fallback) is available, the client **MUST** redirect to the fallback when not null
	 *
	 * Can return error codes:
	 * * ERR_ALBUM_NOT_FOUND: Is returned when album doesn't exist
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly fallback: Maybe<Album>;
	readonly fansCount: Scalars['Int']['output'];
	/**
	 * Access: This field is restricted to applications granted with specific access.
	 * @deprecated Use isExplicit instead
	 */
	readonly hasExplicitLyrics: Scalars['Boolean']['output'];
	readonly id: Scalars['ID']['output'];
	/**
	 * True if the album contains dolby atmos tracks
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly isAtmos: Scalars['Boolean']['output'];
	/** True if the album contains known explicit tracks */
	readonly isExplicit: Maybe<Scalars['Boolean']['output']>;
	/**
	 * Whether or not the album is in the currently logged-in user's favorites
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly isFavorite: Maybe<Scalars['Boolean']['output']>;
	/**
	 * If the album can be listened to or not. Many reasons can lead to a taken down album.
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly isTakenDown: Scalars['Boolean']['output'];
	/**
	 * Name of the label who produced the album
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly label: Maybe<Scalars['String']['output']>;
	/**
	 * Album most popular track
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly mostPopularTrack: Maybe<Track>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly producerLine: Maybe<Scalars['String']['output']>;
	/** Full list of the album's tracks ids */
	readonly rawTracks: ReadonlyArray<Scalars['ID']['output']>;
	readonly releaseDate: Maybe<Scalars['Date']['output']>;
	readonly subtypes: AlbumSubtype;
	/** Paginated list of the album's tracks */
	readonly tracks: AlbumTracksConnection;
	/** Number of tracks in album */
	readonly tracksCount: Scalars['Int']['output'];
	readonly type: AlbumType;
	/** Absolute canonical URL to the album page on Deezer */
	readonly url: DeezerUrl;
	/**
	 * Contains all data about specific release dates dedicated to premium or free account. If present, Windowing is enabled.
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly windowing: Maybe<AlbumWindowing>;
}

/**
 * Represents album information.
 *
 * Some fields can vary depending on the languages accepted by the client.
 */
export interface AlbumAlternativeVersionsArgs {
	after: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
}

/**
 * Represents album information.
 *
 * Some fields can vary depending on the languages accepted by the client.
 */
export interface AlbumContributorsArgs {
	after: InputMaybe<Scalars['String']['input']>;
	cursor: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
	roles?: InputMaybe<ReadonlyArray<ContributorRoles>>;
}

/**
 * Represents album information.
 *
 * Some fields can vary depending on the languages accepted by the client.
 */
export interface AlbumCoverArgs {
	pictureRequest: InputMaybe<PictureRequest>;
}

/**
 * Represents album information.
 *
 * Some fields can vary depending on the languages accepted by the client.
 */
export interface AlbumTracksArgs {
	after: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
}

export interface AlbumAlternativeVersionsConnection {
	readonly __typename: 'AlbumAlternativeVersionsConnection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly edges: ReadonlyArray<AlbumAlternativeVersionsEdge>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly pageInfo: PageInfo;
}

export interface AlbumAlternativeVersionsEdge {
	readonly __typename: 'AlbumAlternativeVersionsEdge';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly cursor: Scalars['String']['output'];
	/**
	 * Can return error codes:
	 * * ERR_ALBUM_NOT_FOUND: Is returned when album doesn't exist
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly node: Maybe<Album>;
}

export interface AlbumContributorsConnection {
	readonly __typename: 'AlbumContributorsConnection';
	readonly edges: ReadonlyArray<AlbumContributorsEdge>;
	readonly pageInfo: PageInfo;
}

export interface AlbumContributorsEdge {
	readonly __typename: 'AlbumContributorsEdge';
	readonly cursor: Scalars['String']['output'];
	readonly node: Contributor;
	readonly roles: ReadonlyArray<ContributorRoles>;
}

export interface AlbumExclusions {
	readonly __typename: 'AlbumExclusions';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly isExcludedFromArtistDiscography: Scalars['Boolean']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly isExcludedFromArtistHighlight: Scalars['Boolean']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly isExcludedFromRecommendation: Scalars['Boolean']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly isExcludedFromSearch: Scalars['Boolean']['output'];
}

export interface AlbumMutation {
	readonly __typename: 'AlbumMutation';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly addToFavorite: AlbumMutationFavoriteAddOut;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly removeFromFavorite: AlbumMutationFavoriteRemoveOut;
}

export interface AlbumMutationFavoriteAddOut {
	readonly __typename: 'AlbumMutationFavoriteAddOut';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly album: Album;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly favoritedAt: Scalars['Date']['output'];
}

export interface AlbumMutationFavoriteRemoveOut {
	readonly __typename: 'AlbumMutationFavoriteRemoveOut';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly album: Album;
}

export enum AlbumOrder {
	ALPHA = 'ALPHA',
	NONE = 'NONE',
	RANK = 'RANK',
	RELEASE_DATE = 'RELEASE_DATE',
}

export enum AlbumSubTypeInput {
	COMPILATION = 'COMPILATION',
	KARAOKE = 'KARAOKE',
	LIVE = 'LIVE',
	STUDIO = 'STUDIO',
}

export interface AlbumSubtype {
	readonly __typename: 'AlbumSubtype';
	readonly isCompilation: Scalars['Boolean']['output'];
	readonly isKaraoke: Scalars['Boolean']['output'];
	readonly isLive: Scalars['Boolean']['output'];
	readonly isStudio: Scalars['Boolean']['output'];
}

export interface AlbumTracksConnection {
	readonly __typename: 'AlbumTracksConnection';
	readonly edges: ReadonlyArray<AlbumTracksEdge>;
	readonly pageInfo: PageInfo;
}

export interface AlbumTracksEdge {
	readonly __typename: 'AlbumTracksEdge';
	readonly cursor: Scalars['String']['output'];
	readonly node: Maybe<Track>;
}

export enum AlbumType {
	ALBUM = 'ALBUM',
	/** @deprecated Not returned anymore */
	COMPILATIONS = 'COMPILATIONS',
	EP = 'EP',
	/** @deprecated Not returned anymore */
	MIXTAPE = 'MIXTAPE',
	SINGLES = 'SINGLES',
	UNKNOWN = 'UNKNOWN',
}

export enum AlbumTypeInput {
	ALBUM = 'ALBUM',
	EP = 'EP',
	SINGLES = 'SINGLES',
	UNKNOWN = 'UNKNOWN',
}

export interface AlbumWindowing {
	readonly __typename: 'AlbumWindowing';
	/**
	 * This will indicate that at least one track in the album is streamable. (Directly or though fallbacks).
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly hasStreamableContent: Scalars['Boolean']['output'];
	/**
	 * Date of release for freemium members
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly releaseDateFree: Maybe<Scalars['Date']['output']>;
	/**
	 * Date of release for premium members
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly releaseDateSub: Scalars['Date']['output'];
}

export interface AlbumsConnection {
	readonly __typename: 'AlbumsConnection';
	readonly edges: ReadonlyArray<AlbumsConnectionEdge>;
	readonly pageInfo: PageInfo;
}

export interface AlbumsConnectionEdge {
	readonly __typename: 'AlbumsConnectionEdge';
	readonly cursor: Scalars['String']['output'];
	/**
	 * Can return error codes:
	 * * ERR_ALBUM_NOT_FOUND: Is returned when album doesn't exist
	 */
	readonly node: Maybe<Album>;
}

/**
 * Represents artist information.
 *
 * Some fields can vary depending on the languages accepted by the client.
 */
export interface Artist extends Node {
	readonly __typename: 'Artist';
	/** List of album types released by the artist */
	readonly albumTypes: ReadonlyArray<AlbumType>;
	readonly albums: ArtistAlbumsConnection;
	/** Biography of the artist if available in the first language in order of preferred languages (defaults to english if available) */
	readonly bio: Maybe<ArtistBiography>;
	/**
	 * Access: This field is restricted to applications granted with specific access.
	 * @deprecated Replaced by liveEvents
	 */
	readonly concerts: Maybe<ArtistConcertConnection>;
	/**
	 * Artist exclusions
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly exclusions: Maybe<ArtistExclusions>;
	/**
	 * Artist fandom
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 * @deprecated Feature in stand-by
	 */
	readonly fandom: Fandom;
	readonly fansCount: Scalars['Int']['output'];
	/**
	 * An artist with a partial discography is intentionally incomplete at Deezer on the artist or rightholder demand.
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly hasPartialDiscography: Scalars['Boolean']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly hasSmartRadio: Scalars['Boolean']['output'];
	/**
	 * Whether or not the artist has top tracks
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly hasTopTracks: Maybe<Scalars['Boolean']['output']>;
	/**
	 * Highlight is a section where an artist can push content he/she wants to promote.
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly highlight: Maybe<ArtistHighlight>;
	readonly id: Scalars['ID']['output'];
	/**
	 * Whether or not the artist is banned from the logged-in user's recommendation
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly isBannedFromRecommendation: Maybe<Scalars['Boolean']['output']>;
	/**
	 * If set to true, this artist is not a real artist but an aggregated one like VariousArtist that does not represent one artist
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly isDummyArtist: Scalars['Boolean']['output'];
	/**
	 * Whether or not the artist is in the currently logged-in user's favorites
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly isFavorite: Maybe<Scalars['Boolean']['output']>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly isPictureFromReliableSource: Scalars['Boolean']['output'];
	/**
	 * WARNING!!
	 * It returns fake data and should only be used for development at the moment.
	 *
	 * Whether or not the artist is accepting subscription
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly isSubscriptionEnabled: Scalars['Boolean']['output'];
	/**
	 * Live events from deezer and external sources (songkick...)
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly liveEvents: Maybe<LiveEventsConnection>;
	/**
	 * Live events from deezer and external sources (songkick...) ordered by proximity
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly liveEventsByProximity: Maybe<LiveEventsConnection>;
	/** Name of the artist, in the first language in order of preferred languages (defaults to original name) */
	readonly name: Scalars['String']['output'];
	/**
	 * Access: This field is restricted to applications granted with specific access.
	 * @deprecated Will be replaced by another field when albums are available in graph
	 */
	readonly nbAlbums: Scalars['Int']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly onTour: Scalars['Boolean']['output'];
	readonly picture: Maybe<Picture>;
	/**
	 * List of playlists related to the artist
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly playlists: ArtistPlaylists;
	/**
	 * The full list of the artist raw album ids.
	 * You may receive one album, or thousands, hence you must fetch this field only when absolutely needed, and only once per query.
	 * We defined the cost of the field accordingly.
	 */
	readonly rawAlbums: ReadonlyArray<ArtistRawAlbum>;
	/**
	 * Recommended artists based on the current artist.
	 *
	 * This recommendation can vary depending on the current user and the current country.
	 */
	readonly relatedArtist: Maybe<ArtistRelatedArtistConnection>;
	/** Social media links */
	readonly social: ArtistSocial;
	/**
	 * Artists can have a custom status line to display below their avatar
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly status: Maybe<Scalars['String']['output']>;
	/**
	 * Information about the the artist subscription
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly subscriptionInfo: Maybe<ArtistSubscriptionInfo>;
	/** Top tracks of the current artist */
	readonly topTracks: Maybe<ArtistTopTracksConnection>;
	/** Absolute canonical URL to the artist page on Deezer */
	readonly url: DeezerUrl;
}

/**
 * Represents artist information.
 *
 * Some fields can vary depending on the languages accepted by the client.
 */
export interface ArtistAlbumTypesArgs {
	mode?: InputMaybe<DiscographyMode>;
	roles?: InputMaybe<ReadonlyArray<ContributorRoles>>;
}

/**
 * Represents artist information.
 *
 * Some fields can vary depending on the languages accepted by the client.
 */
export interface ArtistAlbumsArgs {
	after: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
	mode?: InputMaybe<DiscographyMode>;
	onlyCanonical?: Scalars['Boolean']['input'];
	order?: InputMaybe<AlbumOrder>;
	roles?: InputMaybe<ReadonlyArray<ContributorRoles>>;
	subType: InputMaybe<AlbumSubTypeInput>;
	types?: InputMaybe<ReadonlyArray<AlbumTypeInput>>;
}

/**
 * Represents artist information.
 *
 * Some fields can vary depending on the languages accepted by the client.
 */
export interface ArtistConcertsArgs {
	after: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
}

/**
 * Represents artist information.
 *
 * Some fields can vary depending on the languages accepted by the client.
 */
export interface ArtistLiveEventsArgs {
	after: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
	statuses: ReadonlyArray<GraphLiveEventStatusInput>;
	types: ReadonlyArray<GraphLiveEventTypeInput>;
}

/**
 * Represents artist information.
 *
 * Some fields can vary depending on the languages accepted by the client.
 */
export interface ArtistLiveEventsByProximityArgs {
	after: InputMaybe<Scalars['String']['input']>;
	coordinatesInput: InputMaybe<ArtistLiveEventsCoordinatesInput>;
	first?: InputMaybe<Scalars['Int']['input']>;
}

/**
 * Represents artist information.
 *
 * Some fields can vary depending on the languages accepted by the client.
 */
export interface ArtistPictureArgs {
	pictureRequest: InputMaybe<PictureRequest>;
}

/**
 * Represents artist information.
 *
 * Some fields can vary depending on the languages accepted by the client.
 */
export interface ArtistRawAlbumsArgs {
	mode?: InputMaybe<DiscographyMode>;
	onlyCanonical?: Scalars['Boolean']['input'];
	order?: InputMaybe<AlbumOrder>;
	roles?: InputMaybe<ReadonlyArray<ContributorRoles>>;
	subType: InputMaybe<AlbumSubTypeInput>;
	types?: InputMaybe<ReadonlyArray<AlbumTypeInput>>;
}

/**
 * Represents artist information.
 *
 * Some fields can vary depending on the languages accepted by the client.
 */
export interface ArtistRelatedArtistArgs {
	after: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
}

/**
 * Represents artist information.
 *
 * Some fields can vary depending on the languages accepted by the client.
 */
export interface ArtistTopTracksArgs {
	after: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
}

export interface ArtistAlbumsConnection {
	readonly __typename: 'ArtistAlbumsConnection';
	readonly edges: ReadonlyArray<ArtistAlbumsEdge>;
	readonly pageInfo: PageInfo;
}

export interface ArtistAlbumsEdge {
	readonly __typename: 'ArtistAlbumsEdge';
	readonly cursor: Scalars['String']['output'];
	/**
	 * Can return error codes:
	 * * ERR_ALBUM_NOT_FOUND: Is returned when album doesn't exist
	 */
	readonly node: Maybe<Album>;
}

export interface ArtistBiography {
	readonly __typename: 'ArtistBiography';
	readonly full: Scalars['String']['output'];
	/** Where the biography comes from */
	readonly source: Scalars['String']['output'];
	readonly summary: Maybe<Scalars['String']['output']>;
}

export interface ArtistConcert extends Node {
	readonly __typename: 'ArtistConcert';
	/**
	 * Use after instead of cursor
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly attendees: ConcertAttendeesConnection;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly coordinates: Maybe<Coordinates>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly countryCode: Scalars['String']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly date: Scalars['Date']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly endDate: Maybe<Scalars['Date']['output']>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly hasTime: Scalars['Boolean']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly id: Scalars['ID']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly location: Scalars['String']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly name: Scalars['String']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly price: Maybe<Scalars['String']['output']>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly type: Scalars['String']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly url: Scalars['String']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly venue: Maybe<Scalars['String']['output']>;
}

export interface ArtistConcertAttendeesArgs {
	after: InputMaybe<Scalars['String']['input']>;
	cursor: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
}

export interface ArtistConcertConnection {
	readonly __typename: 'ArtistConcertConnection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly edges: ReadonlyArray<ArtistConcertEdge>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly pageInfo: PageInfo;
}

export interface ArtistConcertEdge {
	readonly __typename: 'ArtistConcertEdge';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly cursor: Scalars['String']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly node: ArtistConcert;
}

export interface ArtistExclusions {
	readonly __typename: 'ArtistExclusions';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly isExcludedFromRecommendation: Scalars['Boolean']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly isExcludedFromSearch: Scalars['Boolean']['output'];
}

export interface ArtistHighlight {
	readonly __typename: 'ArtistHighlight';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly content: Maybe<ArtistHighlightContent>;
}

export type ArtistHighlightContent =
	| Album
	| Playlist
	| Podcast
	| PodcastEpisode;

export interface ArtistLiveEventsCoordinatesInput {
	/** Latitude from which to calculate artist live event proximity */
	readonly latitude: Scalars['Float']['input'];
	/** Longitude from which to calculate artist live event proximity */
	readonly longitude: Scalars['Float']['input'];
}

export interface ArtistMutationBanOut {
	readonly __typename: 'ArtistMutationBanOut';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly artist: Artist;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly bannedAt: Scalars['Date']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly status: Scalars['Boolean']['output'];
}

export interface ArtistMutationFavoriteAddOut {
	readonly __typename: 'ArtistMutationFavoriteAddOut';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly artist: Artist;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly favoritedAt: Scalars['Date']['output'];
}

export interface ArtistMutationFavoriteRemoveOut {
	readonly __typename: 'ArtistMutationFavoriteRemoveOut';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly artist: Artist;
}

export interface ArtistMutationUnbanOut {
	readonly __typename: 'ArtistMutationUnbanOut';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly artist: Artist;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly status: Scalars['Boolean']['output'];
}

export interface ArtistPlaylists {
	readonly __typename: 'ArtistPlaylists';
	/**
	 * List of playlists curated by editors related to the artist
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly curatedPlaylists: PlaylistsConnection;
	/**
	 * List of playlists related to the artist
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly relatedPlaylists: PlaylistsConnection;
}

export interface ArtistPlaylistsCuratedPlaylistsArgs {
	after: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
}

export interface ArtistPlaylistsRelatedPlaylistsArgs {
	after: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
}

export interface ArtistRawAlbum {
	readonly __typename: 'ArtistRawAlbum';
	readonly albumId: Scalars['ID']['output'];
}

export interface ArtistRelatedArtistConnection {
	readonly __typename: 'ArtistRelatedArtistConnection';
	readonly edges: ReadonlyArray<RelatedArtistEdge>;
	readonly pageInfo: PageInfo;
}

export interface ArtistSocial {
	readonly __typename: 'ArtistSocial';
	readonly facebook: Maybe<Scalars['String']['output']>;
	readonly instagram: Maybe<Scalars['String']['output']>;
	readonly twitter: Maybe<Scalars['String']['output']>;
	readonly website: Maybe<Scalars['String']['output']>;
}

export interface ArtistSubscriptionBenefit {
	readonly __typename: 'ArtistSubscriptionBenefit';
	/**
	 * Description of the benefit
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly description: Scalars['String']['output'];
	/**
	 * Name of the benefit
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly name: Scalars['String']['output'];
	/**
	 * WARNING!!
	 * It returns fake data and should only be used for development at the moment.
	 *
	 * Type/icon of the benefit
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly type: Scalars['String']['output'];
}

/**
 * Represents all artist subscription data (share/paywall/price)
 *
 * Some fields can vary depending on the languages accepted by the client.
 */
export interface ArtistSubscriptionInfo {
	readonly __typename: 'ArtistSubscriptionInfo';
	/**
	 * WARNING!!
	 * It returns fake data and should only be used for development at the moment.
	 *
	 * Artist subscription CTA Label
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly paywallCTALabel: Scalars['String']['output'];
	/**
	 * WARNING!!
	 * It returns fake data and should only be used for development at the moment.
	 *
	 * Artist subscription CTA link
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly paywallCTALink: Scalars['String']['output'];
	/**
	 * WARNING!!
	 * It returns fake data and should only be used for development at the moment.
	 *
	 * Artist subscription box title
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly paywallTitle: Scalars['String']['output'];
	/**
	 * WARNING!!
	 * It returns fake data and should only be used for development at the moment.
	 *
	 * Price of the subscription
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly priceDetails: Scalars['String']['output'];
	/**
	 * WARNING!!
	 * It returns fake data and should only be used for development at the moment.
	 *
	 * Artist subscription benefits
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly subscriptionBenefits: Maybe<
		ReadonlyArray<ArtistSubscriptionBenefit>
	>;
	/**
	 * WARNING!!
	 * It returns fake data and should only be used for development at the moment.
	 *
	 * Artist subscription benefits title
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly subscriptionBenefitsTitle: Maybe<Scalars['String']['output']>;
	/**
	 * Artist subscription sharing assets
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly subscriptionSharingAssets: Maybe<SharingAssets>;
	/**
	 * WARNING!!
	 * It returns fake data and should only be used for development at the moment.
	 *
	 * Artist subscription terms and conditions
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly termsAndConditions: Maybe<Scalars['String']['output']>;
}

export interface ArtistTopTracksConnection {
	readonly __typename: 'ArtistTopTracksConnection';
	readonly edges: ReadonlyArray<TopTrackEdge>;
	readonly pageInfo: PageInfo;
}

export interface ArtistsPickerStep {
	readonly __typename: 'ArtistsPickerStep';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly artists: OnboardingArtistsConnection;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly displayGenreBar: Scalars['Boolean']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly genres: ReadonlyArray<OnboardingGenre>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly maximumArtistsToPick: Scalars['Int']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly minimumArtistsToPick: Scalars['Int']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly relatedArtists: OnboardingRelatedArtistsConnection;
}

export interface ArtistsPickerStepArtistsArgs {
	after: InputMaybe<Scalars['String']['input']>;
	first: Scalars['Int']['input'];
	genreId: InputMaybe<Scalars['String']['input']>;
}

export interface ArtistsPickerStepRelatedArtistsArgs {
	after: InputMaybe<Scalars['String']['input']>;
	artistId: Scalars['String']['input'];
	excludedArtistIds: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
	first?: Scalars['Int']['input'];
}

export type Asset = FormattedImage | Video;

export interface Audiobook extends Node {
	readonly __typename: 'Audiobook';
	/**
	 * Paginated list of the audiobook's chapters
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly chapters: AudiobookChaptersConnection;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly chaptersCount: Scalars['Int']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly contributorDisplayNames: AudiobookContributorDisplayNamesConnection;
	/**
	 * Paginated list of artists who contributed to the current audiobook.
	 *
	 * Those artists can be filtered by their role in the audiobook. See AudiobookContributorRoles for existing role list.
	 */
	readonly contributors: AudiobookContributorsConnection;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly cover: Maybe<Picture>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly description: Maybe<Scalars['String']['output']>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly discsCount: Scalars['Int']['output'];
	/**
	 * Title of the audiobook, in the first language in order of preferred languages (defaults to original name)
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly displayTitle: Maybe<Scalars['String']['output']>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly duration: Scalars['Int']['output'];
	/**
	 * Audiobook exclusions
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly exclusions: Maybe<AudiobookExclusions>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly fallback: Maybe<Audiobook>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly fansCount: Scalars['Int']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly id: Scalars['ID']['output'];
	/**
	 * True if the audiobook contains known explicit chapters
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly isExplicit: Scalars['Boolean']['output'];
	/**
	 * Whether or not the audiobook is in the currently logged-in user's favorites
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly isFavorite: Maybe<Scalars['Boolean']['output']>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly isTakenDown: Scalars['Boolean']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly originalReleaseDate: Maybe<Scalars['Date']['output']>;
	/**
	 * Name of the label who produced the audiobook
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly producerLine: Maybe<Scalars['String']['output']>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly publisher: Maybe<Scalars['String']['output']>;
	/**
	 * Full list of the audiobook's chapters ids
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly rawChapters: ReadonlyArray<Scalars['ID']['output']>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly releaseDate: Maybe<Scalars['Date']['output']>;
	/**
	 * UPC of the audiobook
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly upc: Maybe<Scalars['String']['output']>;
	/**
	 * Absolute canonical URL to the audiobook page on Deezer
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly url: DeezerUrl;
}

export interface AudiobookChaptersArgs {
	after: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
}

export interface AudiobookContributorDisplayNamesArgs {
	after: InputMaybe<Scalars['String']['input']>;
	cursor: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
	roles?: InputMaybe<ReadonlyArray<AudiobookContributorRoles>>;
}

export interface AudiobookContributorsArgs {
	after: InputMaybe<Scalars['String']['input']>;
	cursor: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
	roles?: InputMaybe<ReadonlyArray<AudiobookContributorRoles>>;
}

export interface AudiobookCoverArgs {
	pictureRequest: InputMaybe<PictureRequest>;
}

export interface AudiobookAuthorMutationFavoriteAddOut {
	readonly __typename: 'AudiobookAuthorMutationFavoriteAddOut';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly favoritedAt: Scalars['Date']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly id: Scalars['ID']['output'];
}

export interface AudiobookAuthorMutationFavoriteRemoveOut {
	readonly __typename: 'AudiobookAuthorMutationFavoriteRemoveOut';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly id: Scalars['ID']['output'];
}

export interface AudiobookChapter extends Node {
	readonly __typename: 'AudiobookChapter';
	/**
	 * The audiobook from which this chapter is from.
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly audiobook: Audiobook;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly contributorDisplayNames: AudiobookChapterContributorDisplayNamesConnection;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly diskInfo: Maybe<AudiobookChapterInfo>;
	/**
	 * Title of the chapter, in the first language in order of preferred languages (defaults to original name)
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly displayTitle: Scalars['String']['output'];
	/**
	 * Duration of the chapter in seconds
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly duration: Scalars['Int']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly gain: Maybe<Scalars['Float']['output']>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly id: Scalars['ID']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly isExplicit: Scalars['Boolean']['output'];
	/**
	 * Whether or not the audiobookchapter is in the currently logged-in user's favorites
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly isFavorite: Maybe<Scalars['Boolean']['output']>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly isrc: Scalars['String']['output'];
	/**
	 * Contains media information to use for the player.
	 * This node will be populated only if some rights can be computed. In some case, it will be null, meaning the audiobookchapter
	 * cannot be read at all. Consider using this field for simple availability check (use mediaRights for deep checks)
	 * You can ask for the field AudiobookChapterMedia.id only
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly media: Maybe<TrackMedia>;
}

export interface AudiobookChapterContributorDisplayNamesArgs {
	after: InputMaybe<Scalars['String']['input']>;
	cursor: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
	roles?: InputMaybe<ReadonlyArray<AudiobookContributorRoles>>;
}

export interface AudiobookChapterContributorDisplayNameEdge {
	readonly __typename: 'AudiobookChapterContributorDisplayNameEdge';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly cursor: Scalars['String']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly node: Maybe<Scalars['String']['output']>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly role: AudiobookContributorRoles;
}

export interface AudiobookChapterContributorDisplayNamesConnection {
	readonly __typename: 'AudiobookChapterContributorDisplayNamesConnection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly edges: ReadonlyArray<AudiobookChapterContributorDisplayNameEdge>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly pageInfo: PageInfo;
}

export interface AudiobookChapterInfo {
	readonly __typename: 'AudiobookChapterInfo';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly chapterPosition: Maybe<Scalars['Int']['output']>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly diskNumber: Maybe<Scalars['Int']['output']>;
}

export interface AudiobookChapterState {
	readonly __typename: 'AudiobookChapterState';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly completedAt: Maybe<Scalars['Date']['output']>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly elapsedSeconds: Scalars['Int']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly id: Scalars['ID']['output'];
}

export interface AudiobookChaptersConnection {
	readonly __typename: 'AudiobookChaptersConnection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly edges: ReadonlyArray<AudiobookChaptersEdge>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly pageInfo: PageInfo;
}

export interface AudiobookChaptersEdge {
	readonly __typename: 'AudiobookChaptersEdge';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly cursor: Scalars['String']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly node: Maybe<AudiobookChapter>;
}

export type AudiobookContributor = Artist;

export interface AudiobookContributorDisplayNameEdge {
	readonly __typename: 'AudiobookContributorDisplayNameEdge';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly cursor: Scalars['String']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly node: Maybe<Scalars['String']['output']>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly role: AudiobookContributorRoles;
}

export interface AudiobookContributorDisplayNamesConnection {
	readonly __typename: 'AudiobookContributorDisplayNamesConnection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly edges: ReadonlyArray<AudiobookContributorDisplayNameEdge>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly pageInfo: PageInfo;
}

export enum AudiobookContributorRoles {
	AUTHOR = 'AUTHOR',
	NARRATOR = 'NARRATOR',
	OTHER = 'OTHER',
}

export interface AudiobookContributorsConnection {
	readonly __typename: 'AudiobookContributorsConnection';
	readonly edges: ReadonlyArray<AudiobookContributorsEdge>;
	readonly pageInfo: PageInfo;
}

export interface AudiobookContributorsEdge {
	readonly __typename: 'AudiobookContributorsEdge';
	readonly cursor: Scalars['String']['output'];
	readonly node: AudiobookContributor;
	readonly roles: ReadonlyArray<AudiobookContributorRoles>;
}

export interface AudiobookExclusions {
	readonly __typename: 'AudiobookExclusions';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly isExcludedFromRecommendation: Scalars['Boolean']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly isExcludedFromSearch: Scalars['Boolean']['output'];
}

export interface AudiobookMutationFavoriteAddOut {
	readonly __typename: 'AudiobookMutationFavoriteAddOut';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly favoritedAt: Scalars['Date']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly id: Scalars['ID']['output'];
}

export interface AudiobookMutationFavoriteRemoveOut {
	readonly __typename: 'AudiobookMutationFavoriteRemoveOut';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly id: Scalars['ID']['output'];
}

export interface AudiobookOnDeckMutationOutput {
	readonly __typename: 'AudiobookOnDeckMutationOutput';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly id: Scalars['ID']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly startedAt: Scalars['Date']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly updatedAt: Scalars['Date']['output'];
}

export interface AudiobookOnDeckRemoveMutationOutput {
	readonly __typename: 'AudiobookOnDeckRemoveMutationOutput';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly id: Scalars['ID']['output'];
}

export interface AudiobookState {
	readonly __typename: 'AudiobookState';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly completedAt: Maybe<Scalars['Date']['output']>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly currentChapterState: Maybe<AudiobookChapterState>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly id: Scalars['ID']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly startedAt: Scalars['Date']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly updatedAt: Scalars['Date']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly userPlaybackSpeed: Scalars['Int']['output'];
}

export enum BackgroundFormats {
	IMAGE = 'IMAGE',
	VIDEO = 'VIDEO',
}

export interface BannedArtistEdge {
	readonly __typename: 'BannedArtistEdge';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly bannedAt: Scalars['Date']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly cursor: Scalars['String']['output'];
	/**
	 * Can return error codes:
	 * * ERR_ARTIST_NOT_FOUND: Is returned when artist doesn't exist
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly node: Maybe<Artist>;
}

export interface BannedFromRecommendation {
	readonly __typename: 'BannedFromRecommendation';
	/**
	 * Paginated list of all artists banned by the user
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly artists: Maybe<UserBannedArtistConnection>;
	/**
	 * Estimated number of artists banned by the user; use for display only
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly estimatedArtistsCount: Scalars['Int']['output'];
	/**
	 * Estimated number of tracks banned by the user; use for display only
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly estimatedTracksCount: Scalars['Int']['output'];
	/**
	 * The full list of the user's banned artist ids.
	 * You may receive one artist, or thousands, hence you must fetch this field only when absolutely needed, and only once per query.
	 * We defined the cost of the field accordingly.
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly rawArtists: Maybe<ReadonlyArray<RawBannedContent>>;
	/**
	 * The full list of the user's banned track ids.
	 * You may receive one track, or thousands, hence you must fetch this field only when absolutely needed, and only once per query.
	 * We defined the cost of the field accordingly.
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly rawTracks: Maybe<ReadonlyArray<RawBannedContent>>;
	/**
	 * Paginated list of all tracks banned by the user
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly tracks: Maybe<UserBannedTrackConnection>;
}

export interface BannedFromRecommendationArtistsArgs {
	after: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
}

export interface BannedFromRecommendationTracksArgs {
	after: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
}

export interface BannedTrackEdge {
	readonly __typename: 'BannedTrackEdge';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly bannedAt: Scalars['Date']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly cursor: Scalars['String']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly node: Maybe<Track>;
}

export interface BlindTest {
	readonly __typename: 'BlindTest';
	/**
	 * The blindtest's cover
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly cover: Picture;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly id: Scalars['ID']['output'];
	/**
	 * WARNING!!
	 * It returns fake data and should only be used for development at the moment.
	 *
	 * Is the blindtest playable for a user with restricted access to the blindtest feature?
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly isPlayableAsUserWithRestrictedAccessToBlindTest: Scalars['Boolean']['output'];
	/**
	 * The blindtest's leaderboard
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly leaderboard: Maybe<BlindTestLeaderboard>;
	/**
	 * The blindtest's questions. Every usage of this field will return a completely different batch of questions
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 * @deprecated questions is deprecated, use blindTestStartSession Mutation
	 */
	readonly questions: ReadonlyArray<Maybe<BlindTestQuestion>>;
	/**
	 * The blindtest's title
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly title: Scalars['String']['output'];
	/**
	 * The blindtest's type
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly type: BlindTestType;
	/**
	 * Current user's best score in the blindtest
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly userBestScore: Maybe<Scalars['Int']['output']>;
	/**
	 * Current user's rank in the blindtest
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly userRank: Maybe<Scalars['Int']['output']>;
}

export interface BlindTestConnection {
	readonly __typename: 'BlindTestConnection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly edges: ReadonlyArray<BlindTestConnectionEdge>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly pageInfo: PageInfo;
}

export interface BlindTestConnectionEdge {
	readonly __typename: 'BlindTestConnectionEdge';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly cursor: Scalars['String']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly node: Maybe<BlindTest>;
}

export interface BlindTestLeaderboard {
	readonly __typename: 'BlindTestLeaderboard';
	/**
	 * Number of users who played in the current blindtest
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly playersCount: Maybe<Scalars['Int']['output']>;
	/**
	 * Users with the highest best score recorded in the current blindtest
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly topRankedPlayers: ReadonlyArray<TopRankedPlayer>;
	/**
	 * Timestamp of the last update to the leaderboard
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly updatedAt: Maybe<Scalars['Date']['output']>;
}

export interface BlindTestLeaderboardTopRankedPlayersArgs {
	first?: InputMaybe<Scalars['Int']['input']>;
}

/** Response to the Make A Guess Mutation */
export interface BlindTestMakeAGuessOutput {
	readonly __typename: 'BlindTestMakeAGuessOutput';
	/**
	 * The actual Track that was expected
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly answer: Track;
	/**
	 * Score variation applied following the guess
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly scoreVariation: Scalars['Int']['output'];
	/**
	 * New state of the blind test session
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly state: BlindTestSessionState;
}

export interface BlindTestPermissions {
	readonly __typename: 'BlindTestPermissions';
	/**
	 * Define if the currently logged user can initiate a new multiplayer blindtest session
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly canInitiateMultiplayerSession: Scalars['Boolean']['output'];
	/**
	 * WARNING!!
	 * It returns fake data and should only be used for development at the moment.
	 *
	 * Define if the currently logged user has a restricted acces to singleplayer blindtest
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly hasRestrictedAccessToBlindTest: Scalars['Boolean']['output'];
}

export interface BlindTestQuestion {
	readonly __typename: 'BlindTestQuestion';
	/**
	 * The blindtest question's choices
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly choices: ReadonlyArray<Maybe<Track>>;
	/**
	 * The blindtest question's correct answer
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly response: Track;
}

export enum BlindTestQuestionTypeInput {
	ARTISTS = 'ARTISTS',
	TRACKS = 'TRACKS',
}

/** Question of the blindtest without the actual answer */
export interface BlindTestSessionQuestion {
	readonly __typename: 'BlindTestSessionQuestion';
	/**
	 * List of choices
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly choices: ReadonlyArray<Maybe<Track>>;
	/**
	 * media token used to play to audio sample
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly mediaToken: TrackMediaToken;
	/**
	 * Track Full media access when question was answered
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly response: Maybe<Track>;
}

/** State of a BlindTest session */
export interface BlindTestSessionState {
	readonly __typename: 'BlindTestSessionState';
	/**
	 * BlindTest current session is the state of
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly blindTest: BlindTest;
	/**
	 * BlindTest session creation Date
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly createdAt: Scalars['Date']['output'];
	/**
	 * Date at which session token will (or has) expire
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly expiresAt: Scalars['Date']['output'];
	/**
	 * Is current session finished?
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly isFinished: Scalars['Boolean']['output'];
	/**
	 * Maximum score reachable per Question
	 *
	 * Be aware that this field will probably be deprecated in favor of a more global
	 * scoring scheme object
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly maxScorePerQuestion: Maybe<Scalars['Int']['output']>;
	/**
	 * Questions of this session
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly questions: ReadonlyArray<Maybe<BlindTestSessionQuestion>>;
	/**
	 * Current Score
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly score: Scalars['Int']['output'];
	/**
	 * Current session Step (0-based, if greater than or equal to number of questions, means session ended)
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly step: Scalars['Int']['output'];
	/**
	 * Token that should be used (consumed) to advance game.
	 *
	 * Could also be used to retrieve current state of the session
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly token: Scalars['String']['output'];
}

export interface BlindTestSessionStateToken {
	readonly __typename: 'BlindTestSessionStateToken';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly expiresAt: Scalars['Date']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly token: Scalars['String']['output'];
}

/** Response to the Start Session Mutation */
export interface BlindTestStartSessionOutput {
	readonly __typename: 'BlindTestStartSessionOutput';
	/**
	 * Tokens that could be used for a multiplayer game
	 *
	 * These are not linked to any player, which will be detected
	 * on first guess.
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly additionalTokens: ReadonlyArray<BlindTestSessionStateToken>;
	/**
	 * BlindTest current session is the state of
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly blindTest: BlindTest;
	/**
	 * BlindTest session creation Date
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly createdAt: Scalars['Date']['output'];
	/**
	 * Date at which session token will (or has) expire
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly expiresAt: Scalars['Date']['output'];
	/**
	 * Maximum score reachable per Question
	 *
	 * Be aware that this field will probably be deprecated in favor of a more global
	 * scoring scheme object
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly maxScorePerQuestion: Maybe<Scalars['Int']['output']>;
	/**
	 * Questions of this session
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly questions: ReadonlyArray<Maybe<BlindTestSessionQuestion>>;
	/**
	 * Token that should be used (consumed) to advance game.
	 *
	 * Could also be used to retrieve current state of the session
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly token: Scalars['String']['output'];
}

export enum BlindTestType {
	PLAYLIST = 'PLAYLIST',
}

export enum BlindTestTypeInput {
	PLAYLIST = 'PLAYLIST',
}

export interface BookmarkPodcastEpisodeOutput {
	readonly __typename: 'BookmarkPodcastEpisodeOutput';
	/**
	 * Will return the created bookmark.
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly bookmark: Maybe<PodcastEpisodeBookmark>;
	/**
	 * Was the episode successfully bookmarked
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly status: Scalars['Boolean']['output'];
}

export interface Card {
	readonly __typename: 'Card';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly audioTrackId: Maybe<Scalars['ID']['output']>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly duration: Maybe<Scalars['Int']['output']>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly id: Maybe<Scalars['ID']['output']>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly metas: Maybe<ReadonlyArray<Meta>>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly template: CardTemplate;
}

export interface CardTemplate {
	readonly name: Scalars['String']['output'];
}

export interface CatalogIssueAlbumInput {
	readonly additionalData: InputMaybe<Scalars['String']['input']>;
	readonly id: Scalars['String']['input'];
	readonly problem: CatalogIssueAlbumProblem;
}

export enum CatalogIssueAlbumProblem {
	AUDIO = 'AUDIO',
	EXPLICIT_CONTENT = 'EXPLICIT_CONTENT',
	OTHER = 'OTHER',
	WRONG_ARTIST = 'WRONG_ARTIST',
}

export interface CatalogIssueArtistInput {
	readonly additionalData: InputMaybe<Scalars['String']['input']>;
	readonly id: Scalars['String']['input'];
	readonly problem: CatalogIssueArtistProblem;
}

export enum CatalogIssueArtistProblem {
	ARTIST_ALREADY_EXIST = 'ARTIST_ALREADY_EXIST',
	EXPLICIT_CONTENT = 'EXPLICIT_CONTENT',
	OTHER = 'OTHER',
	WRONG_PICTURE = 'WRONG_PICTURE',
}

export interface CatalogIssueInput {
	readonly album: InputMaybe<CatalogIssueAlbumInput>;
	readonly artist: InputMaybe<CatalogIssueArtistInput>;
	readonly lyrics: InputMaybe<CatalogIssueLyricsInput>;
	readonly track: InputMaybe<CatalogIssueTrackInput>;
}

export interface CatalogIssueLyricsInput {
	readonly id: Scalars['String']['input'];
	readonly problem: CatalogIssueLyricsProblem;
}

export enum CatalogIssueLyricsProblem {
	NOT_SYNCHRONIZED = 'NOT_SYNCHRONIZED',
	SPELLING_MISTAKE = 'SPELLING_MISTAKE',
	WRONG_LANGUAGE = 'WRONG_LANGUAGE',
	WRONG_SONG = 'WRONG_SONG',
}

export interface CatalogIssueTrackInput {
	readonly additionalData: InputMaybe<Scalars['String']['input']>;
	readonly id: Scalars['String']['input'];
	readonly problem: CatalogIssueTrackProblem;
}

export enum CatalogIssueTrackProblem {
	AUDIO = 'AUDIO',
	EXPLICIT_CONTENT = 'EXPLICIT_CONTENT',
	OTHER = 'OTHER',
	WRONG_ARTIST = 'WRONG_ARTIST',
}

/**
 * Represents channel information.
 *
 * Some fields can vary depending on the languages accepted by the client.
 */
export interface Channel extends Node {
	readonly __typename: 'Channel';
	/** Background color of the channel */
	readonly backgroundColor: Scalars['String']['output'];
	readonly id: Scalars['ID']['output'];
	/**
	 * Logo of the channel
	 * @deprecated Use logoAsset instead
	 */
	readonly logo: Maybe<Picture>;
	/** Logo of the channel */
	readonly logoAsset: Maybe<UiAsset>;
	/** Name of the channel, in the first language in order of preferred languages (defaults to original title) */
	readonly name: Scalars['String']['output'];
	/** Picture of the channel */
	readonly picture: Maybe<Picture>;
	/** Slug of the channel */
	readonly slug: Scalars['String']['output'];
	/** Absolute canonical URL to the channel page on Deezer */
	readonly url: DeezerUrl;
}

export interface ChannelsConnection {
	readonly __typename: 'ChannelsConnection';
	readonly edges: ReadonlyArray<ChannelsConnectionEdge>;
	readonly pageInfo: PageInfo;
}

export interface ChannelsConnectionEdge {
	readonly __typename: 'ChannelsConnectionEdge';
	readonly cursor: Scalars['String']['output'];
	readonly node: Maybe<Channel>;
}

export interface Chapter extends CardTemplate {
	readonly __typename: 'Chapter';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly background: Maybe<Asset>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly cta: Maybe<Cta>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly header: Maybe<Scalars['String']['output']>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly name: Scalars['String']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly title: ReadonlyArray<Scalars['String']['output']>;
}

export interface Charts {
	readonly __typename: 'Charts';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly country: Maybe<ChartsCountry>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly worldwide: Maybe<ChartsWorldwide>;
}

export interface ChartsCountryArgs {
	countryCode: InputMaybe<Scalars['String']['input']>;
}

export interface ChartsAlbumsConnection {
	readonly __typename: 'ChartsAlbumsConnection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly edges: ReadonlyArray<ChartsAlbumsEdge>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly pageInfo: PageInfo;
}

export interface ChartsAlbumsEdge {
	readonly __typename: 'ChartsAlbumsEdge';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly cursor: Scalars['String']['output'];
	/**
	 * Can return error codes:
	 * * ERR_ALBUM_NOT_FOUND: Is returned when album doesn't exist
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly node: Maybe<Album>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly position: Scalars['Int']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly variation: Scalars['Int']['output'];
}

export interface ChartsArtistsConnection {
	readonly __typename: 'ChartsArtistsConnection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly edges: ReadonlyArray<ChartsArtistsEdge>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly pageInfo: PageInfo;
}

export interface ChartsArtistsEdge {
	readonly __typename: 'ChartsArtistsEdge';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly cursor: Scalars['String']['output'];
	/**
	 * Can return error codes:
	 * * ERR_ARTIST_NOT_FOUND: Is returned when artist doesn't exist
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly node: Maybe<Artist>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly position: Scalars['Int']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly variation: Scalars['Int']['output'];
}

export interface ChartsCountry {
	readonly __typename: 'ChartsCountry';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly albums: Maybe<ChartsAlbumsConnection>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly artists: Maybe<ChartsArtistsConnection>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly playlists: Maybe<ChartsPlaylistsConnection>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly tracks: Maybe<ChartsTracksConnection>;
}

export interface ChartsCountryAlbumsArgs {
	after: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
}

export interface ChartsCountryArtistsArgs {
	after: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
}

export interface ChartsCountryPlaylistsArgs {
	after: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
}

export interface ChartsCountryTracksArgs {
	after: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
}

export interface ChartsPlaylistsConnection {
	readonly __typename: 'ChartsPlaylistsConnection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly edges: ReadonlyArray<ChartsPlaylistsEdge>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly pageInfo: PageInfo;
}

export interface ChartsPlaylistsEdge {
	readonly __typename: 'ChartsPlaylistsEdge';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly cursor: Scalars['String']['output'];
	/**
	 * Can return error codes:
	 * * ERR_PLAYLIST_NOT_FOUND: Is returned when playlist doesn't exist
	 * * ERR_PLAYLIST_ACCESS_NOT_AUTHORIZED: Is returned when playlist access is not allowed
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly node: Maybe<Playlist>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly position: Scalars['Int']['output'];
}

export interface ChartsTracksConnection {
	readonly __typename: 'ChartsTracksConnection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly edges: ReadonlyArray<ChartsTracksEdge>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly pageInfo: PageInfo;
}

export interface ChartsTracksEdge {
	readonly __typename: 'ChartsTracksEdge';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly cursor: Scalars['String']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly node: Maybe<Track>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly position: Scalars['Int']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly variation: Scalars['Int']['output'];
}

export interface ChartsWorldwide {
	readonly __typename: 'ChartsWorldwide';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly tracks: Maybe<ChartsTracksConnection>;
}

export interface ChartsWorldwideTracksArgs {
	after: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
}

export interface ChooseNextStepStep {
	readonly __typename: 'ChooseNextStepStep';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly steps: ReadonlyArray<Scalars['String']['output']>;
}

/** Describes a chunk */
export interface ChunkMetadata {
	readonly __typename: 'ChunkMetadata';
	/**
	 * Queue chunk's identifier
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly id: Scalars['ID']['output'];
	/**
	 * Queue chunk's size
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly size: Scalars['Int']['output'];
}

export interface City {
	readonly __typename: 'City';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly coordinates: Coordinates;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly countryCode: Scalars['String']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly name: Scalars['String']['output'];
}

export interface Codec {
	readonly __typename: 'Codec';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly bitrate: Scalars['Int']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly type: Scalars['String']['output'];
}

export type ConcertAttendee = Artist | GenericConcertArtist;

export interface ConcertAttendeesConnection {
	readonly __typename: 'ConcertAttendeesConnection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly edges: ReadonlyArray<ConcertAttendeesEdge>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly pageInfo: PageInfo;
}

export interface ConcertAttendeesEdge {
	readonly __typename: 'ConcertAttendeesEdge';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly cursor: Scalars['String']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly node: ConcertAttendee;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly roles: ReadonlyArray<ConcertAttendeesRoles>;
}

export enum ConcertAttendeesRoles {
	HEADLINER = 'HEADLINER',
	OPENING_ACT = 'OPENING_ACT',
}

export interface ConcertContributorMetadata {
	readonly __typename: 'ConcertContributorMetadata';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly performanceOrder: Scalars['Int']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly roles: LiveEventConcertContributorRoles;
}

export interface ConnectInput {
	readonly bus: PubsubSpec;
	/**
	 * The device identifier is a copy of the device id that
	 * was sent by the discovery bridge when publishing the
	 * connection offers
	 */
	readonly deviceId: Scalars['String']['input'];
}

export interface ConsentData {
	readonly __typename: 'ConsentData';
	/**
	 * Consent screen webview URI to update preferences
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly consentScreenWebview: Scalars['String']['output'];
	/**
	 * Specific user consent data
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly current: Maybe<PrivateConsentData>;
	/**
	 * Numbers of vendors in the Global Vendors List
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly vendorsCount: Scalars['Int']['output'];
}

/** User participation to a contest */
export interface ContestParticipation {
	readonly __typename: 'ContestParticipation';
	/**
	 * Date of the participation
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly attendeeDate: Scalars['Date']['output'];
	/**
	 * Id of the contest for which the user has participated
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly contestId: Scalars['Int']['output'];
}

export type Contributor = Artist;

export enum ContributorRoles {
	FEATURED = 'FEATURED',
	MAIN = 'MAIN',
}

export interface ConversionSection {
	readonly __typename: 'ConversionSection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly title: Maybe<Scalars['String']['output']>;
}

export interface Coordinates {
	readonly __typename: 'Coordinates';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly latitude: Scalars['Float']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly longitude: Scalars['Float']['output'];
}

export interface Cover extends CardTemplate {
	readonly __typename: 'Cover';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly background: Maybe<Asset>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly cta: Maybe<Cta>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly header: Maybe<Scalars['String']['output']>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly image: Maybe<Asset>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly name: Scalars['String']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly title: Maybe<ReadonlyArray<Scalars['String']['output']>>;
}

export interface CreateNewQueueInput {
	/** The list of all contexts referenced in mediaList entries */
	readonly contexts: InputMaybe<ReadonlyArray<QueueContextInput>>;
	/** The list of media the queue should hold */
	readonly mediaList: ReadonlyArray<QueueMediaInput>;
}

export interface CreateNewQueueOutput {
	readonly __typename: 'CreateNewQueueOutput';
	/**
	 * Identifier of the created queue
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly queueId: Scalars['ID']['output'];
}

export interface Cta {
	readonly __typename: 'Cta';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly generatedSharingAssets: Maybe<SharingAssets>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly label: Maybe<Scalars['String']['output']>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly link: Maybe<Scalars['String']['output']>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly sharingAssets: Maybe<SharingAssets>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly type: CtaTypes;
}

export interface CtaGeneratedSharingAssetsArgs {
	generatedAssetHash: InputMaybe<Scalars['String']['input']>;
}

export enum CtaTypes {
	GET_STARTED = 'GET_STARTED',
	NONE = 'NONE',
	SHARE = 'SHARE',
	SKIP = 'SKIP',
	START_LISTENING = 'START_LISTENING',
	SWIPE = 'SWIPE',
}

export interface DeezerUrl {
	/** Absolute canonical url used as deeplink in Deezer mobile apps */
	readonly deepLink: Scalars['String']['output'];
	/** Absolute canonical url to the node on Deezer website */
	readonly webUrl: Scalars['String']['output'];
}

export enum DiscographyMode {
	ALL = 'ALL',
	NON_OFFICIAL = 'NON_OFFICIAL',
	OFFICIAL = 'OFFICIAL',
}

export interface DiscoverInput {
	readonly bus: PubsubSpec;
	/**
	 * The session is a unique ID that allow to identify a session for a given user
	 * action. This will also be returned in the discovery offers returned on the
	 * pubsub to the client allowing it to filter against this key the offers that
	 * actually refer to the current discovery session.
	 */
	readonly session: Scalars['String']['input'];
}

export enum DiscoveryTuner {
	DEFAULT = 'DEFAULT',
	DISCOVERY = 'DISCOVERY',
}

export enum DiscoveryTunerInput {
	DEFAULT = 'DEFAULT',
	DISCOVERY = 'DISCOVERY',
}

export interface Duo extends CardTemplate {
	readonly __typename: 'Duo';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly background: Maybe<Asset>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly bottomImage: FormattedImage;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly cta: Maybe<Cta>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly header: Maybe<Scalars['String']['output']>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly name: Scalars['String']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly text: Maybe<ReadonlyArray<Scalars['String']['output']>>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly topImage: FormattedImage;
}

/** Represents dynamic page. */
export interface DynamicPage {
	readonly __typename: 'DynamicPage';
	/**
	 * Expiration date of this page, if date is in the past the page must be refreshed
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly expiresAt: Scalars['Date']['output'];
	/**
	 * The page's masthead
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly masthead: Maybe<DynamicPageMasthead>;
	/**
	 * Paginated list of sections belonging to the dynamic page
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly sections: SectionsConnection;
	/**
	 * Page title
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly title: Scalars['String']['output'];
	/**
	 * Dynamic page identifier
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly uri: Scalars['String']['output'];
}

/** Represents dynamic page. */
export interface DynamicPageSectionsArgs {
	after: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
	support: ReadonlyArray<SectionTypeInput>;
}

/** Represents the masthead of a dynamic page */
export interface DynamicPageMasthead {
	readonly __typename: 'DynamicPageMasthead';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly backgroundColor: Scalars['String']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly logo: Maybe<Picture>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly mobileBackgroundPicture: Maybe<Picture>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly title: Scalars['String']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly webBackgroundPicture: Maybe<Picture>;
}

export interface DynamicThemePicture {
	readonly __typename: 'DynamicThemePicture';
	/**
	 * Asset to use with dark background theme
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly darkThemeAsset: Picture;
	/**
	 * Asset to use with light background theme
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly lightThemeAsset: Picture;
}

export interface DynamicUiAsset {
	readonly __typename: 'DynamicUIAsset';
	/**
	 * Asset to use with dark background theme
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly darkThemeUIAsset: UiAsset;
	/**
	 * Asset to use with light background theme
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly lightThemeUIAsset: UiAsset;
}

export enum ErrorCode {
	/** When album doesn't exist */
	ERR_ALBUM_NOT_FOUND = 'ERR_ALBUM_NOT_FOUND',
	/** When artist doesn't exist */
	ERR_ARTIST_NOT_FOUND = 'ERR_ARTIST_NOT_FOUND',
	/** When playlist access is not authorized */
	ERR_PLAYLIST_ACCESS_NOT_AUTHORIZED = 'ERR_PLAYLIST_ACCESS_NOT_AUTHORIZED',
	/** When playlist doesn't exist */
	ERR_PLAYLIST_NOT_FOUND = 'ERR_PLAYLIST_NOT_FOUND',
	/** When smart tracklist doesn't exist */
	ERR_SMART_TRACKLIST_NOT_FOUND = 'ERR_SMART_TRACKLIST_NOT_FOUND',
	/** When access to a field is not authorized */
	ERR_UNAUTHORIZED_ACCESS = 'ERR_UNAUTHORIZED_ACCESS',
}

export interface ErrorCodeWithDetails {
	readonly code: ErrorCode;
	readonly details: Scalars['String']['input'];
}

export interface ExternalMedia {
	readonly __typename: 'ExternalMedia';
	/**
	 * Codec type: "hls", "aac" or "mp3"
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly codec: Maybe<Codec>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly url: Scalars['String']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly version: Maybe<Scalars['Int']['output']>;
}

export interface ExternalUrl {
	readonly __typename: 'ExternalUrl';
	/**
	 * Url of the webview
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly url: Scalars['String']['output'];
}

/** Represents a user family information and users flags concerning a family. */
export interface Family {
	readonly __typename: 'Family';
	/**
	 * Family linked profile
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly linked: ReadonlyArray<FamilyUser>;
	/**
	 * Family main profile
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly main: FamilyUser;
	/**
	 * Family parent
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 * @deprecated Use main instead
	 */
	readonly parent: FamilyUser;
}

/** Represents a user information from family */
export interface FamilyUser {
	readonly __typename: 'FamilyUser';
	/**
	 * The profile caption
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly caption: Scalars['String']['output'];
	/**
	 * The ID of the User
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly id: Scalars['String']['output'];
	/**
	 * The user name
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly name: Scalars['String']['output'];
	/**
	 * Permissions of current user on this family profile
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly permissions: FamilyUserPermissions;
	/**
	 * The user avatar uploaded by the user on this account
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly picture: Maybe<Picture>;
}

/** Represents a user information from family */
export interface FamilyUserPermissions {
	readonly __typename: 'FamilyUserPermissions';
	/**
	 * If true the current user can edit this family profile
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly arePersonalDataEditable: Scalars['Boolean']['output'];
	/**
	 * If true the current user can convert this family profile into independent account
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly canBeConvertedToIndependent: Scalars['Boolean']['output'];
	/**
	 * If true the current user can delete this family profile
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly isDeletable: Scalars['Boolean']['output'];
	/**
	 * If true the current user can unlink this family profile
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly isDelinkable: Scalars['Boolean']['output'];
	/**
	 * If true the current user can switch with this family profile
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly isLoggableAs: Scalars['Boolean']['output'];
}

export interface Fandom {
	readonly __typename: 'Fandom';
	/**
	 * Whether or not the user is superfan
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 * @deprecated Feature in stand-by
	 */
	readonly isSuperfan: Maybe<Scalars['Boolean']['output']>;
	/**
	 * Artist superfan sharing assets
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 * @deprecated Feature in stand-by
	 */
	readonly superfanSharingAssets: Maybe<SharingAssets>;
}

export interface FavoriteAlbumEdge {
	readonly __typename: 'FavoriteAlbumEdge';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly cursor: Scalars['String']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly favoritedAt: Scalars['Date']['output'];
	/**
	 * Can return error codes:
	 * * ERR_ALBUM_NOT_FOUND: Is returned when album doesn't exist
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly node: Maybe<Album>;
}

export interface FavoriteArtistEdge {
	readonly __typename: 'FavoriteArtistEdge';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly cursor: Scalars['String']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly favoritedAt: Scalars['Date']['output'];
	/**
	 * Can return error codes:
	 * * ERR_ARTIST_NOT_FOUND: Is returned when artist doesn't exist
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly node: Maybe<Artist>;
}

export interface FavoriteContentArtistsConnection {
	readonly __typename: 'FavoriteContentArtistsConnection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly edges: ReadonlyArray<FavoriteContentArtistsConnectionEdge>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly pageInfo: PageInfo;
}

export interface FavoriteContentArtistsConnectionEdge {
	readonly __typename: 'FavoriteContentArtistsConnectionEdge';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly cursor: Scalars['String']['output'];
	/**
	 * Can return error codes:
	 * * ERR_ARTIST_NOT_FOUND: Is returned when artist doesn't exist
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly node: Maybe<Artist>;
}

export interface FavoritePlaylistEdge {
	readonly __typename: 'FavoritePlaylistEdge';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly cursor: Scalars['String']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly favoritedAt: Scalars['Date']['output'];
	/**
	 * Can return error codes:
	 * * ERR_PLAYLIST_NOT_FOUND: Is returned when playlist doesn't exist
	 * * ERR_PLAYLIST_ACCESS_NOT_AUTHORIZED: Is returned when playlist access is not allowed
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly node: Maybe<Playlist>;
}

export interface FavoritePodcastEdge {
	readonly __typename: 'FavoritePodcastEdge';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly cursor: Scalars['String']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly favoritedAt: Scalars['Date']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly node: Maybe<Podcast>;
}

export interface FavoriteTrackEdge {
	readonly __typename: 'FavoriteTrackEdge';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly cursor: Scalars['String']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly favoritedAt: Scalars['Date']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly node: Maybe<Track>;
}

export interface Favorites {
	readonly __typename: 'Favorites';
	/**
	 * Paginated list of all albums favorited by the user
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly albums: Maybe<UserFavoriteAlbumConnection>;
	/**
	 * Paginated list of all artists favorited by the user
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly artists: Maybe<UserFavoriteArtistConnection>;
	/**
	 * Favorites aggregated by artist
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly byArtist: FavoritesByArtist;
	/**
	 * Estimated number of albums favorited by the user; use for display only
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly estimatedAlbumsCount: Scalars['Int']['output'];
	/**
	 * Estimated number of artists favorited by the user; use for display only
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly estimatedArtistsCount: Scalars['Int']['output'];
	/**
	 * Estimated number of playlists favorited by the user; use for display only
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly estimatedPlaylistsCount: Scalars['Int']['output'];
	/**
	 * Estimated number of podcasts favorited by the user; use for display only
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly estimatedPodcastsCount: Scalars['Int']['output'];
	/**
	 * Estimated number of tracks favorited by the user; use for display only
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly estimatedTracksCount: Scalars['Int']['output'];
	/**
	 * Paginated list of all playlists favorited by the user
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly playlists: Maybe<UserFavoritePlaylistConnection>;
	/**
	 * Paginated list of all podcasts favorited by the user
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly podcasts: Maybe<UserFavoritePodcastConnection>;
	/**
	 * The full list of the user's favorite album ids.
	 * You may receive one album, or thousands, hence you must fetch this field only when absolutely needed, and only once per query.
	 * We defined the cost of the field accordingly.
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly rawAlbums: Maybe<ReadonlyArray<RawFavorite>>;
	/**
	 * The full list of the user's favorite artist ids.
	 * You may receive one artist, or thousands, hence you must fetch this field only when absolutely needed, and only once per query.
	 * We defined the cost of the field accordingly.
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly rawArtists: Maybe<ReadonlyArray<RawFavorite>>;
	/**
	 * The full list of the user's favorite audiobook authors ids.
	 * You may receive one author, or thousands, hence you must fetch this field only when absolutely needed, and only once per query.
	 * We defined the cost of the field accordingly.
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly rawAudiobookAuthors: Maybe<ReadonlyArray<RawFavorite>>;
	/**
	 * The full list of the user's favorite audiobooks ids.
	 * You may receive one audiobook, or thousands, hence you must fetch this field only when absolutely needed, and only once per query.
	 * We defined the cost of the field accordingly.
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly rawAudiobooks: Maybe<ReadonlyArray<RawFavorite>>;
	/**
	 * The full list of the user's favorite playlists ids.
	 * You may receive one playlists, or thousands, hence you must fetch this field only when absolutely needed, and only once per query.
	 * We defined the cost of the field accordingly.
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly rawPlaylists: Maybe<ReadonlyArray<RawFavorite>>;
	/**
	 * The full list of the user's favorite podcast ids.
	 * You may receive one podcast, or thousands, hence you must fetch this field only when absolutely needed, and only once per query.
	 * We defined the cost of the field accordingly.
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly rawPodcasts: Maybe<ReadonlyArray<RawFavorite>>;
	/**
	 * The full list of the user's favorite track ids.
	 * You may receive one track, or thousands, hence you must fetch this field only when absolutely needed, and only once per query.
	 * We defined the cost of the field accordingly.
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly rawTracks: Maybe<ReadonlyArray<RawFavorite>>;
	/**
	 * Paginated list of all tracks favorited by the user
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly tracks: Maybe<UserFavoriteTrackConnection>;
}

export interface FavoritesAlbumsArgs {
	after: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
}

export interface FavoritesArtistsArgs {
	after: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
}

export interface FavoritesByArtistArgs {
	artistId: Scalars['String']['input'];
}

export interface FavoritesPlaylistsArgs {
	after: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
}

export interface FavoritesPodcastsArgs {
	after: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
}

export interface FavoritesRawAudiobookAuthorsArgs {
	kidMode?: Scalars['Boolean']['input'];
}

export interface FavoritesRawAudiobooksArgs {
	kidMode?: Scalars['Boolean']['input'];
}

export interface FavoritesTracksArgs {
	after: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
}

export interface FavoritesByArtist {
	readonly __typename: 'FavoritesByArtist';
	/**
	 * Paginated list of all albums favorited by the user for a given artist
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly albums: Maybe<UserFavoriteAlbumConnection>;
	/**
	 * Estimated number of albums favorited by the user for a given artist; use for display only
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly estimatedAlbumsCount: Scalars['Int']['output'];
	/**
	 * Estimated number of tracks favorited by the user for a given artist; use for display only
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly estimatedTracksCount: Scalars['Int']['output'];
	/**
	 * The full list of tracks ids favorited by the user for a given artist
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly rawTracks: ReadonlyArray<RawFavorite>;
	/**
	 * Paginated list of all tracks favorited by the user for a given artist
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly tracks: Maybe<UserFavoriteTrackConnection>;
}

export interface FavoritesByArtistAlbumsArgs {
	after: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
}

export interface FavoritesByArtistTracksArgs {
	after: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
}

/** Flow is mix based on favorite music mixed with fresh recommendations generated for a given user */
export interface Flow extends FlowMood, MadeForMe, Node {
	readonly __typename: 'Flow';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly assets: FlowMoodAssets;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly cover: Maybe<Picture>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly id: Scalars['ID']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly subTitle: Maybe<Scalars['String']['output']>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly title: Scalars['String']['output'];
	/**
	 * Generated batch of tracks based on user's latest interactions. Every usage of this field will return a completely different batch of tracks
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly tracks: ReadonlyArray<FlowTrack>;
}

export interface FlowConfig extends FlowMood, Node {
	readonly __typename: 'FlowConfig';
	/**
	 * Access: This field is restricted to applications granted with specific access.
	 * @deprecated Flow mood is too specific, better use visuals
	 */
	readonly assets: FlowMoodAssets;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly id: Scalars['ID']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly title: Scalars['String']['output'];
	/**
	 * Generated batch of tracks based on user's latest interactions. Every usage of this field will return a completely different batch of tracks
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly tracks: ReadonlyArray<FlowConfigTrack>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly visuals: FlowConfigVisuals;
}

export interface FlowConfigTrack {
	readonly __typename: 'FlowConfigTrack';
	/**
	 * The track itself
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly track: Maybe<Track>;
}

export interface FlowConfigVisuals {
	readonly __typename: 'FlowConfigVisuals';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly dynamicPageIcon: Maybe<UiAsset>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly hardwareCircleIcon: Maybe<UiAsset>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly hardwareSquareIcon: Maybe<UiAsset>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly wheelIcon: Maybe<UiAsset>;
}

export interface FlowMood {
	readonly assets: FlowMoodAssets;
	readonly id: Scalars['ID']['output'];
	readonly title: Scalars['String']['output'];
}

export interface FlowMoodAssets {
	readonly __typename: 'FlowMoodAssets';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly dynamicPageIcon: Maybe<UiAsset>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly hardwareCircleIcon: Maybe<UiAsset>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly hardwareSquareIcon: Maybe<UiAsset>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly wheelIcon: Maybe<UiAsset>;
}

export enum FlowMoodContextInput {
	DYNAMIC_PAGE = 'DYNAMIC_PAGE',
	FLOW_WHEEL = 'FLOW_WHEEL',
	HARDWARE = 'HARDWARE',
}

export interface FlowTrack {
	readonly __typename: 'FlowTrack';
	/**
	 * The track itself
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly track: Maybe<Track>;
}

/** Current value of the tuner used to generate tracks for flow  and flow configs. */
export interface FlowTuner {
	readonly __typename: 'FlowTuner';
	/**
	 * Value of the default/more discoveries tuner.
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly discoveryTuner: DiscoveryTuner;
}

export interface FlowWheel extends Node {
	readonly __typename: 'FlowWheel';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly flowConfigs: ReadonlyArray<FlowConfig>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly id: Scalars['ID']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly title: Scalars['String']['output'];
}

export interface FlowWheels {
	readonly __typename: 'FlowWheels';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly defaultWheelId: Scalars['ID']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly wheels: ReadonlyArray<FlowWheel>;
}

export interface FormattedImage {
	readonly __typename: 'FormattedImage';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly format: Maybe<ImageFormats>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly image: Maybe<Image>;
}

export interface GamesPermissions {
	readonly __typename: 'GamesPermissions';
	/**
	 * BlindTest game permissions
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly blindTest: Maybe<BlindTestPermissions>;
}

export interface Gatekeep {
	readonly __typename: 'Gatekeep';
	/**
	 * True if the gatekeep is open. False otherwise
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly isOpen: Scalars['Boolean']['output'];
	/**
	 * Name of the gatekeep. It's a technical name, not for display
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly name: Scalars['String']['output'];
}

export interface Gatekeeper {
	readonly __typename: 'Gatekeeper';
	/**
	 * List of individual gatekeep
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly gatekeeps: ReadonlyArray<Gatekeep>;
}

export interface GenericConcertArtist {
	readonly __typename: 'GenericConcertArtist';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly name: Scalars['String']['output'];
}

export enum GraphLiveEventStatusInput {
	CANCELLED = 'CANCELLED',
	ENDED = 'ENDED',
	PENDING = 'PENDING',
	POSTPONED = 'POSTPONED',
	STARTED = 'STARTED',
}

export enum GraphLiveEventTypeInput {
	CONCERT = 'CONCERT',
	FESTIVAL = 'FESTIVAL',
	LIVESTREAM_CONCERT = 'LIVESTREAM_CONCERT',
	LIVESTREAM_FESTIVAL = 'LIVESTREAM_FESTIVAL',
}

export enum GraphSearchMixedResultTypeInput {
	ALBUM = 'ALBUM',
	ARTIST = 'ARTIST',
	CHANNEL = 'CHANNEL',
	FLOW_CONFIG = 'FLOW_CONFIG',
	LIVESTREAM = 'LIVESTREAM',
	PLAYLIST = 'PLAYLIST',
	PODCAST = 'PODCAST',
	PODCAST_EPISODE = 'PODCAST_EPISODE',
	TRACK = 'TRACK',
	USER = 'USER',
}

export enum GridFullItemSupportInput {
	ALBUM = 'ALBUM',
	ARTIST = 'ARTIST',
	BLINDTEST = 'BLINDTEST',
	CHANNEL = 'CHANNEL',
	FLOW = 'FLOW',
	LIVESTREAM = 'LIVESTREAM',
	MIX = 'MIX',
	PLAYLIST = 'PLAYLIST',
	PODCAST = 'PODCAST',
	PROMOTED_CONTENT = 'PROMOTED_CONTENT',
	PROMOTIONAL_VIDEO = 'PROMOTIONAL_VIDEO',
	SMART_TRACKLIST = 'SMART_TRACKLIST',
	TRACK = 'TRACK',
}

export interface GridFullSection {
	readonly __typename: 'GridFullSection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly content: GridFullSectionContent;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly rawContent: GridFullSectionRawContent;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly subtitle: Maybe<Scalars['String']['output']>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly target: Maybe<SectionTarget>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly title: Maybe<Scalars['String']['output']>;
}

export interface GridFullSectionContentArgs {
	support: ReadonlyArray<GridFullItemSupportInput>;
}

export interface GridFullSectionRawContentArgs {
	support: ReadonlyArray<GridFullItemSupportInput>;
}

export interface GridFullSectionContent {
	readonly __typename: 'GridFullSectionContent';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly paginatedItems: GridFullSectionContentConnection;
}

export interface GridFullSectionContentPaginatedItemsArgs {
	after: InputMaybe<Scalars['String']['input']>;
	first: Scalars['Int']['input'];
}

export interface GridFullSectionContentConnection {
	readonly __typename: 'GridFullSectionContentConnection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly edges: ReadonlyArray<GridFullSectionContentConnectionEdge>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly pageInfo: PageInfo;
}

export interface GridFullSectionContentConnectionEdge {
	readonly __typename: 'GridFullSectionContentConnectionEdge';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly cursor: Scalars['String']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly node: Maybe<GridFullSectionContentItem>;
}

export type GridFullSectionContentItem =
	| Album
	| Artist
	| Channel
	| Flow
	| Livestream
	| Mix
	| Playlist
	| Podcast
	| PromotedContent
	| PromotionalVideo
	| SmartTracklist
	| Track;

export interface GridFullSectionRawContent {
	readonly __typename: 'GridFullSectionRawContent';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly expectsMoreItems: Scalars['Boolean']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly rawItems: ReadonlyArray<Maybe<GridFullSectionRawContentItem>>;
}

export interface GridFullSectionRawContentItem {
	readonly __typename: 'GridFullSectionRawContentItem';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly id: Scalars['String']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly type: GridFullSectionRawContentItemType;
}

export enum GridFullSectionRawContentItemType {
	ALBUM = 'ALBUM',
	ARTIST = 'ARTIST',
	BLINDTEST = 'BLINDTEST',
	CHANNEL = 'CHANNEL',
	FLOW = 'FLOW',
	LIVESTREAM = 'LIVESTREAM',
	MIX = 'MIX',
	PLAYLIST = 'PLAYLIST',
	PODCAST = 'PODCAST',
	PROMOTED_CONTENT = 'PROMOTED_CONTENT',
	PROMOTIONAL_VIDEO = 'PROMOTIONAL_VIDEO',
	SMART_TRACKLIST = 'SMART_TRACKLIST',
	TRACK = 'TRACK',
}

export enum GridOneLineItemSupportInput {
	ALBUM = 'ALBUM',
	ARTIST = 'ARTIST',
	CHANNEL = 'CHANNEL',
	FLOW = 'FLOW',
	LIVESTREAM = 'LIVESTREAM',
	MIX = 'MIX',
	PLAYLIST = 'PLAYLIST',
	PODCAST = 'PODCAST',
	PROMOTIONAL_VIDEO = 'PROMOTIONAL_VIDEO',
	SMART_TRACKLIST = 'SMART_TRACKLIST',
	TRACK = 'TRACK',
}

export interface GridOneLineSection {
	readonly __typename: 'GridOneLineSection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly content: GridOneLineSectionContent;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly subtitle: Maybe<Scalars['String']['output']>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly target: Maybe<SectionTarget>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly title: Maybe<Scalars['String']['output']>;
}

export interface GridOneLineSectionContentArgs {
	support: ReadonlyArray<GridOneLineItemSupportInput>;
}

export interface GridOneLineSectionContent {
	readonly __typename: 'GridOneLineSectionContent';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly items: GridOneLineSectionContentCollection;
}

export interface GridOneLineSectionContentItemsArgs {
	limit?: InputMaybe<Scalars['Int']['input']>;
}

export interface GridOneLineSectionContentCollection {
	readonly __typename: 'GridOneLineSectionContentCollection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly expectsMoreItems: Scalars['Boolean']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly nodes: ReadonlyArray<GridOneLineSectionContentItemNode>;
}

export type GridOneLineSectionContentItem =
	| Album
	| Artist
	| Channel
	| Flow
	| Livestream
	| Mix
	| Playlist
	| Podcast
	| PromotionalVideo
	| SmartTracklist
	| Track;

export interface GridOneLineSectionContentItemNode {
	readonly __typename: 'GridOneLineSectionContentItemNode';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly item: GridOneLineSectionContentItem;
}

export enum GridTwoLinesItemSupportInput {
	ALBUM = 'ALBUM',
	ARTIST = 'ARTIST',
	CHANNEL = 'CHANNEL',
	FLOW = 'FLOW',
	LIVESTREAM = 'LIVESTREAM',
	MIX = 'MIX',
	PLAYLIST = 'PLAYLIST',
	PODCAST = 'PODCAST',
	PROMOTIONAL_VIDEO = 'PROMOTIONAL_VIDEO',
	SMART_TRACKLIST = 'SMART_TRACKLIST',
	TRACK = 'TRACK',
}

export interface GridTwoLinesSection {
	readonly __typename: 'GridTwoLinesSection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly content: GridTwoLinesSectionContent;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly subtitle: Maybe<Scalars['String']['output']>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly target: Maybe<SectionTarget>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly title: Maybe<Scalars['String']['output']>;
}

export interface GridTwoLinesSectionContentArgs {
	support: ReadonlyArray<GridTwoLinesItemSupportInput>;
}

export interface GridTwoLinesSectionContent {
	readonly __typename: 'GridTwoLinesSectionContent';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly items: GridTwoLinesSectionContentCollection;
}

export interface GridTwoLinesSectionContentItemsArgs {
	limit?: InputMaybe<Scalars['Int']['input']>;
}

export interface GridTwoLinesSectionContentCollection {
	readonly __typename: 'GridTwoLinesSectionContentCollection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly expectsMoreItems: Scalars['Boolean']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly nodes: ReadonlyArray<GridTwoLinesSectionContentItemNode>;
}

export type GridTwoLinesSectionContentItem =
	| Album
	| Artist
	| Channel
	| Flow
	| Livestream
	| Mix
	| Playlist
	| Podcast
	| PromotionalVideo
	| SmartTracklist
	| Track;

export interface GridTwoLinesSectionContentItemNode {
	readonly __typename: 'GridTwoLinesSectionContentItemNode';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly item: GridTwoLinesSectionContentItem;
}

export interface Health {
	readonly __typename: 'Health';
	readonly health: Maybe<Scalars['Boolean']['output']>;
	readonly hello: Maybe<Scalars['String']['output']>;
	readonly secrets: Maybe<Scalars['String']['output']>;
}

export enum HorizontalGridItemSupportInput {
	ALBUM = 'ALBUM',
	ARTIST = 'ARTIST',
	BLINDTEST = 'BLINDTEST',
	CHANNEL = 'CHANNEL',
	FLOW = 'FLOW',
	LIVESTREAM = 'LIVESTREAM',
	MIX = 'MIX',
	PLAYLIST = 'PLAYLIST',
	PODCAST = 'PODCAST',
	PROMOTED_CONTENT = 'PROMOTED_CONTENT',
	PROMOTIONAL_VIDEO = 'PROMOTIONAL_VIDEO',
	SMART_TRACKLIST = 'SMART_TRACKLIST',
	TRACK = 'TRACK',
}

export interface HorizontalGridSection {
	readonly __typename: 'HorizontalGridSection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly content: HorizontalGridSectionContent;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly subtitle: Maybe<Scalars['String']['output']>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly target: Maybe<SectionTarget>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly title: Maybe<Scalars['String']['output']>;
}

export interface HorizontalGridSectionContentArgs {
	support: ReadonlyArray<HorizontalGridItemSupportInput>;
}

export interface HorizontalGridSectionContent {
	readonly __typename: 'HorizontalGridSectionContent';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly items: HorizontalGridSectionContentCollection;
}

export interface HorizontalGridSectionContentItemsArgs {
	limit?: InputMaybe<Scalars['Int']['input']>;
}

export interface HorizontalGridSectionContentCollection {
	readonly __typename: 'HorizontalGridSectionContentCollection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly expectsMoreItems: Scalars['Boolean']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly nodes: ReadonlyArray<HorizontalGridSectionContentItemNode>;
}

export type HorizontalGridSectionContentItem =
	| Album
	| Artist
	| Channel
	| Flow
	| Livestream
	| Mix
	| Playlist
	| Podcast
	| PromotedContent
	| PromotionalVideo
	| SmartTracklist
	| Track;

export interface HorizontalGridSectionContentItemNode {
	readonly __typename: 'HorizontalGridSectionContentItemNode';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly item: HorizontalGridSectionContentItem;
}

export interface Illustration {
	readonly __typename: 'Illustration';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly url: Scalars['String']['output'];
}

export type Image = Illustration | Picture;

export enum ImageFormats {
	ROUND = 'ROUND',
	SQUARE = 'SQUARE',
}

export interface ImportLibraryStep {
	readonly __typename: 'ImportLibraryStep';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly displayChooseDSP: Scalars['Boolean']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly importer: Scalars['String']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly skipCTA: Scalars['String']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly supportedServices: ReadonlyArray<Scalars['String']['output']>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly title: Scalars['String']['output'];
}

export interface InformativeSection {
	readonly __typename: 'InformativeSection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly title: Maybe<Scalars['String']['output']>;
}

export interface InstantSearch {
	readonly __typename: 'InstantSearch';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly results: InstantSearchResults;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly topResult: Maybe<InstantSearchTopResult>;
}

export interface InstantSearchAlbumsConnection {
	readonly __typename: 'InstantSearchAlbumsConnection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly edges: ReadonlyArray<InstantSearchAlbumsConnectionEdge>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly pageInfo: PageInfo;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly priority: Scalars['Int']['output'];
}

export interface InstantSearchAlbumsConnectionEdge {
	readonly __typename: 'InstantSearchAlbumsConnectionEdge';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly cursor: Scalars['String']['output'];
	/**
	 * Can return error codes:
	 * * ERR_ALBUM_NOT_FOUND: Is returned when album doesn't exist
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly node: Maybe<Album>;
}

export interface InstantSearchArtistsConnection {
	readonly __typename: 'InstantSearchArtistsConnection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly edges: ReadonlyArray<InstantSearchArtistsConnectionEdge>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly pageInfo: PageInfo;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly priority: Scalars['Int']['output'];
}

export interface InstantSearchArtistsConnectionEdge {
	readonly __typename: 'InstantSearchArtistsConnectionEdge';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly cursor: Scalars['String']['output'];
	/**
	 * Can return error codes:
	 * * ERR_ARTIST_NOT_FOUND: Is returned when artist doesn't exist
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly node: Maybe<Artist>;
}

export interface InstantSearchChannelsConnection {
	readonly __typename: 'InstantSearchChannelsConnection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly edges: ReadonlyArray<InstantSearchChannelsConnectionEdge>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly pageInfo: PageInfo;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly priority: Scalars['Int']['output'];
}

export interface InstantSearchChannelsConnectionEdge {
	readonly __typename: 'InstantSearchChannelsConnectionEdge';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly cursor: Scalars['String']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly node: Maybe<Channel>;
}

export interface InstantSearchFlowConfigConnection {
	readonly __typename: 'InstantSearchFlowConfigConnection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly edges: ReadonlyArray<InstantSearchFlowConfigConnectionEdge>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly pageInfo: PageInfo;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly priority: Scalars['Int']['output'];
}

export interface InstantSearchFlowConfigConnectionEdge {
	readonly __typename: 'InstantSearchFlowConfigConnectionEdge';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly cursor: Scalars['String']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly node: Maybe<FlowConfig>;
}

export interface InstantSearchLivestreamsConnection {
	readonly __typename: 'InstantSearchLivestreamsConnection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly edges: ReadonlyArray<InstantSearchLivestreamsConnectionEdge>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly pageInfo: PageInfo;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly priority: Scalars['Int']['output'];
}

export interface InstantSearchLivestreamsConnectionEdge {
	readonly __typename: 'InstantSearchLivestreamsConnectionEdge';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly cursor: Scalars['String']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly node: Maybe<Livestream>;
}

export interface InstantSearchPlaylistsConnection {
	readonly __typename: 'InstantSearchPlaylistsConnection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly edges: ReadonlyArray<InstantSearchPlaylistsConnectionEdge>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly pageInfo: PageInfo;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly priority: Scalars['Int']['output'];
}

export interface InstantSearchPlaylistsConnectionEdge {
	readonly __typename: 'InstantSearchPlaylistsConnectionEdge';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly cursor: Scalars['String']['output'];
	/**
	 * Can return error codes:
	 * * ERR_PLAYLIST_NOT_FOUND: Is returned when playlist doesn't exist
	 * * ERR_PLAYLIST_ACCESS_NOT_AUTHORIZED: Is returned when playlist access is not allowed
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly node: Maybe<Playlist>;
}

export interface InstantSearchPodcastEpisodesConnection {
	readonly __typename: 'InstantSearchPodcastEpisodesConnection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly edges: ReadonlyArray<InstantSearchPodcastEpisodesConnectionEdge>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly pageInfo: PageInfo;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly priority: Scalars['Int']['output'];
}

export interface InstantSearchPodcastEpisodesConnectionEdge {
	readonly __typename: 'InstantSearchPodcastEpisodesConnectionEdge';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly cursor: Scalars['String']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly node: Maybe<PodcastEpisode>;
}

export interface InstantSearchPodcastsConnection {
	readonly __typename: 'InstantSearchPodcastsConnection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly edges: ReadonlyArray<InstantSearchPodcastsConnectionEdge>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly pageInfo: PageInfo;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly priority: Scalars['Int']['output'];
}

export interface InstantSearchPodcastsConnectionEdge {
	readonly __typename: 'InstantSearchPodcastsConnectionEdge';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly cursor: Scalars['String']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly node: Maybe<Podcast>;
}

export interface InstantSearchResults {
	readonly __typename: 'InstantSearchResults';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly albums: InstantSearchAlbumsConnection;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly artists: InstantSearchArtistsConnection;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly channels: InstantSearchChannelsConnection;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly flowConfigs: InstantSearchFlowConfigConnection;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly livestreams: InstantSearchLivestreamsConnection;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly playlists: InstantSearchPlaylistsConnection;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly podcastEpisodes: InstantSearchPodcastEpisodesConnection;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly podcasts: InstantSearchPodcastsConnection;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly tracks: InstantSearchTracksConnection;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly tracksByLyrics: InstantSearchTracksConnection;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly users: InstantSearchUsersConnection;
}

export interface InstantSearchResultsAlbumsArgs {
	after: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
}

export interface InstantSearchResultsArtistsArgs {
	after: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
}

export interface InstantSearchResultsChannelsArgs {
	after: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
}

export interface InstantSearchResultsFlowConfigsArgs {
	after: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
}

export interface InstantSearchResultsLivestreamsArgs {
	after: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
}

export interface InstantSearchResultsPlaylistsArgs {
	after: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
}

export interface InstantSearchResultsPodcastEpisodesArgs {
	after: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
}

export interface InstantSearchResultsPodcastsArgs {
	after: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
}

export interface InstantSearchResultsTracksArgs {
	after: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
}

export interface InstantSearchResultsTracksByLyricsArgs {
	after: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
}

export interface InstantSearchResultsUsersArgs {
	after: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
}

export interface InstantSearchTopResult {
	readonly __typename: 'InstantSearchTopResult';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly content: Maybe<InstantSearchTopResultContent>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly relatedContent: Maybe<InstantSearchTopResultRelatedContent>;
}

export type InstantSearchTopResultContent =
	| Album
	| Artist
	| Livestream
	| Playlist
	| Podcast
	| PodcastEpisode
	| Track;

export interface InstantSearchTopResultRelatedContent {
	readonly __typename: 'InstantSearchTopResultRelatedContent';
	/**
	 * Can return error codes:
	 * * ERR_ALBUM_NOT_FOUND: Is returned when album doesn't exist
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly newRelease: Maybe<Album>;
}

export interface InstantSearchTracksConnection {
	readonly __typename: 'InstantSearchTracksConnection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly edges: ReadonlyArray<InstantSearchTracksConnectionEdge>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly pageInfo: PageInfo;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly priority: Scalars['Int']['output'];
}

export interface InstantSearchTracksConnectionEdge {
	readonly __typename: 'InstantSearchTracksConnectionEdge';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly cursor: Scalars['String']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly node: Maybe<Track>;
}

export interface InstantSearchUsersConnection {
	readonly __typename: 'InstantSearchUsersConnection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly edges: ReadonlyArray<InstantSearchUsersConnectionEdge>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly pageInfo: PageInfo;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly priority: Scalars['Int']['output'];
}

export interface InstantSearchUsersConnectionEdge {
	readonly __typename: 'InstantSearchUsersConnectionEdge';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly cursor: Scalars['String']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly node: Maybe<User>;
}

export enum ItemHighlightItemSupportInput {
	MIX = 'MIX',
}

export interface ItemHighlightSection {
	readonly __typename: 'ItemHighlightSection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly content: ItemHighlightSectionContent;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly subtitle: Maybe<Scalars['String']['output']>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly target: Maybe<SectionTarget>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly title: Maybe<Scalars['String']['output']>;
}

export interface ItemHighlightSectionContentArgs {
	support: ReadonlyArray<ItemHighlightItemSupportInput>;
}

export interface ItemHighlightSectionContent {
	readonly __typename: 'ItemHighlightSectionContent';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly items: ItemHighlightSectionContentCollection;
}

export interface ItemHighlightSectionContentItemsArgs {
	limit?: InputMaybe<Scalars['Int']['input']>;
}

export interface ItemHighlightSectionContentCollection {
	readonly __typename: 'ItemHighlightSectionContentCollection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly expectsMoreItems: Scalars['Boolean']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly nodes: ReadonlyArray<ItemHighlightSectionContentItemNode>;
}

export type ItemHighlightSectionContentItem = Mix;

export interface ItemHighlightSectionContentItemNode {
	readonly __typename: 'ItemHighlightSectionContentItemNode';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly item: ItemHighlightSectionContentItem;
}

export interface KeyValue {
	readonly __typename: 'KeyValue';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly key: Scalars['String']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly value: Scalars['String']['output'];
}

export enum LargeCardItemSupportInput {
	ALBUM = 'ALBUM',
	ARTIST = 'ARTIST',
	CHANNEL = 'CHANNEL',
	FLOW = 'FLOW',
	LIVESTREAM = 'LIVESTREAM',
	MIX = 'MIX',
	PLAYLIST = 'PLAYLIST',
	PODCAST = 'PODCAST',
	PROMOTED_CONTENT = 'PROMOTED_CONTENT',
	PROMOTIONAL_VIDEO = 'PROMOTIONAL_VIDEO',
	SMART_TRACKLIST = 'SMART_TRACKLIST',
}

export interface LargeCardSection {
	readonly __typename: 'LargeCardSection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly content: LargeCardSectionContent;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly subtitle: Maybe<Scalars['String']['output']>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly target: Maybe<SectionTarget>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly title: Maybe<Scalars['String']['output']>;
}

export interface LargeCardSectionContentArgs {
	support: ReadonlyArray<LargeCardItemSupportInput>;
}

export interface LargeCardSectionContent {
	readonly __typename: 'LargeCardSectionContent';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly items: LargeCardSectionContentCollection;
}

export interface LargeCardSectionContentItemsArgs {
	limit?: InputMaybe<Scalars['Int']['input']>;
}

export interface LargeCardSectionContentCollection {
	readonly __typename: 'LargeCardSectionContentCollection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly expectsMoreItems: Scalars['Boolean']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly nodes: ReadonlyArray<LargeCardSectionContentItemNode>;
}

export type LargeCardSectionContentItem =
	| Album
	| Artist
	| Channel
	| Flow
	| Livestream
	| Mix
	| Playlist
	| Podcast
	| PromotedContent
	| PromotionalVideo
	| SmartTracklist;

export interface LargeCardSectionContentItemNode {
	readonly __typename: 'LargeCardSectionContentItemNode';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly item: LargeCardSectionContentItem;
}

export enum ListItemSupportInput {
	ALBUM = 'ALBUM',
	ARTIST = 'ARTIST',
	CHANNEL = 'CHANNEL',
	LIVESTREAM = 'LIVESTREAM',
	MIX = 'MIX',
	PLAYLIST = 'PLAYLIST',
	PODCAST = 'PODCAST',
	PODCAST_EPISODE = 'PODCAST_EPISODE',
	PROMOTIONAL_VIDEO = 'PROMOTIONAL_VIDEO',
	TRACK = 'TRACK',
}

export interface ListSection {
	readonly __typename: 'ListSection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly content: ListSectionContent;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly subtitle: Maybe<Scalars['String']['output']>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly target: Maybe<SectionTarget>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly title: Maybe<Scalars['String']['output']>;
}

export interface ListSectionContentArgs {
	support: ReadonlyArray<ListItemSupportInput>;
}

export interface ListSectionContent {
	readonly __typename: 'ListSectionContent';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly items: ListSectionContentCollection;
}

export interface ListSectionContentItemsArgs {
	limit?: InputMaybe<Scalars['Int']['input']>;
}

export interface ListSectionContentCollection {
	readonly __typename: 'ListSectionContentCollection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly expectsMoreItems: Scalars['Boolean']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly nodes: ReadonlyArray<ListSectionContentItemNode>;
}

export type ListSectionContentItem =
	| Album
	| Artist
	| Channel
	| Livestream
	| Mix
	| Playlist
	| Podcast
	| PodcastEpisode
	| PromotionalVideo
	| Track;

export interface ListSectionContentItemNode {
	readonly __typename: 'ListSectionContentItemNode';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly item: ListSectionContentItem;
}

export interface LiveEvent extends Node {
	readonly __typename: 'LiveEvent';
	/**
	 * Assets for the live event, includes platform specific assets
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly assets: LiveEventAssets;
	/**
	 * In what city the live event takes place
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly cityName: Maybe<Scalars['String']['output']>;
	/**
	 * Contributors, may be non artists contributors
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly contributors: LiveEventContributorsConnection;
	/**
	 * The country code of the country the live event takes place in
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly countryCode: Maybe<Scalars['String']['output']>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly description: Maybe<Scalars['String']['output']>;
	/**
	 * Duration of the live event in minutes
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly duration: Maybe<Scalars['Int']['output']>;
	/**
	 * Did the user ask to be notified when the event status changes
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly hasSubscribedToNotification: Scalars['Boolean']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly id: Scalars['ID']['output'];
	/**
	 * Did the user bookmark the live event
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly isBookmarked: Maybe<Scalars['Boolean']['output']>;
	/**
	 * Actual live video stream of the live event
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly live: Maybe<LiveVideoStream>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly name: Scalars['String']['output'];
	/**
	 * Where does the event come from
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly sources: LiveEventSources;
	/**
	 * Expected start date, may differ from actual start date
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly startDate: Scalars['Date']['output'];
	/**
	 * Status of the live event, may differ from start date
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly status: LiveEventStatus;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly types: LiveEventTypes;
	/**
	 * In what venue the live event takes place
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly venue: Maybe<Scalars['String']['output']>;
	/**
	 * Videos related to the live event
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly videos: LiveEventVideoStreamsConnection;
}

export interface LiveEventContributorsArgs {
	after: InputMaybe<Scalars['String']['input']>;
	cursor: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
}

export interface LiveEventVideosArgs {
	after: InputMaybe<Scalars['String']['input']>;
	cursor: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
	types?: InputMaybe<ReadonlyArray<LiveEventVideoStreamTypeInput>>;
}

export interface LiveEventAssets {
	readonly __typename: 'LiveEventAssets';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly eventCardImageMobile: Maybe<Picture>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly eventCardImageWeb: Maybe<Picture>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly liveEventHeroMobile: Maybe<Picture>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly liveEventHeroWeb: Maybe<Picture>;
}

export interface LiveEventCoBranding {
	readonly __typename: 'LiveEventCoBranding';
	/**
	 * Access: This field is restricted to applications granted with specific access.
	 * @deprecated Use logoAsset instead
	 */
	readonly logo: Maybe<DynamicThemePicture>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly logoAsset: Maybe<DynamicUiAsset>;
}

export interface LiveEventConcertContributorRoles {
	readonly __typename: 'LiveEventConcertContributorRoles';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly isMain: Scalars['Boolean']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly isSupport: Scalars['Boolean']['output'];
}

export type LiveEventContributor = Artist;

export interface LiveEventContributorsConnection {
	readonly __typename: 'LiveEventContributorsConnection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly edges: ReadonlyArray<LiveEventContributorsEdge>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly pageInfo: PageInfo;
}

export interface LiveEventContributorsEdge {
	readonly __typename: 'LiveEventContributorsEdge';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly concertContributorMetadata: Maybe<ConcertContributorMetadata>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly cursor: Scalars['String']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly node: Maybe<LiveEventContributor>;
}

export interface LiveEventMutationBookmarkLiveEventOutput {
	readonly __typename: 'LiveEventMutationBookmarkLiveEventOutput';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly liveEvent: LiveEvent;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly status: Scalars['Boolean']['output'];
}

export interface LiveEventMutationSubscribeToNotificationOutput {
	readonly __typename: 'LiveEventMutationSubscribeToNotificationOutput';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly liveEvent: LiveEvent;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly status: Scalars['Boolean']['output'];
}

export interface LiveEventMutationUnbookmarkLiveEventOutput {
	readonly __typename: 'LiveEventMutationUnbookmarkLiveEventOutput';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly liveEvent: Maybe<LiveEvent>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly status: Scalars['Boolean']['output'];
}

export interface LiveEventMutationUnsubscribeToNotificationOutput {
	readonly __typename: 'LiveEventMutationUnsubscribeToNotificationOutput';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly liveEvent: LiveEvent;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly status: Scalars['Boolean']['output'];
}

export interface LiveEventSources {
	readonly __typename: 'LiveEventSources';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly coBranding: Maybe<LiveEventCoBranding>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly defaultUrl: Maybe<Scalars['String']['output']>;
	/**
	 * Access: This field is restricted to applications granted with specific access.
	 * @deprecated Use defaultUrl instead
	 */
	readonly songkick: Maybe<SongkickSource>;
}

export enum LiveEventStatus {
	CANCELLED = 'CANCELLED',
	ENDED = 'ENDED',
	PENDING = 'PENDING',
	STARTED = 'STARTED',
}

export interface LiveEventTypes {
	readonly __typename: 'LiveEventTypes';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly isConcert: Scalars['Boolean']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly isFestival: Scalars['Boolean']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly isLivestreamConcert: Scalars['Boolean']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly isLivestreamFestival: Scalars['Boolean']['output'];
}

export enum LiveEventVideoStreamTypeInput {
	DEEZER_SESSION = 'DEEZER_SESSION',
	DOCUMENTARY = 'DOCUMENTARY',
	INTERVIEW = 'INTERVIEW',
	REPLAY = 'REPLAY',
	TRAILER = 'TRAILER',
}

export interface LiveEventVideoStreamsConnection {
	readonly __typename: 'LiveEventVideoStreamsConnection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly edges: ReadonlyArray<LiveEventVideoStreamsEdge>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly pageInfo: PageInfo;
}

export interface LiveEventVideoStreamsEdge {
	readonly __typename: 'LiveEventVideoStreamsEdge';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly cursor: Scalars['String']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly node: Maybe<VideoStream>;
}

export interface LiveEventsConnection {
	readonly __typename: 'LiveEventsConnection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly edges: ReadonlyArray<LiveEventsConnectionEdge>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly pageInfo: PageInfo;
}

export interface LiveEventsConnectionEdge {
	readonly __typename: 'LiveEventsConnectionEdge';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly cursor: Scalars['String']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly node: Maybe<LiveEvent>;
}

export interface LiveTokenPackage {
	readonly __typename: 'LiveTokenPackage';
	/**
	 * Token cannot be used after its expiration date, must fetch again for a new one.
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly expirationDate: Maybe<Scalars['Date']['output']>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly token: Maybe<Scalars['String']['output']>;
}

export interface LiveVideoStream extends Node {
	readonly __typename: 'LiveVideoStream';
	/**
	 * External URL used to display the video
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly externalUrl: Maybe<ExternalUrl>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly id: Scalars['ID']['output'];
	/**
	 * Asset picture of the video stream
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly image: Maybe<Picture>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly subtitle: Maybe<Scalars['String']['output']>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly title: Maybe<Scalars['String']['output']>;
}

/** Information related to a given Livestream */
export interface Livestream extends Node {
	readonly __typename: 'Livestream';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly country: Maybe<Scalars['String']['output']>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly cover: Maybe<Picture>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly description: Maybe<Scalars['String']['output']>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly id: Scalars['ID']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly isFingerprinted: Scalars['Boolean']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly isOnStream: Scalars['Boolean']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly language: Maybe<Scalars['String']['output']>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly media: ReadonlyArray<ExternalMedia>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly name: Scalars['String']['output'];
}

export enum LongCardHorizontalGridItemSupportInput {
	ALBUM = 'ALBUM',
	ARTIST = 'ARTIST',
	CHANNEL = 'CHANNEL',
	LIVESTREAM = 'LIVESTREAM',
	MIX = 'MIX',
	PLAYLIST = 'PLAYLIST',
	PODCAST = 'PODCAST',
}

export interface LongCardHorizontalGridSection {
	readonly __typename: 'LongCardHorizontalGridSection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly content: LongCardHorizontalGridSectionContent;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly subtitle: Maybe<Scalars['String']['output']>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly target: Maybe<SectionTarget>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly title: Maybe<Scalars['String']['output']>;
}

export interface LongCardHorizontalGridSectionContentArgs {
	support: ReadonlyArray<LongCardHorizontalGridItemSupportInput>;
}

export interface LongCardHorizontalGridSectionContent {
	readonly __typename: 'LongCardHorizontalGridSectionContent';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly items: LongCardHorizontalGridSectionContentCollection;
}

export interface LongCardHorizontalGridSectionContentItemsArgs {
	limit?: InputMaybe<Scalars['Int']['input']>;
}

export interface LongCardHorizontalGridSectionContentCollection {
	readonly __typename: 'LongCardHorizontalGridSectionContentCollection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly expectsMoreItems: Scalars['Boolean']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly nodes: ReadonlyArray<LongCardHorizontalGridSectionContentItemNode>;
}

export type LongCardHorizontalGridSectionContentItem =
	| Album
	| Artist
	| Channel
	| Livestream
	| Mix
	| Playlist
	| Podcast;

export interface LongCardHorizontalGridSectionContentItemNode {
	readonly __typename: 'LongCardHorizontalGridSectionContentItemNode';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly item: LongCardHorizontalGridSectionContentItem;
}

export interface Lyrics extends Node {
	readonly __typename: 'Lyrics';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly copyright: Maybe<Scalars['String']['output']>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly id: Scalars['ID']['output'];
	/**
	 * When available, the list of lines of lyrics with their synchronization in terms of time code
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly synchronizedLines: Maybe<ReadonlyArray<LyricsSynchronizedLine>>;
	/**
	 * The full text without synchronization
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly text: Scalars['String']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly writers: Maybe<Scalars['String']['output']>;
}

/**
 * A synchronised line presents the text of a line of lyrics associated with its timestamp (two formats are given)
 * and the duration it should be displayed.
 */
export interface LyricsSynchronizedLine {
	readonly __typename: 'LyricsSynchronizedLine';
	/**
	 * The time to keep the line highlighted in milliseconds
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly duration: Maybe<Scalars['Int']['output']>;
	/**
	 * Text of the line of lyrics
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly line: Scalars['String']['output'];
	/**
	 * Text translation of the line of lyrics according to user language preferences
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly lineTranslated: Maybe<Scalars['String']['output']>;
	/**
	 * The timestamp in format [mm:ss.cc]
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly lrcTimestamp: Maybe<Scalars['String']['output']>;
	/**
	 * The same timestamp as lrcTimestamp but in milliseconds
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly milliseconds: Maybe<Scalars['Int']['output']>;
}

export interface MadeForMe {
	readonly cover: Maybe<Picture>;
	readonly id: Scalars['ID']['output'];
	readonly subTitle: Maybe<Scalars['String']['output']>;
	readonly title: Scalars['String']['output'];
}

export interface MarkAsNotPlayedPodcastEpisodeOutput {
	readonly __typename: 'MarkAsNotPlayedPodcastEpisodeOutput';
	/**
	 * Episode which was marked as not played.
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly episode: PodcastEpisode;
	/**
	 * Was the episode bookmark played status successfully updated
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly status: Scalars['Boolean']['output'];
}

export interface MarkAsPlayedPodcastEpisodeOutput {
	readonly __typename: 'MarkAsPlayedPodcastEpisodeOutput';
	/**
	 * Will return the updated bookmark.
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly bookmark: Maybe<PodcastEpisodeBookmark>;
	/**
	 * Was the episode bookmark played status successfully updated
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly status: Scalars['Boolean']['output'];
}

export interface MediaRights {
	readonly __typename: 'MediaRights';
	/**
	 * Rights for users with ads
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly ads: Maybe<AdsRights>;
	/**
	 * Rights for a subscribed user
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly sub: Maybe<SubRights>;
	/**
	 * Rights for an uploaded track
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly upload: Maybe<UploadRights>;
}

export interface MediaServiceLicenseTokenPackage {
	readonly __typename: 'MediaServiceLicenseTokenPackage';
	/**
	 * Token cannot be used after its expiration date, must fetch again for a new one.
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly expirationDate: Maybe<Scalars['Date']['output']>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly token: Maybe<Scalars['String']['output']>;
}

export interface MediaToken {
	readonly __typename: 'MediaToken';
	/**
	 * Expiration date of this token. If date is in the past, you have to fetch an updated one before sending it to media provider
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly expiresAt: Scalars['Int']['output'];
	/**
	 * Actual media provider token used to acquire CDN URL of the media
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly payload: Scalars['String']['output'];
}

export enum MediaTypeInput {
	/** Chapter of an autdiobook */
	CHAPTER = 'CHAPTER',
	/** Episode of a podcast */
	EPISODE = 'EPISODE',
	/** Live stream radio like FM radio */
	LIVE = 'LIVE',
	/** Media raw url */
	RAW_URL = 'RAW_URL',
	/** Trakc of an album */
	TRACK = 'TRACK',
}

export interface Meta {
	readonly __typename: 'Meta';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly type: Scalars['String']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly value: Scalars['String']['output'];
}

export enum MiniBannerItemSupportInput {
	PROMOTED_CONTENT = 'PROMOTED_CONTENT',
}

export interface MiniBannerSection {
	readonly __typename: 'MiniBannerSection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly content: MiniBannerSectionContent;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly hasAnimation: Scalars['Boolean']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly subtitle: Maybe<Scalars['String']['output']>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly target: Maybe<SectionTarget>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly title: Maybe<Scalars['String']['output']>;
}

export interface MiniBannerSectionContentArgs {
	support: ReadonlyArray<MiniBannerItemSupportInput>;
}

export interface MiniBannerSectionContent {
	readonly __typename: 'MiniBannerSectionContent';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly items: MiniBannerSectionContentCollection;
}

export interface MiniBannerSectionContentItemsArgs {
	limit?: InputMaybe<Scalars['Int']['input']>;
}

export interface MiniBannerSectionContentCollection {
	readonly __typename: 'MiniBannerSectionContentCollection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly expectsMoreItems: Scalars['Boolean']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly nodes: ReadonlyArray<MiniBannerSectionContentItemNode>;
}

export type MiniBannerSectionContentItem = PromotedContent;

export interface MiniBannerSectionContentItemNode {
	readonly __typename: 'MiniBannerSectionContentItemNode';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly item: MiniBannerSectionContentItem;
}

/** Information related to a given Mix */
export interface Mix extends Node {
	readonly __typename: 'Mix';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly id: Scalars['ID']['output'];
}

export interface MusicTogetherAffinity {
	readonly __typename: 'MusicTogetherAffinity';
	/**
	 * Compatibility score between the current user and the member (0 to 100)
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly compatibilityScore: Scalars['Int']['output'];
	/**
	 * Artists that the current user and the member should enjoy
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly discoveryArtists: MusicTogetherDiscoveryArtistsConnection;
	/**
	 * Tracks that the current user and the member should enjoy
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly discoveryTracks: MusicTogetherDiscoveryTracksConnection;
	/**
	 * The member with which affinity is calculated
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly member: MusicTogetherGroupMember;
}

export interface MusicTogetherAffinityDiscoveryArtistsArgs {
	after: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
}

export interface MusicTogetherAffinityDiscoveryTracksArgs {
	after: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
}

export interface MusicTogetherCreateGroupError {
	readonly __typename: 'MusicTogetherCreateGroupError';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly hasReachedGroupsLimit: Maybe<Scalars['Boolean']['output']>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly isInvalidGroupName: Maybe<Scalars['Boolean']['output']>;
}

export interface MusicTogetherCreateGroupInput {
	/** The group name. */
	readonly name: Scalars['String']['input'];
}

export interface MusicTogetherCreateGroupOutput {
	readonly __typename: 'MusicTogetherCreateGroupOutput';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly group: MusicTogetherGroup;
}

export type MusicTogetherCreateGroupResult =
	| MusicTogetherCreateGroupError
	| MusicTogetherCreateGroupOutput;

export interface MusicTogetherDiscoveryArtistsConnection {
	readonly __typename: 'MusicTogetherDiscoveryArtistsConnection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly edges: ReadonlyArray<MusicTogetherDiscoveryArtistsConnectionEdge>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly pageInfo: PageInfo;
}

export interface MusicTogetherDiscoveryArtistsConnectionEdge {
	readonly __typename: 'MusicTogetherDiscoveryArtistsConnectionEdge';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly cursor: Scalars['String']['output'];
	/**
	 * Can return error codes:
	 * * ERR_ARTIST_NOT_FOUND: Is returned when artist doesn't exist
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly node: Maybe<Artist>;
}

export interface MusicTogetherDiscoveryTracksConnection {
	readonly __typename: 'MusicTogetherDiscoveryTracksConnection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly edges: ReadonlyArray<MusicTogetherDiscoveryTracksConnectionEdge>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly pageInfo: PageInfo;
}

export interface MusicTogetherDiscoveryTracksConnectionEdge {
	readonly __typename: 'MusicTogetherDiscoveryTracksConnectionEdge';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly cursor: Scalars['String']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly node: Maybe<Track>;
}

export interface MusicTogetherEvent {
	readonly trackAddedToFavorite: InputMaybe<MusicTogetherEventTrackAddedToFavorite>;
}

export interface MusicTogetherEventTrackAddedToFavorite {
	/** The user who added the track to the playlist */
	readonly curatedBy: Scalars['ID']['input'];
	/** The group id */
	readonly groupId: Scalars['ID']['input'];
	/** The track id */
	readonly trackId: Scalars['ID']['input'];
}

export interface MusicTogetherGenerateGroupNameOutput {
	readonly __typename: 'MusicTogetherGenerateGroupNameOutput';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly name: Scalars['String']['output'];
}

export interface MusicTogetherGroup extends Node {
	readonly __typename: 'MusicTogetherGroup';
	/**
	 * Group affinities story of the day
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly affinitiesStory: Maybe<SimpleStory>;
	/**
	 * The date the group was created
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly createdAt: Scalars['Date']['output'];
	/**
	 * All tracks the group members liked or added manually
	 *
	 * Can return error codes:
	 * * ERR_PLAYLIST_NOT_FOUND: Is returned when playlist doesn't exist
	 * * ERR_PLAYLIST_ACCESS_NOT_AUTHORIZED: Is returned when playlist access is not allowed
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly curatedTracklist: Maybe<Playlist>;
	/**
	 * The estimated number of group members
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly estimatedMembersCount: Scalars['Int']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly id: Scalars['ID']['output'];
	/**
	 * True if the group has been generated from a family account
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly isFamily: Scalars['Boolean']['output'];
	/**
	 * Indicates if the group is ready (if it has enough members/data to provide the experience)
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly isReady: Scalars['Boolean']['output'];
	/**
	 * Paginated list of users group's members
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly members: MusicTogetherGroupMembersConnection;
	/**
	 * The group's name
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly name: Scalars['String']['output'];
	/**
	 * Members ids
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly rawMembers: ReadonlyArray<Scalars['ID']['output']>;
	/**
	 * The suggested tracklist based on group's members tastes, it could be null if group is not ready
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly suggestedTracklist: Maybe<MusicTogetherGroupSuggestedTracklist>;
	/**
	 * The date the group was last updated
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly updatedAt: Scalars['Date']['output'];
}

export interface MusicTogetherGroupMembersArgs {
	after: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
}

export interface MusicTogetherGroupSuggestedTracklistArgs {
	mood?: MusicTogetherSuggestedTracklistMoodInput;
}

export interface MusicTogetherGroupMember extends Node {
	readonly __typename: 'MusicTogetherGroupMember';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly id: Scalars['ID']['output'];
	/**
	 * The date the member joined the group
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 * @deprecated Do not use, moved in MusicTogetherGroup.members connection
	 */
	readonly joinedAt: Scalars['Date']['output'];
	/**
	 * The name of the group's member
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly name: Scalars['String']['output'];
	/**
	 * The picture of the group's member, could be null if user didn't set one
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly picture: Maybe<Picture>;
	/**
	 * The user profile of the group's member, could be null if user profile is private
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly user: Maybe<User>;
}

export interface MusicTogetherGroupMembersConnection {
	readonly __typename: 'MusicTogetherGroupMembersConnection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly edges: ReadonlyArray<MusicTogetherGroupMembersConnectionEdge>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly pageInfo: PageInfo;
}

export interface MusicTogetherGroupMembersConnectionEdge {
	readonly __typename: 'MusicTogetherGroupMembersConnectionEdge';
	/**
	 * The current's user affinity with the member
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly affinity: Maybe<MusicTogetherAffinity>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly cursor: Scalars['String']['output'];
	/**
	 * The date the member joined the group
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly joinedAt: Scalars['Date']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly node: Maybe<MusicTogetherGroupMember>;
}

export interface MusicTogetherGroupSuggestedTracklist {
	readonly __typename: 'MusicTogetherGroupSuggestedTracklist';
	/**
	 * Indicates if tracklist refresh is ongoing (could be a member that manually triggered the refresh, refresh triggered after a new user joined the group or an automatic refresh that happens every few days)
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly isRefreshing: Scalars['Boolean']['output'];
	/**
	 * The suggested tracklist queue that can be null if being refreshed
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly tracklist: Maybe<QueueChunk>;
}

export interface MusicTogetherJoinGroupError {
	readonly __typename: 'MusicTogetherJoinGroupError';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly isGroupFull: Maybe<Scalars['Boolean']['output']>;
}

export interface MusicTogetherJoinGroupInput {
	/** the MusicTogether suggested tracklist should be refreshed */
	readonly doRefreshSuggestedTracklist: Scalars['Boolean']['input'];
	/** the MusicTogether group's id to join */
	readonly musicTogetherGroupId: Scalars['String']['input'];
}

export interface MusicTogetherJoinGroupOutput {
	readonly __typename: 'MusicTogetherJoinGroupOutput';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly group: MusicTogetherGroup;
}

export type MusicTogetherJoinGroupResult =
	| MusicTogetherJoinGroupError
	| MusicTogetherJoinGroupOutput;

export interface MusicTogetherLeaveGroupError {
	readonly __typename: 'MusicTogetherLeaveGroupError';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly userNotInGroup: Maybe<Scalars['Boolean']['output']>;
}

export interface MusicTogetherLeaveGroupInput {
	/** the MusicTogether group's id to leave */
	readonly musicTogetherGroupId: Scalars['String']['input'];
}

export interface MusicTogetherLeaveGroupOutput {
	readonly __typename: 'MusicTogetherLeaveGroupOutput';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly group: Maybe<MusicTogetherGroup>;
}

export type MusicTogetherLeaveGroupResult =
	| MusicTogetherLeaveGroupError
	| MusicTogetherLeaveGroupOutput;

export interface MusicTogetherRefreshSuggestedTracklistError {
	readonly __typename: 'MusicTogetherRefreshSuggestedTracklistError';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly userNotInGroup: Maybe<Scalars['Boolean']['output']>;
}

export interface MusicTogetherRefreshSuggestedTracklistInput {
	readonly mood: MusicTogetherRefreshSuggestedTracklistMoodInput;
	readonly musicTogetherGroupId: Scalars['String']['input'];
}

export enum MusicTogetherRefreshSuggestedTracklistMoodInput {
	CHILL = 'CHILL',
	FOCUS = 'FOCUS',
	NONE = 'NONE',
	PARTY = 'PARTY',
}

export interface MusicTogetherRefreshSuggestedTracklistOutput {
	readonly __typename: 'MusicTogetherRefreshSuggestedTracklistOutput';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly group: MusicTogetherGroup;
}

export type MusicTogetherRefreshSuggestedTracklistResult =
	| MusicTogetherRefreshSuggestedTracklistError
	| MusicTogetherRefreshSuggestedTracklistOutput;

export enum MusicTogetherSuggestedTracklistMoodInput {
	CHILL = 'CHILL',
	FOCUS = 'FOCUS',
	NONE = 'NONE',
	PARTY = 'PARTY',
}

export interface MusicTogetherTrackMetadata {
	readonly __typename: 'MusicTogetherTrackMetadata';
	/**
	 * The reason of the track presence
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly trackOrigin: Maybe<ReadonlyArray<Maybe<MusicTogetherTrackOrigin>>>;
}

export interface MusicTogetherTrackOrigin {
	readonly __typename: 'MusicTogetherTrackOrigin';
	/**
	 * The group member involved
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly member: MusicTogetherGroupMember;
	/**
	 * The track is picked from member favorite artists tracks
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly pickedFromFavoriteArtists: Scalars['Boolean']['output'];
	/**
	 * The track is picked from member favorite tracks
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly pickedFromFavoriteTracks: Scalars['Boolean']['output'];
	/**
	 * The track has been streamed in repeat recently by the member
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly recentlyStreamedOnRepeat: Scalars['Boolean']['output'];
	/**
	 * The track has been streamed in the past year by the member
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly streamedPastYear: Scalars['Boolean']['output'];
}

export interface MusicTogetherTriggerEventInput {
	readonly event: MusicTogetherEvent;
}

export interface MusicTogetherTriggerEventOutput {
	readonly __typename: 'MusicTogetherTriggerEventOutput';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly status: Scalars['Boolean']['output'];
}

export interface MusicTogetherUpdateGroupSettingsError {
	readonly __typename: 'MusicTogetherUpdateGroupSettingsError';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly isInvalidGroupName: Maybe<Scalars['Boolean']['output']>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly userNotInGroup: Maybe<Scalars['Boolean']['output']>;
}

export interface MusicTogetherUpdateGroupSettingsInput {
	readonly musicTogetherGroupId: Scalars['String']['input'];
	readonly name: InputMaybe<Scalars['String']['input']>;
}

export interface MusicTogetherUpdateGroupSettingsOutput {
	readonly __typename: 'MusicTogetherUpdateGroupSettingsOutput';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly group: MusicTogetherGroup;
}

export type MusicTogetherUpdateGroupSettingsResult =
	| MusicTogetherUpdateGroupSettingsError
	| MusicTogetherUpdateGroupSettingsOutput;

export interface Mutation {
	readonly __typename: 'Mutation';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly addAlbumInSearchSuccessResult: SearchHistorySuccessResultsMutationOutput;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly addAlbumToFavorite: AlbumMutationFavoriteAddOut;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly addArtistInSearchSuccessResult: SearchHistorySuccessResultsMutationOutput;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly addArtistToFavorite: ArtistMutationFavoriteAddOut;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly addAudiobookAuthorToFavorite: AudiobookAuthorMutationFavoriteAddOut;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly addAudiobookToFavorite: AudiobookMutationFavoriteAddOut;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly addLivestreamInSearchSuccessResult: SearchHistorySuccessResultsMutationOutput;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly addMixInSearchSuccessResult: SearchHistorySuccessResultsMutationOutput;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly addPlaylistInSearchSuccessResult: SearchHistorySuccessResultsMutationOutput;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly addPlaylistToFavorite: PlaylistMutationFavoriteAddOut;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly addPodcastEpisodeInSearchSuccessResult: SearchHistorySuccessResultsMutationOutput;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly addPodcastInSearchSuccessResult: SearchHistorySuccessResultsMutationOutput;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly addPodcastToFavorite: PodcastMutationFavoriteAddOut;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly addQueryInUserSearchHistory: UserSearchedQueriesHistoryMutationOutput;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly addTrackInSearchSuccessResult: SearchHistorySuccessResultsMutationOutput;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly addTrackToFavorite: TrackMutationFavoriteAddOut;
	/** Access: This field requires one of these scopes: [partner-playlist-modify]. */
	readonly addTracksToPartnerPlaylist: AddTracksToPartnerPlaylistResult;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly addTracksToPlaylist: PlaylistAddTracksResult;
	/**
	 * Access: This field is restricted to applications granted with specific access.
	 * @deprecated Use addAlbumToFavorite and removeAlbumFromFavorite instead
	 */
	readonly album: AlbumMutation;
	/**
	 * Access: This field is restricted to applications granted with specific access.
	 * @deprecated Use addTracksToPlaylist instead
	 */
	readonly appendTracksToPlaylist: PlaylistAppendTracksMutationOutput;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly banArtistFromRecommendation: ArtistMutationBanOut;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly banTrackFromRecommendation: TrackMutationBanOut;
	/**
	 * Make a guess On a blind test question
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly blindTestMakeAGuess: BlindTestMakeAGuessOutput;
	/**
	 * Start a BlindTest Session
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly blindTestStartSession: BlindTestStartSessionOutput;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly bookmarkLiveEvent: LiveEventMutationBookmarkLiveEventOutput;
	/**
	 * Bookmarks a podcast episode
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly bookmarkPodcastEpisode: BookmarkPodcastEpisodeOutput;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly clearSearchSuccessResult: SearchHistorySuccessResultsMutationOutput;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly clearUserSearchHistory: UserSearchedQueriesHistoryMutationOutput;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly connectToRemoteTarget: Maybe<Scalars['Boolean']['output']>;
	/**
	 * Create a new queue
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly createNewQueue: CreateNewQueueOutput;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly createPlaylist: PlaylistCreateMutationOutput;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly createPlaylistFromSmartTracklist: PlaylistFromSmartTracklistCreateMutationResult;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly deletePlaylist: PlaylistDeleteMutationOutput;
	/**
	 * Launch the discovery process for systems that uses the discovery
	 * bridges
	 *
	 * The discovery offers will be trasnmitted though the pubsub service
	 * that was specified in the bus part of the intput.
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly discoverRemoteTargets: Maybe<Scalars['String']['output']>;
	/**
	 * Mark as not played a podcast episode
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly markAsNotPlayedPodcastEpisode: MarkAsNotPlayedPodcastEpisodeOutput;
	/**
	 * Mark as played a podcast episode
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly markAsPlayedPodcastEpisode: MarkAsPlayedPodcastEpisodeOutput;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly markAudiobookOnDeckAsCompleted: AudiobookOnDeckMutationOutput;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly moveTrackInPlaylist: PlaylistMoveTrackMutationOutput;
	/**
	 * Create a new Music Together group. Default values will be used if no input is provided
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly musicTogetherCreateGroup: MusicTogetherCreateGroupResult;
	/**
	 * Generates a music together random group name
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly musicTogetherGenerateGroupName: MusicTogetherGenerateGroupNameOutput;
	/**
	 * Joins an existing music together group
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly musicTogetherJoinGroup: MusicTogetherJoinGroupResult;
	/**
	 * Leave an existing music together group
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly musicTogetherLeaveGroup: MusicTogetherLeaveGroupResult;
	/**
	 * Refresh music together group's suggested tracklist input
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly musicTogetherRefreshSuggestedTracklist: MusicTogetherRefreshSuggestedTracklistResult;
	/**
	 * Create a music together event
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly musicTogetherTriggerEvent: MusicTogetherTriggerEventOutput;
	/**
	 * Update music together group settings
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly musicTogetherUpdateGroupSettings: MusicTogetherUpdateGroupSettingsResult;
	/**
	 * Access: This field is restricted to applications granted with specific access.
	 * @deprecated Do not use
	 */
	readonly node: Maybe<Node>;
	/**
	 * Register user participation to a contest
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly participateToContest: ParticipateToContestResult;
	/**
	 * WARNING!!
	 * It returns fake data and should only be used for development at the moment.
	 *
	 * Patch current queue state
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly patchQueueState: PatchQueueStateResult;
	/**
	 * WARNING!!
	 * It returns fake data and should only be used for development at the moment.
	 *
	 * Push a new current queue state
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly pushQueueState: PushQueueStateResult;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly removeAlbumFromFavorite: AlbumMutationFavoriteRemoveOut;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly removeArtistFromFavorite: ArtistMutationFavoriteRemoveOut;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly removeAudiobookAuthorFromFavorite: AudiobookAuthorMutationFavoriteRemoveOut;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly removeAudiobookFromDeck: AudiobookOnDeckRemoveMutationOutput;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly removeAudiobookFromFavorite: AudiobookMutationFavoriteRemoveOut;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly removePlaylistFromFavorite: PlaylistMutationFavoriteRemoveOut;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly removePodcastFromFavorite: PodcastMutationFavoriteRemoveOut;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly removeTrackFromFavorite: TrackMutationFavoriteRemoveOut;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly removeTracksFromPlaylist: PlaylistRemoveTracksMutationOutput;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly reportCatalogIssue: ReportCatalogIssueMutationResult;
	/**
	 * Access: This field is restricted to applications granted with specific access.
	 * @deprecated Score is now saved during the blindTestMakeAGuess mutation
	 */
	readonly saveBlindTestScore: SaveBlindTestScoreMutationOutput;
	/**
	 * Update the discoveryTuner with discovery/default value
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly setFlowDiscoveryTuner: SetDiscoveryTunerOutput;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly storeAudiobookOnDeckChapterState: AudiobookOnDeckMutationOutput;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly storeAudiobookOnDeckPlaybackSpeed: AudiobookOnDeckMutationOutput;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly subscribeToLiveEventNotification: LiveEventMutationSubscribeToNotificationOutput;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly unbanArtistFromRecommendation: ArtistMutationUnbanOut;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly unbanTrackFromRecommendation: TrackMutationUnbanOut;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly unbookmarkLiveEvent: LiveEventMutationUnbookmarkLiveEventOutput;
	/**
	 * Unbookmarks a podcast episode
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly unbookmarkPodcastEpisode: UnbookmarkPodcastEpisodeOutput;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly unsubscribeFromLiveEventNotification: LiveEventMutationUnsubscribeToNotificationOutput;
	/**
	 * Access: This field is restricted to applications granted with specific access.
	 * @deprecated Use unsubscribeFromLiveEventNotification instead
	 */
	readonly unsubscribeToLiveEventNotification: LiveEventMutationUnsubscribeToNotificationOutput;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly updatePlaylist: PlaylistUpdateMutationOutput;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly validateOnboardingArtistsPickerStep: ValidateOnboardingArtistsPickerStepOutput;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly validateOnboardingChooseNextStepStep: ValidateOnboardingChooseNextStepStepOutput;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly validateOnboardingImportLibraryStep: ValidateOnboardingImportLibraryStepOutput;
}

export interface MutationAddAlbumInSearchSuccessResultArgs {
	albumId: Scalars['String']['input'];
}

export interface MutationAddAlbumToFavoriteArgs {
	albumId: Scalars['String']['input'];
}

export interface MutationAddArtistInSearchSuccessResultArgs {
	artistId: Scalars['String']['input'];
}

export interface MutationAddArtistToFavoriteArgs {
	artistId: Scalars['String']['input'];
}

export interface MutationAddAudiobookAuthorToFavoriteArgs {
	authorId: Scalars['String']['input'];
	kidMode?: Scalars['Boolean']['input'];
}

export interface MutationAddAudiobookToFavoriteArgs {
	audiobookId: Scalars['String']['input'];
	kidMode?: Scalars['Boolean']['input'];
}

export interface MutationAddLivestreamInSearchSuccessResultArgs {
	livestreamId: Scalars['String']['input'];
}

export interface MutationAddMixInSearchSuccessResultArgs {
	mixId: Scalars['String']['input'];
}

export interface MutationAddPlaylistInSearchSuccessResultArgs {
	playlistId: Scalars['String']['input'];
}

export interface MutationAddPlaylistToFavoriteArgs {
	playlistId: Scalars['String']['input'];
}

export interface MutationAddPodcastEpisodeInSearchSuccessResultArgs {
	episodeId: Scalars['String']['input'];
}

export interface MutationAddPodcastInSearchSuccessResultArgs {
	podcastId: Scalars['String']['input'];
}

export interface MutationAddPodcastToFavoriteArgs {
	podcastId: Scalars['String']['input'];
}

export interface MutationAddQueryInUserSearchHistoryArgs {
	query: Scalars['String']['input'];
}

export interface MutationAddTrackInSearchSuccessResultArgs {
	trackId: Scalars['String']['input'];
}

export interface MutationAddTrackToFavoriteArgs {
	trackId: Scalars['String']['input'];
}

export interface MutationAddTracksToPartnerPlaylistArgs {
	input: AddTracksToPartnerPlaylistInput;
}

export interface MutationAddTracksToPlaylistArgs {
	input: PlaylistAddTracksMutationInput;
}

export interface MutationAlbumArgs {
	albumId: Scalars['String']['input'];
}

export interface MutationAppendTracksToPlaylistArgs {
	input: PlaylistAppendTracksMutationInput;
}

export interface MutationBanArtistFromRecommendationArgs {
	artistId: Scalars['String']['input'];
}

export interface MutationBanTrackFromRecommendationArgs {
	trackId: Scalars['String']['input'];
}

export interface MutationBlindTestMakeAGuessArgs {
	guess: Scalars['String']['input'];
	step: Scalars['Int']['input'];
	token: Scalars['String']['input'];
	value: Scalars['String']['input'];
}

export interface MutationBlindTestStartSessionArgs {
	additionalPlayers: InputMaybe<Scalars['Int']['input']>;
	id: Scalars['String']['input'];
	questionType?: BlindTestQuestionTypeInput;
	type: BlindTestTypeInput;
}

export interface MutationBookmarkLiveEventArgs {
	liveEventId: Scalars['String']['input'];
}

export interface MutationBookmarkPodcastEpisodeArgs {
	episodeId: Scalars['String']['input'];
	offset: Scalars['Int']['input'];
}

export interface MutationConnectToRemoteTargetArgs {
	input: ConnectInput;
}

export interface MutationCreateNewQueueArgs {
	input: CreateNewQueueInput;
}

export interface MutationCreatePlaylistArgs {
	input: PlaylistCreateMutationInput;
}

export interface MutationCreatePlaylistFromSmartTracklistArgs {
	input: PlaylistFromSmartTracklistCreateMutationInput;
}

export interface MutationDeletePlaylistArgs {
	input: PlaylistDeleteMutationInput;
}

export interface MutationDiscoverRemoteTargetsArgs {
	input: DiscoverInput;
}

export interface MutationMarkAsNotPlayedPodcastEpisodeArgs {
	episodeId: Scalars['String']['input'];
}

export interface MutationMarkAsPlayedPodcastEpisodeArgs {
	episodeId: Scalars['String']['input'];
}

export interface MutationMarkAudiobookOnDeckAsCompletedArgs {
	audiobookId: Scalars['String']['input'];
	kidMode?: Scalars['Boolean']['input'];
}

export interface MutationMoveTrackInPlaylistArgs {
	input: PlaylistMoveTrackMutationInput;
}

export interface MutationMusicTogetherCreateGroupArgs {
	input: InputMaybe<MusicTogetherCreateGroupInput>;
}

export interface MutationMusicTogetherJoinGroupArgs {
	input: MusicTogetherJoinGroupInput;
}

export interface MutationMusicTogetherLeaveGroupArgs {
	input: MusicTogetherLeaveGroupInput;
}

export interface MutationMusicTogetherRefreshSuggestedTracklistArgs {
	input: MusicTogetherRefreshSuggestedTracklistInput;
}

export interface MutationMusicTogetherTriggerEventArgs {
	input: MusicTogetherTriggerEventInput;
}

export interface MutationMusicTogetherUpdateGroupSettingsArgs {
	input: MusicTogetherUpdateGroupSettingsInput;
}

export interface MutationNodeArgs {
	id: Scalars['ID']['input'];
}

export interface MutationParticipateToContestArgs {
	input: ParticipateToContestInput;
}

export interface MutationPatchQueueStateArgs {
	input: PatchQueueStateInput;
}

export interface MutationPushQueueStateArgs {
	input: PushQueueStateInput;
}

export interface MutationRemoveAlbumFromFavoriteArgs {
	albumId: Scalars['String']['input'];
}

export interface MutationRemoveArtistFromFavoriteArgs {
	artistId: Scalars['String']['input'];
}

export interface MutationRemoveAudiobookAuthorFromFavoriteArgs {
	authorId: Scalars['String']['input'];
	kidMode?: Scalars['Boolean']['input'];
}

export interface MutationRemoveAudiobookFromDeckArgs {
	audiobookId: Scalars['String']['input'];
	kidMode?: Scalars['Boolean']['input'];
}

export interface MutationRemoveAudiobookFromFavoriteArgs {
	audiobookId: Scalars['String']['input'];
	kidMode?: Scalars['Boolean']['input'];
}

export interface MutationRemovePlaylistFromFavoriteArgs {
	playlistId: Scalars['String']['input'];
}

export interface MutationRemovePodcastFromFavoriteArgs {
	podcastId: Scalars['String']['input'];
}

export interface MutationRemoveTrackFromFavoriteArgs {
	trackId: Scalars['String']['input'];
}

export interface MutationRemoveTracksFromPlaylistArgs {
	input: PlaylistRemoveTracksMutationInput;
}

export interface MutationReportCatalogIssueArgs {
	input: InputMaybe<CatalogIssueInput>;
}

export interface MutationSaveBlindTestScoreArgs {
	id: Scalars['String']['input'];
	score: Scalars['Int']['input'];
	type: BlindTestTypeInput;
}

export interface MutationSetFlowDiscoveryTunerArgs {
	discoveryTuner: DiscoveryTunerInput;
}

export interface MutationStoreAudiobookOnDeckChapterStateArgs {
	audiobookId: Scalars['String']['input'];
	chapterId: Scalars['String']['input'];
	elapsedSeconds: Scalars['Int']['input'];
	kidMode?: Scalars['Boolean']['input'];
}

export interface MutationStoreAudiobookOnDeckPlaybackSpeedArgs {
	audiobookId: Scalars['String']['input'];
	kidMode?: Scalars['Boolean']['input'];
	playbackSpeed: Scalars['Int']['input'];
}

export interface MutationSubscribeToLiveEventNotificationArgs {
	liveEventId: Scalars['String']['input'];
}

export interface MutationUnbanArtistFromRecommendationArgs {
	artistId: Scalars['String']['input'];
}

export interface MutationUnbanTrackFromRecommendationArgs {
	trackId: Scalars['String']['input'];
}

export interface MutationUnbookmarkLiveEventArgs {
	liveEventId: Scalars['String']['input'];
}

export interface MutationUnbookmarkPodcastEpisodeArgs {
	episodeId: Scalars['String']['input'];
}

export interface MutationUnsubscribeFromLiveEventNotificationArgs {
	liveEventId: Scalars['String']['input'];
}

export interface MutationUnsubscribeToLiveEventNotificationArgs {
	liveEventId: Scalars['String']['input'];
}

export interface MutationUpdatePlaylistArgs {
	input: PlaylistUpdateMutationInput;
}

export interface MutationValidateOnboardingArtistsPickerStepArgs {
	context?: OnboardingContextInput;
	input: ValidateOnboardingArtistsPickerStepInput;
}

export interface MutationValidateOnboardingChooseNextStepStepArgs {
	context?: OnboardingContextInput;
}

export interface MutationValidateOnboardingImportLibraryStepArgs {
	context?: OnboardingContextInput;
	input: ValidateOnboardingImportLibraryStepInput;
}

export interface Node {
	readonly id: Scalars['ID']['output'];
}

export interface OnDeck {
	readonly __typename: 'OnDeck';
	/**
	 * User's audiobook chapters progression
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly rawAudiobookChapters: Maybe<ReadonlyArray<AudiobookChapterState>>;
	/**
	 * User's audiobooks on deck
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly rawAudiobooks: Maybe<ReadonlyArray<AudiobookState>>;
}

export interface OnDeckRawAudiobookChaptersArgs {
	audiobookId: InputMaybe<Scalars['String']['input']>;
	kidMode?: Scalars['Boolean']['input'];
}

export interface OnDeckRawAudiobooksArgs {
	kidMode?: Scalars['Boolean']['input'];
}

export interface Onboarding {
	readonly __typename: 'Onboarding';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly currentStep: Maybe<Scalars['String']['output']>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly shouldBeOnboarded: Scalars['Boolean']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly version: Scalars['String']['output'];
}

export interface OnboardingArtistsConnection {
	readonly __typename: 'OnboardingArtistsConnection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly edges: ReadonlyArray<OnboardingArtistsEdge>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly pageInfo: PageInfo;
}

export interface OnboardingArtistsEdge {
	readonly __typename: 'OnboardingArtistsEdge';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly cursor: Scalars['String']['output'];
	/**
	 * Can return error codes:
	 * * ERR_ARTIST_NOT_FOUND: Is returned when artist doesn't exist
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly node: Maybe<Artist>;
}

export enum OnboardingContextInput {
	ARTISTS_PICKER = 'ARTISTS_PICKER',
	IMPROVE = 'IMPROVE',
	MUSIC_TOGETHER = 'MUSIC_TOGETHER',
	WELCOME = 'WELCOME',
}

export interface OnboardingGenre {
	readonly __typename: 'OnboardingGenre';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly id: Scalars['String']['output'];
	/**
	 * Name of the genre, in the first language in order of preferred languages (defaults to original name)
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly name: Scalars['String']['output'];
}

export interface OnboardingRelatedArtistsConnection {
	readonly __typename: 'OnboardingRelatedArtistsConnection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly edges: ReadonlyArray<OnboardingRelatedArtistsEdge>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly pageInfo: PageInfo;
}

export interface OnboardingRelatedArtistsEdge {
	readonly __typename: 'OnboardingRelatedArtistsEdge';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly cursor: Scalars['String']['output'];
	/**
	 * Can return error codes:
	 * * ERR_ARTIST_NOT_FOUND: Is returned when artist doesn't exist
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly node: Maybe<Artist>;
}

export interface OnboardingSection {
	readonly __typename: 'OnboardingSection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly title: Maybe<Scalars['String']['output']>;
}

export interface OnboardingSteps {
	readonly __typename: 'OnboardingSteps';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly artistsPicker: ArtistsPickerStep;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly chooseNextStep: ChooseNextStepStep;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly importLibrary: ImportLibraryStep;
}

export interface Page extends CardTemplate {
	readonly __typename: 'Page';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly asset: Asset;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly assetCta: Maybe<Cta>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly background: Maybe<Asset>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly cta: Maybe<Cta>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly header: Maybe<Scalars['String']['output']>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly name: Scalars['String']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly text: ReadonlyArray<Scalars['String']['output']>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly title: Maybe<ReadonlyArray<Scalars['String']['output']>>;
}

export interface PageInfo {
	readonly __typename: 'PageInfo';
	/** When paginating forwards, the cursor to continue. */
	readonly endCursor: Maybe<Scalars['String']['output']>;
	/** When paginating forwards, are there more items? */
	readonly hasNextPage: Scalars['Boolean']['output'];
	/** When paginating backwards, are there more items? */
	readonly hasPreviousPage: Scalars['Boolean']['output'];
	/** When paginating backwards, the cursor to continue. */
	readonly startCursor: Maybe<Scalars['String']['output']>;
}

export interface ParticipateToContestError {
	readonly __typename: 'ParticipateToContestError';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly hasAlreadySubscribed: Maybe<Scalars['Boolean']['output']>;
}

export interface ParticipateToContestInput {
	/** Age of the user */
	readonly age: Scalars['Int']['input'];
	/** Birhtday of the user */
	readonly birthday: InputMaybe<Scalars['String']['input']>;
	/** City of the user */
	readonly city: InputMaybe<Scalars['String']['input']>;
	/** Identifier of the the contest the user participates to */
	readonly contestId: Scalars['String']['input'];
	/** Email of the user */
	readonly email: Scalars['String']['input'];
	/** First name of the user */
	readonly firstName: Scalars['String']['input'];
	/** Does the user already owns a ticket for this event */
	readonly hasTicket: InputMaybe<Scalars['Boolean']['input']>;
	/** Will the user attend to the event on its own */
	readonly isSolo: InputMaybe<Scalars['Boolean']['input']>;
	/** Last name of the user */
	readonly lastName: Scalars['String']['input'];
	/** Phone number of the user */
	readonly phone: Scalars['String']['input'];
}

export interface ParticipateToContestOutput {
	readonly __typename: 'ParticipateToContestOutput';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly status: Maybe<Scalars['Boolean']['output']>;
}

export type ParticipateToContestResult =
	| ParticipateToContestError
	| ParticipateToContestOutput;

export interface PatchQueueStateAddEventContextInput {
	/** The context content related to the media */
	readonly content: Scalars['String']['input'];
	/** Unique identifier of the media within the queue as queue can contain multiple time the same media */
	readonly id: Scalars['ID']['input'];
}

/** Event of a media being added to the queue */
export interface PatchQueueStateAddEventInput {
	/** The context related to the media being added */
	readonly context: InputMaybe<PatchQueueStateAddEventContextInput>;
	/** The media id that was added */
	readonly id: Scalars['ID']['input'];
	/**
	 * The position within the queue at which the media was put
	 * Note: Queue positions are 0-indexed, use 0 if media was added at the very begining of the queue
	 */
	readonly position: Scalars['Int']['input'];
	/** The media type that was added */
	readonly type: MediaTypeInput;
}

/** Details about the client that wants to apply the patch */
export interface PatchQueueStateClientDetailsInput {
	/** Identifier of the client's local queue, doesn't necessarily match a real QueueId */
	readonly localQueueID: Scalars['String']['input'];
	/** Date in ISO-8601 format including milliseconds when last update was made on client */
	readonly locallyUpdatedAt: Scalars['Date']['input'];
}

export interface PatchQueueStateConflict {
	readonly __typename: 'PatchQueueStateConflict';
	/**
	 * Current queue state in conflict with the version being patched
	 * Could be null if queue state is in an inconsistent state
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly current: Maybe<QueueState>;
}

export interface PatchQueueStateError {
	readonly __typename: 'PatchQueueStateError';
	/**
	 * Set when supplied patch conflicts with an already present queue state, otherwise will be null
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly conflict: Maybe<PatchQueueStateConflict>;
}

/**
 * Event that occurred on a queue that should be applied by a patch
 * Note: even if this input has multiple fields, you MUST USE ONLY ONE field at a time.
 */
export interface PatchQueueStateEventInput {
	readonly add: InputMaybe<PatchQueueStateAddEventInput>;
	readonly move: InputMaybe<PatchQueueStateMoveEventInput>;
	readonly remove: InputMaybe<PatchQueueStateRemoveEventInput>;
	readonly shuffle: InputMaybe<PatchQueueStateShuffleEventInput>;
}

export interface PatchQueueStateInput {
	/** Details about the client patching the queue state */
	readonly clientDetails: PatchQueueStateClientDetailsInput;
	/** Zero based index of the current playback position in the queue */
	readonly position: Scalars['Int']['input'];
	/** Queue state's version patch should be applied from. */
	readonly previousVersion: Scalars['String']['input'];
	/** New queue's configuration */
	readonly queueConfiguration: InputMaybe<Scalars['String']['input']>;
	/** List of all events that occurred since last queue state was retrieved */
	readonly queueEvents: InputMaybe<ReadonlyArray<PatchQueueStateEventInput>>;
	/** New repeat mode set for the queue */
	readonly repeatMode: InputMaybe<RepeatModeInput>;
}

/** Event of a track that have been moved within the queue */
export interface PatchQueueStateMoveEventInput {
	/** The old position of the media that was moved */
	readonly from: Scalars['Int']['input'];
	/** The media's new position */
	readonly to: Scalars['Int']['input'];
}

export interface PatchQueueStateOutput {
	readonly __typename: 'PatchQueueStateOutput';
	/**
	 * Server-side update date
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly updatedAt: Scalars['Date']['output'];
	/**
	 * Version of the new created State
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly version: Scalars['String']['output'];
}

/** Event of a media that was removed from the queue */
export interface PatchQueueStateRemoveEventInput {
	/**
	 * The position within the queue of the media that was removed
	 * Note: Queue positions are 0-indexed, use 0 if the first media was removed
	 */
	readonly position: Scalars['Int']['input'];
}

export type PatchQueueStateResult =
	| PatchQueueStateError
	| PatchQueueStateOutput;

/** Event of shuffle mode that was enabled or disabled on the queue */
export interface PatchQueueStateShuffleEventInput {
	/** Shuffled positions of each media in case queue was shuffled, just omit it if shuffle mode was disabled */
	readonly positions: InputMaybe<ReadonlyArray<Scalars['Int']['input']>>;
}

/** Permissions information */
export interface Permissions {
	readonly __typename: 'Permissions';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly access: Access;
	/**
	 * Groups all the permissions related to the games
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly games: Maybe<GamesPermissions>;
}

export interface PersonalStats {
	readonly __typename: 'PersonalStats';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly campaignTitle: Scalars['String']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly card: Maybe<Card>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly cards: ReadonlyArray<Card>;
}

export interface PersonalStatsCardArgs {
	cardId: Scalars['String']['input'];
}

export interface Picture {
	readonly __typename: 'Picture';
	/** If applicable, the copyright text associated with the picture */
	readonly copyright: Maybe<Scalars['String']['output']>;
	/** If true, the picture is considered explicit and may display some mature content in it */
	readonly explicitStatus: Scalars['Boolean']['output'];
	/** Stable identification (over time) for the picture itself */
	readonly id: Scalars['String']['output'];
	/**
	 * DO NOT USE
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 * @deprecated Use urls directly, or prefer using uid for identification. md5 for migration purpose only
	 */
	readonly md5: Scalars['String']['output'];
	/**
	 * DO NOT USE
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 * @deprecated Use urls instead
	 */
	readonly url: ReadonlyArray<Scalars['String']['output']>;
	/** List of URLS to get the picture data. All urls will return the same image. Try them one by one in order until success */
	readonly urls: ReadonlyArray<Scalars['String']['output']>;
}

export interface PictureUrlsArgs {
	pictureRequest: PictureRequest;
}

export interface PictureRequest {
	/** In case of croped picture (not square) color of the background in hexa decimal like 000000, or FF00FF. Default is 000000 */
	readonly backgroundColor: InputMaybe<Scalars['String']['input']>;
	/** Height of the picture requested. Max 1500 */
	readonly height: Scalars['Int']['input'];
	/** JPG quality of the picture between 0 and 100. Default is 80 */
	readonly quality: InputMaybe<Scalars['Int']['input']>;
	/** Width of the picture requested. Max 1500 */
	readonly width: Scalars['Int']['input'];
}

/** Playlist is a set of tracks generated by users or Deezer staff */
export interface Playlist extends Node {
	readonly __typename: 'Playlist';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly blindTestable: PlaylistBlindTestable;
	/**
	 * Identifies the date when the playlist was created
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly creationDate: Scalars['Date']['output'];
	/**
	 * The playlist's description
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly description: Maybe<Scalars['String']['output']>;
	/**
	 * This duration could be inexact because it relies on original tracks and doesn't take in account tracks availability / use of fallbacks with different duration, use for display only
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly estimatedDuration: Scalars['Int']['output'];
	/**
	 * This duration could be inexact because it relies on original tracks and doesn't take in account tracks availability, use for display only
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly estimatedTracksCount: Scalars['Int']['output'];
	/**
	 * Identifies the fans count of the playlist
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly fansCount: Scalars['Int']['output'];
	/**
	 * Favorite-related infos
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly favorite: Maybe<PlaylistFavorite>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly id: Scalars['ID']['output'];
	/**
	 * Whether or not a blindtest can be computed from this playlist
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 * @deprecated Do not use
	 */
	readonly isBlindTestable: Scalars['Boolean']['output'];
	/**
	 * Whether or not this playlist is a charts playlist
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly isCharts: Scalars['Boolean']['output'];
	/**
	 * Whether or not this playlist is collaborative
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly isCollaborative: Scalars['Boolean']['output'];
	/**
	 * Whether or not this playlist is managed by a Deezer editor
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly isEditorialized: Scalars['Boolean']['output'];
	/**
	 * Whether or not this playlist is managed by a partner's editor
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly isEditorializedByPartner: Scalars['Boolean']['output'];
	/**
	 * Whether or not the playlist is in the currently logged-in user's favorites
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly isFavorite: Maybe<Scalars['Boolean']['output']>;
	/**
	 * Whether or not this playlist based on the user favorite tracks
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly isFromFavoriteTracks: Scalars['Boolean']['output'];
	/**
	 * Whether or not this playlist is private
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly isPrivate: Scalars['Boolean']['output'];
	/**
	 * Whether or not this playlist is sponsored
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly isSponsored: Scalars['Boolean']['output'];
	/**
	 * Identifies the date when the playlist was last modified
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly lastModificationDate: Scalars['Date']['output'];
	/**
	 * The artist linked to the playlist
	 *
	 * Can return error codes:
	 * * ERR_ARTIST_NOT_FOUND: Is returned when artist doesn't exist
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly linkedArtist: Maybe<Artist>;
	/**
	 * Playlist most popular tracks
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly mostPopularTrack: Maybe<Track>;
	/**
	 * The playlist owner if his profile is public
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly owner: Maybe<User>;
	/**
	 * The playlist picture
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly picture: Maybe<Picture>;
	/**
	 * User's playlist assistant
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly playlistAssistant: Maybe<PlaylistAssistant>;
	/**
	 * The full list of the playlist's raw tracks ids.
	 * You may receive one track, or thousands, hence you must fetch this field only when absolutely needed, and only once per query.
	 * We defined the cost of the field accordingly.
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly rawTracks: ReadonlyArray<PlaylistRawTrack>;
	/**
	 * The playlist's title
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly title: Scalars['String']['output'];
	/**
	 * Paginated list of the playlist's tracks
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly tracks: PlaylistTracksConnection;
}

/** Playlist is a set of tracks generated by users or Deezer staff */
export interface PlaylistRawTracksArgs {
	order?: InputMaybe<PlaylistTracksOrder>;
}

/** Playlist is a set of tracks generated by users or Deezer staff */
export interface PlaylistTracksArgs {
	after: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
	order?: InputMaybe<PlaylistTracksOrder>;
}

export interface PlaylistActor {
	readonly __typename: 'PlaylistActor';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly id: Scalars['ID']['output'];
	/**
	 * Name of the user who added the track to the playlist
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly name: Scalars['String']['output'];
	/**
	 * Avatar of the user who added the track to the playlist
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly picture: Maybe<Picture>;
}

export interface PlaylistAddTracksError {
	readonly __typename: 'PlaylistAddTracksError';
	/**
	 * Indicates if the user is not allowed to add tracks
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly isNotAllowed: Maybe<Scalars['Boolean']['output']>;
}

export interface PlaylistAddTracksMutationInput {
	/** The playlist to update */
	readonly playlistId: Scalars['String']['input'];
	/** The track ids to add */
	readonly trackIds: ReadonlyArray<Scalars['String']['input']>;
}

export interface PlaylistAddTracksOutput {
	readonly __typename: 'PlaylistAddTracksOutput';
	/**
	 * The track ids that were added
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly addedTrackIds: ReadonlyArray<Scalars['String']['output']>;
	/**
	 * The tracks ids that were not added because playlist limit was reached
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly beyondLimitTrackIds: ReadonlyArray<Scalars['String']['output']>;
	/**
	 * The tracks ids that were not added because already present in the playlist
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly duplicatedTrackIds: ReadonlyArray<Scalars['String']['output']>;
	/**
	 * The updated playlist
	 *
	 * Can return error codes:
	 * * ERR_PLAYLIST_NOT_FOUND: Is returned when playlist doesn't exist
	 * * ERR_PLAYLIST_ACCESS_NOT_AUTHORIZED: Is returned when playlist access is not allowed
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly playlist: Maybe<Playlist>;
}

export type PlaylistAddTracksResult =
	| PlaylistAddTracksError
	| PlaylistAddTracksOutput;

export interface PlaylistAppendTracksMutationInput {
	/** The playlist to update */
	readonly playlistId: Scalars['String']['input'];
	/** The track ids to add */
	readonly trackIds: ReadonlyArray<Scalars['String']['input']>;
}

export interface PlaylistAppendTracksMutationOutput {
	readonly __typename: 'PlaylistAppendTracksMutationOutput';
	/**
	 * The track ids that were added
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly addedTrackIds: ReadonlyArray<Scalars['String']['output']>;
	/**
	 * The updated playlist
	 *
	 * Can return error codes:
	 * * ERR_PLAYLIST_NOT_FOUND: Is returned when playlist doesn't exist
	 * * ERR_PLAYLIST_ACCESS_NOT_AUTHORIZED: Is returned when playlist access is not allowed
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly playlist: Maybe<Playlist>;
}

export interface PlaylistAssistant {
	readonly __typename: 'PlaylistAssistant';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly sources: ReadonlyArray<Maybe<PlaylistAssistantSource>>;
}

export interface PlaylistAssistantSource {
	readonly __typename: 'PlaylistAssistantSource';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly id: Scalars['String']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly title: Scalars['String']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly tracks: ReadonlyArray<Maybe<Track>>;
}

export interface PlaylistAssistantSourceTracksArgs {
	first?: InputMaybe<Scalars['Int']['input']>;
}

export interface PlaylistBlindTestable {
	readonly __typename: 'PlaylistBlindTestable';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly artistMode: Scalars['Boolean']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly trackMode: Scalars['Boolean']['output'];
}

export interface PlaylistCreateMutationInput {
	/** The playlist's description */
	readonly description: InputMaybe<Scalars['String']['input']>;
	/** Whether or not this playlist is collaborative. Beware that a private playlist cannot be collaborative */
	readonly isCollaborative: Scalars['Boolean']['input'];
	/** Whether or not this playlist is private */
	readonly isPrivate: Scalars['Boolean']['input'];
	/** The playlist picture. You must use the hash provided by the upload server at `/v2/playlist/picture` */
	readonly picture: InputMaybe<Scalars['String']['input']>;
	/** The playlist's title */
	readonly title: Scalars['String']['input'];
}

export interface PlaylistCreateMutationOutput {
	readonly __typename: 'PlaylistCreateMutationOutput';
	/**
	 * Can return error codes:
	 * * ERR_PLAYLIST_NOT_FOUND: Is returned when playlist doesn't exist
	 * * ERR_PLAYLIST_ACCESS_NOT_AUTHORIZED: Is returned when playlist access is not allowed
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly playlist: Maybe<Playlist>;
}

export interface PlaylistDeleteMutationInput {
	/** The playlist to delete */
	readonly playlistId: Scalars['String']['input'];
}

export interface PlaylistDeleteMutationOutput {
	readonly __typename: 'PlaylistDeleteMutationOutput';
	/**
	 * The status of the deletion, true if successful, otherwise false
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly deleteStatus: Maybe<Scalars['Boolean']['output']>;
}

export interface PlaylistFavorite {
	readonly __typename: 'PlaylistFavorite';
	/**
	 * When was the favorite added
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly favoritedAt: Scalars['Date']['output'];
	/**
	 * When was the favorite last seen
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly lastSeen: Maybe<Scalars['Date']['output']>;
}

export interface PlaylistFromSmartTracklistCreateMutationError {
	readonly __typename: 'PlaylistFromSmartTracklistCreateMutationError';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly isSmartTracklistNotFound: Maybe<Scalars['Boolean']['output']>;
}

export interface PlaylistFromSmartTracklistCreateMutationInput {
	/** The smartTracklist's id */
	readonly smartTracklistId: Scalars['String']['input'];
}

export interface PlaylistFromSmartTracklistCreateMutationOutput {
	readonly __typename: 'PlaylistFromSmartTracklistCreateMutationOutput';
	/**
	 * Can return error codes:
	 * * ERR_PLAYLIST_NOT_FOUND: Is returned when playlist doesn't exist
	 * * ERR_PLAYLIST_ACCESS_NOT_AUTHORIZED: Is returned when playlist access is not allowed
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly playlist: Maybe<Playlist>;
}

export type PlaylistFromSmartTracklistCreateMutationResult =
	| PlaylistFromSmartTracklistCreateMutationError
	| PlaylistFromSmartTracklistCreateMutationOutput;

export interface PlaylistMoveTrackMutationInput {
	/** The track that should precede the track being moved, do not specify if track should be moved at first position */
	readonly afterTrackId: InputMaybe<Scalars['String']['input']>;
	/** The playlist id */
	readonly playlistId: Scalars['String']['input'];
	/** The track to move */
	readonly trackId: Scalars['String']['input'];
}

export interface PlaylistMoveTrackMutationOutput {
	readonly __typename: 'PlaylistMoveTrackMutationOutput';
	/**
	 * The operation status, true if move was successful, false otherwise
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly moveStatus: Maybe<Scalars['Boolean']['output']>;
	/**
	 * The updated playlist
	 *
	 * Can return error codes:
	 * * ERR_PLAYLIST_NOT_FOUND: Is returned when playlist doesn't exist
	 * * ERR_PLAYLIST_ACCESS_NOT_AUTHORIZED: Is returned when playlist access is not allowed
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly playlist: Maybe<Playlist>;
}

export interface PlaylistMutationFavoriteAddOut {
	readonly __typename: 'PlaylistMutationFavoriteAddOut';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly favoritedAt: Scalars['Date']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly playlist: Playlist;
}

export interface PlaylistMutationFavoriteRemoveOut {
	readonly __typename: 'PlaylistMutationFavoriteRemoveOut';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly playlist: Playlist;
}

export interface PlaylistRawTrack {
	readonly __typename: 'PlaylistRawTrack';
	/**
	 * Identifies the date when the track was added to the playlist
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly addedAt: Scalars['Date']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly trackId: Scalars['ID']['output'];
	/**
	 * The track's progression within a charts playlist
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly variation: Maybe<Scalars['Int']['output']>;
}

export interface PlaylistRemoveTracksMutationInput {
	/** The playlist to update */
	readonly playlistId: Scalars['String']['input'];
	/** The track ids to remove */
	readonly trackIds: ReadonlyArray<Scalars['String']['input']>;
}

export interface PlaylistRemoveTracksMutationOutput {
	readonly __typename: 'PlaylistRemoveTracksMutationOutput';
	/**
	 * The updated playlist
	 *
	 * Can return error codes:
	 * * ERR_PLAYLIST_NOT_FOUND: Is returned when playlist doesn't exist
	 * * ERR_PLAYLIST_ACCESS_NOT_AUTHORIZED: Is returned when playlist access is not allowed
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly playlist: Maybe<Playlist>;
	/**
	 * The track ids that were removed
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly removedTrackIds: ReadonlyArray<Scalars['String']['output']>;
}

export interface PlaylistTracksConnection {
	readonly __typename: 'PlaylistTracksConnection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly edges: ReadonlyArray<PlaylistTracksEdge>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly pageInfo: PageInfo;
}

export interface PlaylistTracksEdge {
	readonly __typename: 'PlaylistTracksEdge';
	/**
	 * Identifies the user who added the track to the playlist
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly actor: Maybe<PlaylistActor>;
	/**
	 * Identifies the date when the track was added to the playlist
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly addedAt: Scalars['Date']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly cursor: Scalars['String']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly node: Maybe<Track>;
	/**
	 * The track's progression within a charts playlist
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly variation: Maybe<Scalars['Int']['output']>;
}

export enum PlaylistTracksOrder {
	ADDED_AT_DESC = 'ADDED_AT_DESC',
	MANUAL = 'MANUAL',
}

export interface PlaylistUpdateMutationInput {
	/** The new playlist's description */
	readonly description: InputMaybe<Scalars['String']['input']>;
	/** Whether or not this playlist is collaborative. Beware that a private playlist cannot be collaborative */
	readonly isCollaborative: InputMaybe<Scalars['Boolean']['input']>;
	/** Whether or not this playlist is private */
	readonly isPrivate: InputMaybe<Scalars['Boolean']['input']>;
	/** The new playlist picture. You must use the hash provided by the upload server at `/v2/playlist/picture` */
	readonly picture: InputMaybe<Scalars['String']['input']>;
	/** The playlist to update */
	readonly playlistId: Scalars['String']['input'];
	/** The new playlist's title */
	readonly title: InputMaybe<Scalars['String']['input']>;
}

export interface PlaylistUpdateMutationOutput {
	readonly __typename: 'PlaylistUpdateMutationOutput';
	/**
	 * Can return error codes:
	 * * ERR_PLAYLIST_NOT_FOUND: Is returned when playlist doesn't exist
	 * * ERR_PLAYLIST_ACCESS_NOT_AUTHORIZED: Is returned when playlist access is not allowed
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly playlist: Maybe<Playlist>;
}

export interface PlaylistsConnection {
	readonly __typename: 'PlaylistsConnection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly edges: ReadonlyArray<PlaylistsConnectionEdge>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly pageInfo: PageInfo;
}

export interface PlaylistsConnectionEdge {
	readonly __typename: 'PlaylistsConnectionEdge';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly cursor: Scalars['String']['output'];
	/**
	 * Can return error codes:
	 * * ERR_PLAYLIST_NOT_FOUND: Is returned when playlist doesn't exist
	 * * ERR_PLAYLIST_ACCESS_NOT_AUTHORIZED: Is returned when playlist access is not allowed
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly node: Maybe<Playlist>;
}

/**
 * Information related to a given podcast
 *
 * Podcast can be composed of multiple episodes
 */
export interface Podcast extends Node {
	readonly __typename: 'Podcast';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly cover: Maybe<Picture>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly description: Maybe<Scalars['String']['output']>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly displayTitle: Scalars['String']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly episodes: PodcastEpisodesConnection;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly id: Scalars['ID']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly isAdvertisingAllowed: Scalars['Boolean']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly isDownloadAllowed: Scalars['Boolean']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly isExplicit: Scalars['Boolean']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly isFavorite: Scalars['Boolean']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly rawEpisodes: ReadonlyArray<Scalars['ID']['output']>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly rights: PodcastRights;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly type: PodcastType;
}

/**
 * Information related to a given podcast
 *
 * Podcast can be composed of multiple episodes
 */
export interface PodcastEpisodesArgs {
	after: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
}

/** Information related to a given podcast episode */
export interface PodcastEpisode extends Node {
	readonly __typename: 'PodcastEpisode';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly cover: Maybe<Picture>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly description: Maybe<Scalars['String']['output']>;
	/**
	 * Duration of the podcast episode in seconds
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly duration: Scalars['Int']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly id: Scalars['ID']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly media: ExternalMedia;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly podcast: Podcast;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly releaseDate: Scalars['Date']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly title: Scalars['String']['output'];
	/**
	 * Absolute canonical URL to the podcast episode page on Deezer
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly url: DeezerUrl;
}

/** Information related to a given podcast episode bookmark */
export interface PodcastEpisodeBookmark {
	readonly __typename: 'PodcastEpisodeBookmark';
	/**
	 * Date the bookmark was created at
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly bookmarkedAt: Scalars['Date']['output'];
	/**
	 * Podcast episode that has been bookmarked
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly episode: PodcastEpisode;
	/**
	 * Has the bookmarked been played
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly isPlayed: Scalars['Boolean']['output'];
	/**
	 * Listening time the bookmark is at
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly position: Scalars['Int']['output'];
}

export interface PodcastEpisodesConnection {
	readonly __typename: 'PodcastEpisodesConnection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly edges: ReadonlyArray<PodcastEpisodesEdge>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly pageInfo: PageInfo;
}

export interface PodcastEpisodesEdge {
	readonly __typename: 'PodcastEpisodesEdge';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly cursor: Scalars['String']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly node: Maybe<PodcastEpisode>;
}

export interface PodcastMutationFavoriteAddOut {
	readonly __typename: 'PodcastMutationFavoriteAddOut';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly favoritedAt: Scalars['Date']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly podcast: Podcast;
}

export interface PodcastMutationFavoriteRemoveOut {
	readonly __typename: 'PodcastMutationFavoriteRemoveOut';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly podcast: Maybe<Podcast>;
}

export interface PodcastRights {
	readonly __typename: 'PodcastRights';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly ads: PodcastRightsAds;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly sub: PodcastRightsSub;
}

export interface PodcastRightsAds {
	readonly __typename: 'PodcastRightsAds';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly available: Scalars['Boolean']['output'];
}

export interface PodcastRightsSub {
	readonly __typename: 'PodcastRightsSub';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly available: Scalars['Boolean']['output'];
}

export enum PodcastType {
	EPISODIC = 'EPISODIC',
	SERIAL = 'SERIAL',
}

export interface Poster extends CardTemplate {
	readonly __typename: 'Poster';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly background: Maybe<Asset>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly coversList: ReadonlyArray<Illustration>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly cta: Maybe<Cta>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly header: Maybe<Scalars['String']['output']>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly name: Scalars['String']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly text: Maybe<ReadonlyArray<Scalars['String']['output']>>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly title: Maybe<ReadonlyArray<Scalars['String']['output']>>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly watermark: Maybe<Illustration>;
}

export interface PrivateConsentData {
	readonly __typename: 'PrivateConsentData';
	/**
	 * User's consent string ( empty on invalid )
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly consentString: Maybe<Scalars['String']['output']>;
	/**
	 * Does user has allowed statistic
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly hasAllowedStatistics: Scalars['Boolean']['output'];
	/**
	 * Does user has allowed targeted ads
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly hasAllowedTargetedAds: Scalars['Boolean']['output'];
}

export interface PrivateUser extends Node {
	readonly __typename: 'PrivateUser';
	/**
	 * Opaque string to pass to the media service allongside tracktoken to play music.
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 * @deprecated Use mediaServiceLicenseToken instead. It's the same field
	 */
	readonly MediaServiceLicenseToken: Maybe<MediaServiceLicenseTokenPackage>;
	/**
	 * User's content banned from recommencation aggregation
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly bannedFromRecommendation: BannedFromRecommendation;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly charts: UserCharts;
	/**
	 * Get active contests list user is participating to.
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly contestParticipations: Maybe<ReadonlyArray<ContestParticipation>>;
	/**
	 * Estimated number of albums favorited by the currently logged user
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 * @deprecated Access this information through favorites field
	 */
	readonly estimatedFavoriteAlbumsCount: Scalars['Int']['output'];
	/**
	 * Estimated playlist created count by the currently logged user
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly estimatedPlaylistCount: Scalars['Int']['output'];
	/**
	 * Family options and members
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly family: Maybe<Family>;
	/**
	 * User's favorites aggregation
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 * @deprecated Use userFavorites instead
	 */
	readonly favorites: Favorites;
	/**
	 * User's flow
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly flow: Maybe<Flow>;
	/**
	 * Flow configs for the logged users
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly flowConfigs: PrivateUserFlowConfigs;
	/**
	 * Flow mood list to be displayed in a defined context
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 * @deprecated Use flowConfigs.moods instead
	 */
	readonly flowMoods: ReadonlyArray<Maybe<FlowMood>>;
	/**
	 * Current value of the tuner used to generate tracks for flow.
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly flowTuner: FlowTuner;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly flowWheels: FlowWheels;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly games: PrivateUserGames;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly id: Scalars['ID']['output'];
	/**
	 * WARNING!!
	 * It returns fake data and should only be used for development at the moment.
	 *
	 * Last server known queue state
	 * Could be null when last state was too old or never pushed
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly lastKnownQueueState: Maybe<QueueState>;
	/**
	 * Opaque string to pass to live and relive for authentication
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 * @deprecated Use Tokens.liveToken instead.
	 */
	readonly liveToken: Maybe<LiveTokenPackage>;
	/**
	 * Generated music contents based on user's recommendations
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly madeForMe: PrivateUserMadeForMeConnection;
	/**
	 * Opaque string to pass to the media service allongside tracktoken to play music.
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 * @deprecated Use Tokens.mediaServiceLicenseToken instead.
	 */
	readonly mediaServiceLicenseToken: Maybe<MediaServiceLicenseTokenPackage>;
	/**
	 * Number of music together groups for the currently logged user
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly musicTogetherGroupCount: Scalars['Int']['output'];
	/**
	 * Paginated list of user joined groups
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly musicTogetherGroups: PrivateUserMusicTogetherGroupsConnection;
	/**
	 * User's content on deck
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly onDeck: OnDeck;
	/**
	 * User's onboarding
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly onboarding: Onboarding;
	/**
	 * Offers user his own data for a dedicated campaign (eg: playground)
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly personalStats: Maybe<PersonalStats>;
	/**
	 * Paginated list of all the playlists owned by the currently logged user
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly playlists: PrivateUserPlaylistsConnection;
	/**
	 * User's podcast episode bookmarks
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly podcastEpisodeBookmarks: PrivateUserPodcastEpisodeBookmarksConnection;
	/**
	 * User's audiobooks on deck
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 * @deprecated Moved to onDeck.
	 */
	readonly rawAudiobooksOnDeck: Maybe<ReadonlyArray<AudiobookState>>;
	/**
	 * Raw user's podcast episode bookmarks
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly rawPodcastEpisodeBookmarks: ReadonlyArray<
		Maybe<RawPodcastEpisodeBookmark>
	>;
	/**
	 * Opaque string to pass to REC for authentication
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 * @deprecated Use Tokens.recToken instead.
	 */
	readonly recToken: Maybe<RecTokenPackage>;
	/**
	 * Paginated list of content recently played by the currently logged user
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly recentlyPlayed: PrivateUserRecentlyPlayedConnection;
	/**
	 * User's recommendations
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly recommendations: PrivateUserRecommendations;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly remoteControl: RemoteControl;
	/**
	 * User's search history
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly searchHistory: SearchHistory;
	/**
	 * Simple story
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly simpleStory: Maybe<SimpleStory>;
	/**
	 * User's SongCatcher history
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly songCatcherHistory: Maybe<SongCatcherHistory>;
	/**
	 * Access: This field is restricted to applications granted with specific access.
	 * @deprecated No more available
	 */
	readonly ucps: Maybe<Ucps>;
	/**
	 * The public view of the logged user
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly user: User;
	/**
	 * User's favorites aggregation
	 *
	 * Access: This field requires one of these scopes: [favorite-all-read,favorite-playlist-read,favorite-artist-read,favorites-album-read,favorites-track-read,favorite-podcast-read].
	 */
	readonly userFavorites: PrivateUserFavorites;
}

export interface PrivateUserFlowMoodsArgs {
	context: FlowMoodContextInput;
}

export interface PrivateUserMadeForMeArgs {
	after: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
}

export interface PrivateUserMusicTogetherGroupsArgs {
	after: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
}

export interface PrivateUserOnboardingArgs {
	context?: OnboardingContextInput;
}

export interface PrivateUserPersonalStatsArgs {
	campaignTitle?: Scalars['String']['input'];
}

export interface PrivateUserPlaylistsArgs {
	after: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
	sort: InputMaybe<PrivateUserPlaylistSortInput>;
}

export interface PrivateUserPodcastEpisodeBookmarksArgs {
	after: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
}

export interface PrivateUserRawAudiobooksOnDeckArgs {
	kidMode?: Scalars['Boolean']['input'];
}

export interface PrivateUserRecentlyPlayedArgs {
	after: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
}

export interface PrivateUserSimpleStoryArgs {
	storyId?: Scalars['String']['input'];
}

export interface PrivateUserBlindTestsConnection {
	readonly __typename: 'PrivateUserBlindTestsConnection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly edges: ReadonlyArray<PrivateUserBlindTestsConnectionEdge>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly pageInfo: PageInfo;
}

export interface PrivateUserBlindTestsConnectionEdge {
	readonly __typename: 'PrivateUserBlindTestsConnectionEdge';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly cursor: Scalars['String']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly node: Maybe<BlindTest>;
}

export interface PrivateUserFavoriteAlbumConnection {
	readonly __typename: 'PrivateUserFavoriteAlbumConnection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly edges: ReadonlyArray<PrivateUserFavoriteAlbumEdge>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly pageInfo: PageInfo;
}

export interface PrivateUserFavoriteAlbumEdge {
	readonly __typename: 'PrivateUserFavoriteAlbumEdge';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly cursor: Scalars['String']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly favoritedAt: Scalars['Date']['output'];
	/**
	 * Can return error codes:
	 * * ERR_ALBUM_NOT_FOUND: Is returned when album doesn't exist
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly node: Maybe<Album>;
}

export interface PrivateUserFavoriteArtistConnection {
	readonly __typename: 'PrivateUserFavoriteArtistConnection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly edges: ReadonlyArray<PrivateUserFavoriteArtistEdge>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly pageInfo: PageInfo;
}

export interface PrivateUserFavoriteArtistEdge {
	readonly __typename: 'PrivateUserFavoriteArtistEdge';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly cursor: Scalars['String']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly favoritedAt: Scalars['Date']['output'];
	/**
	 * Can return error codes:
	 * * ERR_ARTIST_NOT_FOUND: Is returned when artist doesn't exist
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly node: Maybe<Artist>;
}

export interface PrivateUserFavoriteCollaborativePlaylistConnection {
	readonly __typename: 'PrivateUserFavoriteCollaborativePlaylistConnection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly edges: ReadonlyArray<PrivateUserFavoriteCollaborativePlaylistEdge>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly pageInfo: PageInfo;
}

export interface PrivateUserFavoriteCollaborativePlaylistEdge {
	readonly __typename: 'PrivateUserFavoriteCollaborativePlaylistEdge';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly cursor: Scalars['String']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly favoritedAt: Scalars['Date']['output'];
	/**
	 * Can return error codes:
	 * * ERR_PLAYLIST_NOT_FOUND: Is returned when playlist doesn't exist
	 * * ERR_PLAYLIST_ACCESS_NOT_AUTHORIZED: Is returned when playlist access is not allowed
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly node: Maybe<Playlist>;
}

export interface PrivateUserFavoritePlaylistConnection {
	readonly __typename: 'PrivateUserFavoritePlaylistConnection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly edges: ReadonlyArray<PrivateUserFavoritePlaylistEdge>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly pageInfo: PageInfo;
}

export interface PrivateUserFavoritePlaylistEdge {
	readonly __typename: 'PrivateUserFavoritePlaylistEdge';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly cursor: Scalars['String']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly favoritedAt: Scalars['Date']['output'];
	/**
	 * Can return error codes:
	 * * ERR_PLAYLIST_NOT_FOUND: Is returned when playlist doesn't exist
	 * * ERR_PLAYLIST_ACCESS_NOT_AUTHORIZED: Is returned when playlist access is not allowed
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly node: Maybe<Playlist>;
}

export interface PrivateUserFavoritePodcastConnection {
	readonly __typename: 'PrivateUserFavoritePodcastConnection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly edges: ReadonlyArray<PrivateUserFavoritePodcastEdge>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly pageInfo: PageInfo;
}

export interface PrivateUserFavoritePodcastEdge {
	readonly __typename: 'PrivateUserFavoritePodcastEdge';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly cursor: Scalars['String']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly favoritedAt: Scalars['Date']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly node: Maybe<Podcast>;
}

export interface PrivateUserFavoriteTrackConnection {
	readonly __typename: 'PrivateUserFavoriteTrackConnection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly edges: ReadonlyArray<PrivateUserFavoriteTrackEdge>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly pageInfo: PageInfo;
}

export interface PrivateUserFavoriteTrackEdge {
	readonly __typename: 'PrivateUserFavoriteTrackEdge';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly cursor: Scalars['String']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly favoritedAt: Scalars['Date']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly node: Maybe<Track>;
}

export interface PrivateUserFavorites {
	readonly __typename: 'PrivateUserFavorites';
	/**
	 * Paginated list of all albums favorited by the user
	 *
	 * Access: This field requires one of these scopes: [favorite-all-read,favorite-album-read].
	 */
	readonly albums: Maybe<PrivateUserFavoriteAlbumConnection>;
	/**
	 * Paginated list of all artists favorited by the user
	 *
	 * Access: This field requires one of these scopes: [favorite-all-read,favorite-artist-read].
	 */
	readonly artists: Maybe<PrivateUserFavoriteArtistConnection>;
	/**
	 * Favorites aggregated by artist
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly byArtist: Maybe<PrivateUserFavoritesByArtist>;
	/**
	 * Paginated list of all collaborative playlists favorited by the user
	 *
	 * Access: This field requires one of these scopes: [favorite-all-read,favorite-playlist-read].
	 */
	readonly collaborativePlaylists: Maybe<PrivateUserFavoriteCollaborativePlaylistConnection>;
	/**
	 * Estimated number of albums favorited by the user; use for display only
	 *
	 * Access: This field requires one of these scopes: [favorite-all-read,favorite-album-read].
	 */
	readonly estimatedAlbumsCount: Maybe<Scalars['Int']['output']>;
	/**
	 * Estimated number of artists favorited by the user; use for display only
	 *
	 * Access: This field requires one of these scopes: [favorite-all-read,favorite-artist-read].
	 */
	readonly estimatedArtistsCount: Maybe<Scalars['Int']['output']>;
	/**
	 * Estimated number of playlists favorited by the user; use for display only
	 *
	 * Access: This field requires one of these scopes: [favorite-all-read,favorite-playlist-read].
	 */
	readonly estimatedPlaylistsCount: Maybe<Scalars['Int']['output']>;
	/**
	 * Estimated number of podcasts favorited by the user; use for display only
	 *
	 * Access: This field requires one of these scopes: [favorite-all-read,favorite-podcast-read].
	 */
	readonly estimatedPodcastsCount: Maybe<Scalars['Int']['output']>;
	/**
	 * Estimated number of tracks favorited by the user; use for display only
	 *
	 * Access: This field requires one of these scopes: [favorite-all-read,favorite-track-read].
	 */
	readonly estimatedTracksCount: Maybe<Scalars['Int']['output']>;
	/**
	 * Paginated list of all playlists favorited by the user
	 *
	 * Access: This field requires one of these scopes: [favorite-all-read,favorite-playlist-read].
	 */
	readonly playlists: Maybe<PrivateUserFavoritePlaylistConnection>;
	/**
	 * Paginated list of all podcasts favorited by the user
	 *
	 * Access: This field requires one of these scopes: [favorite-all-read,favorite-podcast-read].
	 */
	readonly podcasts: Maybe<PrivateUserFavoritePodcastConnection>;
	/**
	 * The full list of the user's favorite album ids.
	 * You may receive one album, or thousands, hence you must fetch this field only when absolutely needed, and only once per query.
	 * We defined the cost of the field accordingly.
	 *
	 * Access: This field requires one of these scopes: [favorite-all-read,favorite-album-read].
	 */
	readonly rawAlbums: Maybe<ReadonlyArray<PrivateUserRawAlbumFavorite>>;
	/**
	 * The full list of the user's favorite artist ids.
	 * You may receive one artist, or thousands, hence you must fetch this field only when absolutely needed, and only once per query.
	 * We defined the cost of the field accordingly.
	 *
	 * Access: This field requires one of these scopes: [favorite-all-read,favorite-album-read].
	 */
	readonly rawArtists: Maybe<ReadonlyArray<PrivateUserRawArtistFavorite>>;
	/**
	 * The full list of the user's favorite playlists ids.
	 * You may receive one playlists, or thousands, hence you must fetch this field only when absolutely needed, and only once per query.
	 * We defined the cost of the field accordingly.
	 *
	 * Access: This field requires one of these scopes: [favorite-all-read,favorite-playlist-read].
	 */
	readonly rawPlaylists: Maybe<ReadonlyArray<PrivateUserRawPlaylistFavorite>>;
	/**
	 * The full list of the user's favorite podcast ids.
	 * You may receive one podcast, or thousands, hence you must fetch this field only when absolutely needed, and only once per query.
	 * We defined the cost of the field accordingly.
	 *
	 * Access: This field requires one of these scopes: [favorite-all-read,favorite-podcast-read].
	 */
	readonly rawPodcasts: Maybe<ReadonlyArray<PrivateUserRawPodcastFavorite>>;
	/**
	 * The full list of the user's favorite track ids.
	 * You may receive one track, or thousands, hence you must fetch this field only when absolutely needed, and only once per query.
	 * We defined the cost of the field accordingly.
	 *
	 * Access: This field requires one of these scopes: [favorite-all-read,favorite-track-read].
	 */
	readonly rawTracks: Maybe<ReadonlyArray<PrivateUserRawTrackFavorite>>;
	/**
	 * Paginated list of all tracks favorited by the user
	 *
	 * Access: This field requires one of these scopes: [favorite-all-read,favorite-track-read].
	 */
	readonly tracks: Maybe<PrivateUserFavoriteTrackConnection>;
}

export interface PrivateUserFavoritesAlbumsArgs {
	after: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
}

export interface PrivateUserFavoritesArtistsArgs {
	after: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
}

export interface PrivateUserFavoritesByArtistArgs {
	artistId: Scalars['String']['input'];
}

export interface PrivateUserFavoritesCollaborativePlaylistsArgs {
	after: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
}

export interface PrivateUserFavoritesPlaylistsArgs {
	after: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
}

export interface PrivateUserFavoritesPodcastsArgs {
	after: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
}

export interface PrivateUserFavoritesTracksArgs {
	after: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
}

export interface PrivateUserFavoritesByArtist {
	readonly __typename: 'PrivateUserFavoritesByArtist';
	/**
	 * Paginated list of all albums favorited by the user for a given artist
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly albums: Maybe<PrivateUserFavoriteAlbumConnection>;
	/**
	 * Estimated number of albums favorited by the user for a given artist; use for display only
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly estimatedAlbumsCount: Maybe<Scalars['Int']['output']>;
	/**
	 * Estimated number of tracks favorited by the user for a given artist; use for display only
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly estimatedTracksCount: Maybe<Scalars['Int']['output']>;
	/**
	 * The full list of tracks ids favorited by the user for a given artist
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly rawTracks: ReadonlyArray<RawFavorite>;
	/**
	 * Paginated list of all tracks favorited by the user for a given artist
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly tracks: Maybe<PrivateUserFavoriteTrackConnection>;
}

export interface PrivateUserFavoritesByArtistAlbumsArgs {
	after: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
}

export interface PrivateUserFavoritesByArtistTracksArgs {
	after: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
}

export interface PrivateUserFlowConfigs {
	readonly __typename: 'PrivateUserFlowConfigs';
	/**
	 * A list of flow focusing on music genres and based on user's tastes (eg. rock, pop...)
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly genres: PrivateUserFlowConfigsConnection;
	/**
	 * A list of flow focusing on moods (eg. chill, party...)
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly moods: PrivateUserFlowConfigsConnection;
}

export interface PrivateUserFlowConfigsGenresArgs {
	after: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
}

export interface PrivateUserFlowConfigsMoodsArgs {
	after: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
}

export interface PrivateUserFlowConfigsConnection {
	readonly __typename: 'PrivateUserFlowConfigsConnection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly edges: ReadonlyArray<PrivateUserFlowConfigsConnectionEdge>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly pageInfo: PageInfo;
}

export interface PrivateUserFlowConfigsConnectionEdge {
	readonly __typename: 'PrivateUserFlowConfigsConnectionEdge';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly cursor: Scalars['String']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly node: Maybe<FlowConfig>;
}

export interface PrivateUserGames {
	readonly __typename: 'PrivateUserGames';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly blindTest: PrivateUserGamesBlindTest;
}

export interface PrivateUserGamesBlindTest {
	readonly __typename: 'PrivateUserGamesBlindTest';
	/**
	 * The best score by blindtest id & type
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly bestScore: Maybe<Scalars['Int']['output']>;
	/**
	 * BlindTests played ordered by best scores
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly blindTestsPlayed: PrivateUserBlindTestsConnection;
	/**
	 * Has the user already played any blindtest
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly hasPlayed: Scalars['Boolean']['output'];
}

export interface PrivateUserGamesBlindTestBestScoreArgs {
	id: Scalars['String']['input'];
	type: BlindTestTypeInput;
}

export interface PrivateUserGamesBlindTestBlindTestsPlayedArgs {
	after: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
	type?: BlindTestTypeInput;
}

export interface PrivateUserMadeForMeConnection {
	readonly __typename: 'PrivateUserMadeForMeConnection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly edges: ReadonlyArray<PrivateUserMadeForMeConnectionEdge>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly pageInfo: PageInfo;
}

export interface PrivateUserMadeForMeConnectionEdge {
	readonly __typename: 'PrivateUserMadeForMeConnectionEdge';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly cursor: Scalars['String']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly node: Maybe<MadeForMe>;
}

export interface PrivateUserMusicTogetherGroupsConnection {
	readonly __typename: 'PrivateUserMusicTogetherGroupsConnection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly edges: ReadonlyArray<PrivateUserMusicTogetherGroupsConnectionEdge>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly pageInfo: PageInfo;
}

export interface PrivateUserMusicTogetherGroupsConnectionEdge {
	readonly __typename: 'PrivateUserMusicTogetherGroupsConnectionEdge';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly cursor: Scalars['String']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly node: Maybe<MusicTogetherGroup>;
}

export enum PrivateUserPlaylistSortByInput {
	LAST_MODIFICATION_DATE = 'LAST_MODIFICATION_DATE',
}

export interface PrivateUserPlaylistSortInput {
	readonly by: InputMaybe<PrivateUserPlaylistSortByInput>;
	readonly order: InputMaybe<SortOrderInput>;
}

export interface PrivateUserPlaylistsConnection {
	readonly __typename: 'PrivateUserPlaylistsConnection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly edges: ReadonlyArray<PrivateUserPlaylistsConnectionEdge>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly pageInfo: PageInfo;
}

export interface PrivateUserPlaylistsConnectionEdge {
	readonly __typename: 'PrivateUserPlaylistsConnectionEdge';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly cursor: Scalars['String']['output'];
	/**
	 * Can return error codes:
	 * * ERR_PLAYLIST_NOT_FOUND: Is returned when playlist doesn't exist
	 * * ERR_PLAYLIST_ACCESS_NOT_AUTHORIZED: Is returned when playlist access is not allowed
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly node: Maybe<Playlist>;
}

export interface PrivateUserPodcastEpisodeBookmarksConnection {
	readonly __typename: 'PrivateUserPodcastEpisodeBookmarksConnection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly edges: ReadonlyArray<PrivateUserPodcastEpisodeBookmarksConnectionEdge>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly pageInfo: PageInfo;
}

export interface PrivateUserPodcastEpisodeBookmarksConnectionEdge {
	readonly __typename: 'PrivateUserPodcastEpisodeBookmarksConnectionEdge';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly cursor: Scalars['String']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly node: Maybe<PodcastEpisodeBookmark>;
}

export interface PrivateUserRawAlbumFavorite extends PrivateUserRawFavorite {
	readonly __typename: 'PrivateUserRawAlbumFavorite';
	/** Access: This field requires one of these scopes: [favorite-all-read,favorites-album-read]. */
	readonly favoritedAt: Scalars['Date']['output'];
	/** Access: This field requires one of these scopes: [favorite-all-read,favorites-album-read]. */
	readonly id: Scalars['ID']['output'];
}

export interface PrivateUserRawArtistFavorite extends PrivateUserRawFavorite {
	readonly __typename: 'PrivateUserRawArtistFavorite';
	/** Access: This field requires one of these scopes: [favorite-all-read,favorites-artist-read]. */
	readonly favoritedAt: Scalars['Date']['output'];
	/** Access: This field requires one of these scopes: [favorite-all-read,favorites-artist-read]. */
	readonly id: Scalars['ID']['output'];
}

export interface PrivateUserRawFavorite {
	readonly favoritedAt: Scalars['Date']['output'];
	readonly id: Scalars['ID']['output'];
}

export interface PrivateUserRawPlaylistFavorite extends PrivateUserRawFavorite {
	readonly __typename: 'PrivateUserRawPlaylistFavorite';
	/** Access: This field requires one of these scopes: [favorite-all-read,favorites-playlist-read]. */
	readonly favoritedAt: Scalars['Date']['output'];
	/** Access: This field requires one of these scopes: [favorite-all-read,favorites-playlist-read]. */
	readonly id: Scalars['ID']['output'];
}

export interface PrivateUserRawPodcastFavorite extends PrivateUserRawFavorite {
	readonly __typename: 'PrivateUserRawPodcastFavorite';
	/** Access: This field requires one of these scopes: [favorite-all-read,favorites-podcast-read]. */
	readonly favoritedAt: Scalars['Date']['output'];
	/** Access: This field requires one of these scopes: [favorite-all-read,favorites-podcast-read]. */
	readonly id: Scalars['ID']['output'];
}

export interface PrivateUserRawTrackFavorite extends PrivateUserRawFavorite {
	readonly __typename: 'PrivateUserRawTrackFavorite';
	/** Access: This field requires one of these scopes: [favorite-all-read,favorites-track-read]. */
	readonly favoritedAt: Scalars['Date']['output'];
	/** Access: This field requires one of these scopes: [favorite-all-read,favorites-track-read]. */
	readonly id: Scalars['ID']['output'];
}

export interface PrivateUserRecentlyPlayedConnection {
	readonly __typename: 'PrivateUserRecentlyPlayedConnection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly edges: ReadonlyArray<PrivateUserRecentlyPlayedConnectionEdge>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly pageInfo: PageInfo;
}

export interface PrivateUserRecentlyPlayedConnectionEdge {
	readonly __typename: 'PrivateUserRecentlyPlayedConnectionEdge';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly cursor: Scalars['String']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly node: Maybe<RecentlyPlayedContent>;
}

export interface PrivateUserRecommendations {
	readonly __typename: 'PrivateUserRecommendations';
	/**
	 * Paginated list of the artist playlists (100% playlists) recommended for the currently logged user
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly artistPlaylists: PrivateUserRecommendedArtistPlaylistsConnection;
	/**
	 * Paginated list of the artists recommended for the currently logged user
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly artists: PrivateUserRecommendedArtistsConnection;
	/**
	 * WARNING!!
	 * It returns fake data and should only be used for development at the moment.
	 *
	 * Paginated list of the targeted user's favorite tracks recommended for the currently logged user
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly favoriteTracksFromUser: Maybe<PrivateUserRecommendedFavoriteTracksFromUserConnection>;
	/**
	 * Paginated list of the newly released albums recommended for the currently logged user
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly newReleases: PrivateUserRecommendedNewReleasesConnection;
	/**
	 * Paginated list of the playlists recommended for the currently logged user
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly playlists: PrivateUserRecommendedPlaylistsConnection;
}

export interface PrivateUserRecommendationsArtistPlaylistsArgs {
	after: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
}

export interface PrivateUserRecommendationsArtistsArgs {
	after: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
}

export interface PrivateUserRecommendationsFavoriteTracksFromUserArgs {
	after: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
	userId: Scalars['ID']['input'];
}

export interface PrivateUserRecommendationsNewReleasesArgs {
	after: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
}

export interface PrivateUserRecommendationsPlaylistsArgs {
	after: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
}

export interface PrivateUserRecommendedArtistPlaylistsConnection {
	readonly __typename: 'PrivateUserRecommendedArtistPlaylistsConnection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly edges: ReadonlyArray<PrivateUserRecommendedArtistPlaylistsConnectionEdge>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly pageInfo: PageInfo;
}

export interface PrivateUserRecommendedArtistPlaylistsConnectionEdge {
	readonly __typename: 'PrivateUserRecommendedArtistPlaylistsConnectionEdge';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly cursor: Scalars['String']['output'];
	/**
	 * Can return error codes:
	 * * ERR_PLAYLIST_NOT_FOUND: Is returned when playlist doesn't exist
	 * * ERR_PLAYLIST_ACCESS_NOT_AUTHORIZED: Is returned when playlist access is not allowed
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly node: Maybe<Playlist>;
}

export interface PrivateUserRecommendedArtistsConnection {
	readonly __typename: 'PrivateUserRecommendedArtistsConnection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly edges: ReadonlyArray<PrivateUserRecommendedArtistsConnectionEdge>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly pageInfo: PageInfo;
}

export interface PrivateUserRecommendedArtistsConnectionEdge {
	readonly __typename: 'PrivateUserRecommendedArtistsConnectionEdge';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly cursor: Scalars['String']['output'];
	/**
	 * Can return error codes:
	 * * ERR_ARTIST_NOT_FOUND: Is returned when artist doesn't exist
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly node: Maybe<Artist>;
}

export interface PrivateUserRecommendedFavoriteTracksFromUserConnection {
	readonly __typename: 'PrivateUserRecommendedFavoriteTracksFromUserConnection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly edges: ReadonlyArray<PrivateUserRecommendedFavoriteTracksFromUserConnectionEdge>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly pageInfo: PageInfo;
}

export interface PrivateUserRecommendedFavoriteTracksFromUserConnectionEdge {
	readonly __typename: 'PrivateUserRecommendedFavoriteTracksFromUserConnectionEdge';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly cursor: Scalars['String']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly node: Maybe<Track>;
}

export interface PrivateUserRecommendedNewReleasesConnection {
	readonly __typename: 'PrivateUserRecommendedNewReleasesConnection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly edges: ReadonlyArray<PrivateUserRecommendedNewReleasesConnectionEdge>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly pageInfo: PageInfo;
}

export interface PrivateUserRecommendedNewReleasesConnectionEdge {
	readonly __typename: 'PrivateUserRecommendedNewReleasesConnectionEdge';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly cursor: Scalars['String']['output'];
	/**
	 * Can return error codes:
	 * * ERR_ALBUM_NOT_FOUND: Is returned when album doesn't exist
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly node: Maybe<Album>;
}

export interface PrivateUserRecommendedPlaylistsConnection {
	readonly __typename: 'PrivateUserRecommendedPlaylistsConnection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly edges: ReadonlyArray<PrivateUserRecommendedPlaylistsConnectionEdge>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly pageInfo: PageInfo;
}

export interface PrivateUserRecommendedPlaylistsConnectionEdge {
	readonly __typename: 'PrivateUserRecommendedPlaylistsConnectionEdge';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly cursor: Scalars['String']['output'];
	/**
	 * Can return error codes:
	 * * ERR_PLAYLIST_NOT_FOUND: Is returned when playlist doesn't exist
	 * * ERR_PLAYLIST_ACCESS_NOT_AUTHORIZED: Is returned when playlist access is not allowed
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly node: Maybe<Playlist>;
}

export type PromotableContent =
	| Album
	| Artist
	| BlindTest
	| Channel
	| Livestream
	| Mix
	| Playlist
	| Podcast
	| PodcastEpisode
	| SmartTracklist
	| Track;

export enum PromotableContentSupportInput {
	ALBUM = 'ALBUM',
	ARTIST = 'ARTIST',
	BLINDTEST = 'BLINDTEST',
	CHANNEL = 'CHANNEL',
	LIVESTREAM = 'LIVESTREAM',
	MIX = 'MIX',
	PLAYLIST = 'PLAYLIST',
	PODCAST = 'PODCAST',
	PODCAST_EPISODE = 'PODCAST_EPISODE',
	SMART_TRACKLIST = 'SMART_TRACKLIST',
	TRACK = 'TRACK',
}

export interface PromotedContent extends Node {
	readonly __typename: 'PromotedContent';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly backgroundColor: Scalars['String']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly content: Maybe<PromotableContent>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly id: Scalars['ID']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly picture: Maybe<Picture>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly subtitle: Maybe<Scalars['String']['output']>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly subtitleColor: Maybe<Scalars['String']['output']>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly theme: PromotedContentTheme;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly title: Maybe<Scalars['String']['output']>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly titleColor: Maybe<Scalars['String']['output']>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly url: DeezerUrl;
}

export enum PromotedContentTheme {
	DARK = 'DARK',
	LIGHT = 'LIGHT',
}

export interface PromotedContentsConnection {
	readonly __typename: 'PromotedContentsConnection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly edges: ReadonlyArray<PromotedContentsConnectionEdge>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly pageInfo: PageInfo;
}

export interface PromotedContentsConnectionEdge {
	readonly __typename: 'PromotedContentsConnectionEdge';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly cursor: Scalars['String']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly node: Maybe<PromotedContent>;
}

export interface PromotionalVideo extends Node {
	readonly __typename: 'PromotionalVideo';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly description: Maybe<Scalars['String']['output']>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly id: Scalars['ID']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly picture: Maybe<Picture>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly rawUrl: Scalars['String']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly subtitle: Maybe<Scalars['String']['output']>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly theme: PromotionalVideoTheme;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly title: Maybe<Scalars['String']['output']>;
}

export enum PromotionalVideoTheme {
	DARK = 'DARK',
	LIGHT = 'LIGHT',
}

export interface PromotionalVideosConnection {
	readonly __typename: 'PromotionalVideosConnection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly edges: ReadonlyArray<PromotionalVideosConnectionEdge>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly pageInfo: PageInfo;
}

export interface PromotionalVideosConnectionEdge {
	readonly __typename: 'PromotionalVideosConnectionEdge';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly cursor: Scalars['String']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly node: Maybe<PromotionalVideo>;
}

/**
 * Describes a pubsub connection. It can be used to specify the
 * way a client wants to be called back from the server.
 */
export interface PubsubSpec {
	/**
	 * The channel or topic the client will be listening on the pubsubs instance
	 *
	 * For elive this is the name of the topic, not the full channel descriptor.
	 * (i.e. not ${USER_ID}_${USER_ID}_${CHANNEL} but only ${CHANNEL}.
	 */
	readonly channel: Scalars['String']['input'];
	/**
	 * The peer to contact on the pubsub channel bus.
	 *
	 * This will be used as the `destination` field of messages sent on the
	 * bus in response to this discovery probe (https://deezer.jira.com/wiki/spaces/TEC/pages/2495251792/Proto1+Message+Format+-+Deezer+Remote+Control+Proto1#Directed-Messages)
	 * It should be the identifier the device making the request used to
	 * identify itself on the pubsub (generally the device id).
	 */
	readonly peer: Scalars['String']['input'];
	/** The type of the bus. The only accepted value for now is 'elive' */
	readonly type: Scalars['String']['input'];
	/** The URL of the pubsub bus (e.g. 'wss://live.deezer.com/ws') */
	readonly url: Scalars['String']['input'];
}

export interface PushQueueMediaContextInput {
	/** The context content related to the media */
	readonly content: Scalars['String']['input'];
	/** Unique identifier of the media within the queue as queue can contain multiple time the same media */
	readonly id: Scalars['ID']['input'];
}

/** Details of media that belongs to the pushed queue */
export interface PushQueueMediaInput {
	/** The context related to the media */
	readonly context: PushQueueMediaContextInput;
	/** The media id */
	readonly id: Scalars['ID']['input'];
	/** The media type */
	readonly type: MediaTypeInput;
	/** The position within the unshuffled version of the queue */
	readonly unshuffledPosition: Scalars['Int']['input'];
}

/** Details about the client that wants to push the queue state */
export interface PushQueueStateClientDetailsInput {
	/** Identifier of the client's local queue, doesn't necessarily match a real queue id */
	readonly localQueueID: Scalars['String']['input'];
	/** Date in ISO-8601 format including milliseconds when update was made on client */
	readonly locallyUpdatedAt: Scalars['Date']['input'];
}

export interface PushQueueStateError {
	readonly __typename: 'PushQueueStateError';
	/**
	 * Set when supplied patch conflicts with an already present queue state, otherwise will be null
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly conflict: Maybe<PushQueueStateErrorConflict>;
}

export interface PushQueueStateErrorConflict {
	readonly __typename: 'PushQueueStateErrorConflict';
	/**
	 * Current Version of state which prevents queue state from being pushed
	 * Could be null if queue state is in an inconsistent state
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly current: Maybe<QueueState>;
}

export interface PushQueueStateInput {
	/** Details about the client pushing the queue state */
	readonly clientDetails: PushQueueStateClientDetailsInput;
	/** List of all media in the queue in playing order */
	readonly mediaList: ReadonlyArray<PushQueueMediaInput>;
	/** Zero based index of the current playback position in the queue */
	readonly position: Scalars['Int']['input'];
	/**
	 * Previous known queue state version
	 * Note: Except when there is no queue state available, this version MUST be provided otherwise you'll get a conflict error
	 */
	readonly previousVersion: InputMaybe<Scalars['String']['input']>;
	/** Queue's configuration */
	readonly queueConfiguration: Scalars['String']['input'];
	/** Queue repeat mode */
	readonly repeatMode: RepeatModeInput;
}

export interface PushQueueStateOutput {
	readonly __typename: 'PushQueueStateOutput';
	/**
	 * Server-side update date in ISO-8601 format
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly updatedAt: Scalars['Date']['output'];
	/**
	 * Version of the new created State
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly version: Scalars['String']['output'];
}

export type PushQueueStateResult = PushQueueStateError | PushQueueStateOutput;

export interface Query {
	readonly __typename: 'Query';
	/**
	 * Get a unique Album node by ID
	 *
	 * Can return error codes:
	 * * ERR_ALBUM_NOT_FOUND: Is returned when album doesn't exist
	 */
	readonly album: Maybe<Album>;
	/**
	 * Get a list of Album nodes by ID, with pagination
	 * @deprecated Use albumsByIds instead
	 */
	readonly albums: AlbumsConnection;
	/** Get a list of Album nodes by ID */
	readonly albumsByIds: ReadonlyArray<Maybe<Album>>;
	/**
	 * Get a unique Artist node by ID
	 *
	 * Can return error codes:
	 * * ERR_ARTIST_NOT_FOUND: Is returned when artist doesn't exist
	 */
	readonly artist: Maybe<Artist>;
	/**
	 * Get a unique Audiobook node by ID
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly audiobook: Maybe<Audiobook>;
	/**
	 * Get a unique Audiobook Chapter node by ID
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly audiobookChapter: Maybe<AudiobookChapter>;
	/**
	 * Get a unique BlindTest node by ID
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly blindTest: Maybe<BlindTest>;
	/**
	 * Get State of a started session
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly blindTestSessionState: Maybe<BlindTestSessionState>;
	/**
	 * Get a collection of available BlindTests nodes for a given type, with pagination
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly blindTestsByType: BlindTestConnection;
	/** Get a unique Channel node by ID */
	readonly channel: Maybe<Channel>;
	/**
	 * Get a list of Channel nodes by ID, with pagination
	 * @deprecated Use channelsByIds instead
	 */
	readonly channels: ChannelsConnection;
	/** Get a list of Channel nodes by ID */
	readonly channelsByIds: ReadonlyArray<Maybe<Channel>>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly charts: Maybe<Charts>;
	/**
	 * Fetches the consent information
	 *
	 * Some information will be empty for unlogged users
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly consent: Maybe<ConsentData>;
	/**
	 * Get a dynamic page from a uri
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly dynamicPage: Maybe<DynamicPage>;
	/**
	 * Specific dynamic page section
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly dynamicPageSection: Section;
	/**
	 * Get a unique FlowConfig node by ID
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly flowConfig: Maybe<FlowConfig>;
	/**
	 * Will return the state of all gatekeeps given in the list
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly gatekeeps: Gatekeeper;
	/**
	 * !! For internal use only !!
	 *
	 * Do not call. Format and behaviour may change without notice.
	 */
	readonly health: Maybe<Health>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly instantSearch: Maybe<InstantSearch>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly liveEvent: Maybe<LiveEvent>;
	/**
	 * Get a collection of live events nodes for given filters, with pagination
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly liveEventsFiltered: LiveEventsConnection;
	/**
	 * Get a collection of live events nodes based on recommendations, with pagination
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly liveEventsRecommended: LiveEventsConnection;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly liveVideoStream: Maybe<LiveVideoStream>;
	/**
	 * Get a unique Livestream node by ID
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly livestream: Maybe<Livestream>;
	/**
	 * Get a unique Lyrics node by ID
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly lyrics: Maybe<Lyrics>;
	/**
	 * Fetches the information of the currently logged-in user
	 *
	 * This node is only reachable in a logged-in context. In case of unlogged context, querying "me" will result in an error.
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly me: Maybe<PrivateUser>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly musicTogetherAffinity: Maybe<MusicTogetherAffinity>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly musicTogetherGroup: Maybe<MusicTogetherGroup>;
	readonly node: Maybe<Node>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly onboardingSteps: Maybe<OnboardingSteps>;
	/**
	 * Get permissions of JWT
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly permissions: Maybe<Permissions>;
	/**
	 * Get a unique Playlist node by ID
	 *
	 * Can return error codes:
	 * * ERR_PLAYLIST_NOT_FOUND: Is returned when playlist doesn't exist
	 * * ERR_PLAYLIST_ACCESS_NOT_AUTHORIZED: Is returned when playlist access is not allowed
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly playlist: Maybe<Playlist>;
	/**
	 * Get the current smarttracklist's playlist id
	 *
	 * Can return error codes:
	 * * ERR_PLAYLIST_NOT_FOUND: Is returned when playlist doesn't exist
	 * * ERR_PLAYLIST_ACCESS_NOT_AUTHORIZED: Is returned when playlist access is not allowed
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly playlistFromSmartTracklist: Maybe<Playlist>;
	/**
	 * Get a list of Playlist nodes by ID, with pagination
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 * @deprecated Use playlistsByIds instead
	 */
	readonly playlists: PlaylistsConnection;
	/**
	 * Get a list of Playlist nodes by ID
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly playlistsByIds: ReadonlyArray<Maybe<Playlist>>;
	/**
	 * Get a unique Podcast node by ID
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly podcast: Maybe<Podcast>;
	/**
	 * Get a unique Podcast Episode node by ID
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly podcastEpisode: Maybe<PodcastEpisode>;
	/**
	 * Get a list of Podcast Episode nodes by ID, with pagination
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 * @deprecated Use podcastEpisodesByIds instead
	 */
	readonly podcastEpisodes: PodcastEpisodesConnection;
	/**
	 * Get a list of Podcast Episode nodes by ID
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly podcastEpisodesByIds: ReadonlyArray<Maybe<PodcastEpisode>>;
	/**
	 * Get a promoted content given it's id
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly promotedContent: Maybe<PromotedContent>;
	/**
	 * Get a list of PromotedContent nodes by ID, with pagination
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly promotedContents: PromotedContentsConnection;
	/**
	 * Get a promotional video given it's id
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly promotionalVideo: Maybe<PromotionalVideo>;
	/**
	 * Get a list of PromotionalVideo nodes by ID, with pagination
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 * @deprecated Use promotionalVideosByIds instead
	 */
	readonly promotionalVideos: PromotionalVideosConnection;
	/**
	 * Get a list of PromotionalVideo nodes by ID
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly promotionalVideosByIds: ReadonlyArray<Maybe<PromotionalVideo>>;
	/**
	 * Get the first chunk of a queue
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly queueChunkByQueueId: Maybe<QueueChunk>;
	/**
	 * Get a list of queue chunks given their ids and the identifier of the queue they belong to
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly queueChunksByIds: ReadonlyArray<Maybe<QueueChunk>>;
	readonly search: Maybe<Search>;
	/**
	 * The trending search queries
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly searchTrendingQueries: SearchTrendingQueriesConnection;
	/**
	 * Get a unique SmartTracklist node by ID
	 *
	 * Can return error codes:
	 * * ERR_SMART_TRACKLIST_NOT_FOUND: Is returned when smart tracklist doesn't exist
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly smartTracklist: Maybe<SmartTracklist>;
	/**
	 * This node contain the tokens available in a logged AND unlogged context.
	 * WARNING: The tokens requested as unlogged must absolutely be refreshed after login.
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly tokens: Tokens;
	/** Get a unique Track node by ID */
	readonly track: Maybe<Track>;
	/**
	 * Get a list of Track nodes by ID, with pagination
	 * @deprecated Use tracksByIds instead
	 */
	readonly tracks: TracksConnection;
	/** Get a list of Track nodes by ID */
	readonly tracksByIds: ReadonlyArray<Maybe<Track>>;
	/**
	 * Access: This field is restricted to applications granted with specific access.
	 * @deprecated No more available
	 */
	readonly ucpsData: Maybe<UcpsData>;
	/**
	 * Get a unique User node by ID
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly user: Maybe<User>;
	/**
	 * Get a list of User nodes by ID, with pagination
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 * @deprecated Use usersByIds instead
	 */
	readonly users: UsersConnection;
	/**
	 * Get a list of User nodes by ID
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly usersByIds: ReadonlyArray<Maybe<User>>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly videoStream: Maybe<VideoStream>;
}

export interface QueryAlbumArgs {
	albumId: Scalars['String']['input'];
}

export interface QueryAlbumsArgs {
	after: InputMaybe<Scalars['String']['input']>;
	albumIds: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
	first?: Scalars['Int']['input'];
}

export interface QueryAlbumsByIdsArgs {
	ids: ReadonlyArray<Scalars['String']['input']>;
}

export interface QueryArtistArgs {
	artistId: InputMaybe<Scalars['String']['input']>;
}

export interface QueryAudiobookArgs {
	audiobookId: Scalars['String']['input'];
}

export interface QueryAudiobookChapterArgs {
	audiobookChapterId: Scalars['String']['input'];
}

export interface QueryBlindTestArgs {
	id: Scalars['String']['input'];
	type: BlindTestTypeInput;
}

export interface QueryBlindTestSessionStateArgs {
	token: Scalars['String']['input'];
}

export interface QueryBlindTestsByTypeArgs {
	after: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
	type: BlindTestTypeInput;
}

export interface QueryChannelArgs {
	channelId: InputMaybe<Scalars['String']['input']>;
}

export interface QueryChannelsArgs {
	after: InputMaybe<Scalars['String']['input']>;
	channelIds: ReadonlyArray<Scalars['String']['input']>;
	first?: Scalars['Int']['input'];
}

export interface QueryChannelsByIdsArgs {
	ids: ReadonlyArray<Scalars['String']['input']>;
}

export interface QueryDynamicPageArgs {
	uri: Scalars['String']['input'];
}

export interface QueryDynamicPageSectionArgs {
	sectionId: Scalars['String']['input'];
}

export interface QueryFlowConfigArgs {
	flowConfigId: Scalars['String']['input'];
}

export interface QueryGatekeepsArgs {
	names: ReadonlyArray<Scalars['String']['input']>;
}

export interface QueryInstantSearchArgs {
	query: InputMaybe<Scalars['String']['input']>;
}

export interface QueryLiveEventArgs {
	liveEventId: Scalars['String']['input'];
}

export interface QueryLiveEventsFilteredArgs {
	after: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
	statuses?: InputMaybe<ReadonlyArray<GraphLiveEventStatusInput>>;
	types: ReadonlyArray<GraphLiveEventTypeInput>;
}

export interface QueryLiveEventsRecommendedArgs {
	after: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
	latitude: InputMaybe<Scalars['Float']['input']>;
	longitude: InputMaybe<Scalars['Float']['input']>;
}

export interface QueryLiveVideoStreamArgs {
	liveVideoStreamId: Scalars['String']['input'];
}

export interface QueryLivestreamArgs {
	livestreamId: Scalars['String']['input'];
}

export interface QueryLyricsArgs {
	lyricsId: Scalars['String']['input'];
}

export interface QueryMusicTogetherAffinityArgs {
	memberId: Scalars['ID']['input'];
	musicTogetherGroupId: Scalars['ID']['input'];
}

export interface QueryMusicTogetherGroupArgs {
	musicTogetherGroupId: Scalars['ID']['input'];
}

export interface QueryNodeArgs {
	id: Scalars['ID']['input'];
}

export interface QueryOnboardingStepsArgs {
	context?: OnboardingContextInput;
}

export interface QueryPlaylistArgs {
	playlistId: InputMaybe<Scalars['String']['input']>;
}

export interface QueryPlaylistFromSmartTracklistArgs {
	smartTracklistId: Scalars['String']['input'];
}

export interface QueryPlaylistsArgs {
	after: InputMaybe<Scalars['String']['input']>;
	first?: Scalars['Int']['input'];
	playlistIds: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
}

export interface QueryPlaylistsByIdsArgs {
	ids: ReadonlyArray<Scalars['String']['input']>;
}

export interface QueryPodcastArgs {
	podcastId: Scalars['String']['input'];
}

export interface QueryPodcastEpisodeArgs {
	podcastEpisodeId: Scalars['String']['input'];
}

export interface QueryPodcastEpisodesArgs {
	after: InputMaybe<Scalars['String']['input']>;
	first?: Scalars['Int']['input'];
	podcastEpisodeIds: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
}

export interface QueryPodcastEpisodesByIdsArgs {
	ids: ReadonlyArray<Scalars['String']['input']>;
}

export interface QueryPromotedContentArgs {
	promotedContentId: Scalars['String']['input'];
	support: InputMaybe<ReadonlyArray<PromotableContentSupportInput>>;
}

export interface QueryPromotedContentsArgs {
	after: InputMaybe<Scalars['String']['input']>;
	first?: Scalars['Int']['input'];
	promotedContentIds: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
	support: ReadonlyArray<PromotableContentSupportInput>;
}

export interface QueryPromotionalVideoArgs {
	videoId: Scalars['String']['input'];
}

export interface QueryPromotionalVideosArgs {
	after: InputMaybe<Scalars['String']['input']>;
	first?: Scalars['Int']['input'];
	videoIds: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
}

export interface QueryPromotionalVideosByIdsArgs {
	ids: ReadonlyArray<Scalars['String']['input']>;
}

export interface QueryQueueChunkByQueueIdArgs {
	queueId: Scalars['ID']['input'];
}

export interface QueryQueueChunksByIdsArgs {
	chunksIds: ReadonlyArray<Scalars['ID']['input']>;
	queueId: Scalars['ID']['input'];
}

export interface QuerySearchArgs {
	query: InputMaybe<Scalars['String']['input']>;
}

export interface QuerySearchTrendingQueriesArgs {
	after: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
}

export interface QuerySmartTracklistArgs {
	smartTracklistId: Scalars['String']['input'];
}

export interface QueryTrackArgs {
	trackId: Scalars['String']['input'];
}

export interface QueryTracksArgs {
	after: InputMaybe<Scalars['String']['input']>;
	first?: Scalars['Int']['input'];
	trackIds: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
}

export interface QueryTracksByIdsArgs {
	ids: ReadonlyArray<Scalars['String']['input']>;
}

export interface QueryUcpsDataArgs {
	userId: Scalars['String']['input'];
}

export interface QueryUserArgs {
	userId: InputMaybe<Scalars['String']['input']>;
}

export interface QueryUsersArgs {
	after: InputMaybe<Scalars['String']['input']>;
	first?: Scalars['Int']['input'];
	userIds: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
}

export interface QueryUsersByIdsArgs {
	ids: ReadonlyArray<Scalars['String']['input']>;
}

export interface QueryVideoStreamArgs {
	videoStreamId: Scalars['String']['input'];
}

export interface QueryCorrection {
	readonly __typename: 'QueryCorrection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly revisedQuery: Scalars['String']['output'];
}

/** Part of a queue list */
export interface QueueChunk extends Node {
	readonly __typename: 'QueueChunk';
	/**
	 * List of all contexts referenced at QueueMedia level
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly contexts: ReadonlyArray<QueueContext>;
	/**
	 * Queue chunk's identifier
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly id: Scalars['ID']['output'];
	/**
	 * List of all queue chunk's media
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly mediaList: ReadonlyArray<QueueMedia>;
	/**
	 * Metadata associated to the queue it belongs to
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly queueMetadata: QueueMetadata;
	/**
	 * Size of the queue chunk
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly size: Scalars['Int']['output'];
	/**
	 * A paginated list of all tracks belonging to the chunk
	 * Note: Be aware that a queue can have multiple chunks, this fields only provides pagination over the current chunk tracks, not over all tracks of the queue it belongs to.
	 * If you want to retrieve every media belonging to this chunk, prefer using mediaList that will contain all kinds of media, not only tracks.
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly tracks: QueueTracksConnection;
}

/** Part of a queue list */
export interface QueueChunkTracksArgs {
	after: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
}

/** Context associated to one or multiple media within a queue */
export interface QueueContext {
	readonly __typename: 'QueueContext';
	/**
	 * The context itself
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly content: Scalars['String']['output'];
	/**
	 * Context identifier
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly id: Scalars['ID']['output'];
}

/** Context associated to one or multiple media within a queue */
export interface QueueContextInput {
	/** The context itself */
	readonly content: Scalars['String']['input'];
	/** The context identifier */
	readonly id: Scalars['ID']['input'];
}

/** Representation of a media within a Queue */
export interface QueueMedia {
	readonly __typename: 'QueueMedia';
	/**
	 * ID of the context associated to the media. Corresponding context can be found in the QueueChunk.contexts field
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly contextId: Maybe<Scalars['ID']['output']>;
	/**
	 * Media identifier
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly id: Scalars['ID']['output'];
	/**
	 * Media type
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly type: QueueMediaType;
}

export interface QueueMediaInput {
	/** ID of the context associated to the media. Corresponding context can be found in the mutation contexts field */
	readonly contextId: InputMaybe<Scalars['ID']['input']>;
	/** Media id */
	readonly id: Scalars['ID']['input'];
	/** Media type */
	readonly type: MediaTypeInput;
}

/** Type of media that can be in a queue */
export enum QueueMediaType {
	/** Chapter of an audiobook */
	CHAPTER = 'CHAPTER',
	/** Episode of a podcast */
	EPISODE = 'EPISODE',
	/** Live stream radio like FM radio */
	LIVE = 'LIVE',
	/** Media raw url */
	RAW_URL = 'RAW_URL',
	/** Track of an album */
	TRACK = 'TRACK',
}

/**
 * Describes a Queue with all it's chunk
 * This allows to know the size of the queue and to get the list of its chunks
 */
export interface QueueMetadata {
	readonly __typename: 'QueueMetadata';
	/**
	 * Metadata of every chunks composing the queue
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly chunkMetadataList: ReadonlyArray<ChunkMetadata>;
	/**
	 * Queue's identifier
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly queueId: Scalars['ID']['output'];
}

/** Represents the last synchronized state of a queue */
export interface QueueState {
	readonly __typename: 'QueueState';
	/**
	 * Details about the last client which updated the queue state
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly lastClientDetails: QueueStateClientDetails;
	/**
	 * When order of queue differ from the original order (shuffled)
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly originalQueue: Maybe<QueueChunk>;
	/**
	 * Zero based index of the current playback position in the queue
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly position: Scalars['Int']['output'];
	/**
	 * The queue itself
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly queue: QueueChunk;
	/**
	 * Configuration of the loaded queue
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly queueConfiguration: Scalars['String']['output'];
	/**
	 * Current repeat mode set for the queue
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly repeatMode: RepeatMode;
	/**
	 * Date of last queue state change
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly updatedAt: Scalars['Date']['output'];
	/**
	 * Version of the queue state
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly version: Scalars['String']['output'];
}

export interface QueueStateClientDetails {
	readonly __typename: 'QueueStateClientDetails';
	/**
	 * Identifier of the client's local queue, doesn't necessarily match a real QueueId
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly localQueueID: Scalars['String']['output'];
	/**
	 * Date of last local update
	 * Note: This may differ from the QueueState.lastUpdatedAt as this is the date when the change was made on client, but it could have been pushed to the backend later
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly locallyUpdatedAt: Scalars['Date']['output'];
	/**
	 * User agent
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly userAgent: Scalars['String']['output'];
}

export interface QueueTrackMetadata {
	readonly __typename: 'QueueTrackMetadata';
	/**
	 * Music together metadata of the track
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly musicTogether: Maybe<MusicTogetherTrackMetadata>;
}

export interface QueueTracksConnection {
	readonly __typename: 'QueueTracksConnection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly edges: ReadonlyArray<QueueTracksEdge>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly pageInfo: PageInfo;
}

export interface QueueTracksEdge {
	readonly __typename: 'QueueTracksEdge';
	/**
	 * ID of the context associated to the media. Corresponding context can be found in the QueueChunk.contexts field
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly contextId: Maybe<Scalars['ID']['output']>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly cursor: Scalars['String']['output'];
	/**
	 * Metadata of the track
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly metadata: Maybe<QueueTrackMetadata>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly node: Maybe<Track>;
}

export interface RecTokenPackage {
	readonly __typename: 'RECTokenPackage';
	/**
	 * Token cannot be used after its expiration date, must fetch again for a new one.
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly expirationDate: Maybe<Scalars['Date']['output']>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly token: Maybe<Scalars['String']['output']>;
}

export interface RawBannedContent {
	readonly __typename: 'RawBannedContent';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly bannedAt: Scalars['Date']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly id: Scalars['ID']['output'];
}

export interface RawFavorite {
	readonly __typename: 'RawFavorite';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly favoritedAt: Scalars['Date']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly id: Scalars['ID']['output'];
}

/** Raw information related to a given podcast episode bookmark */
export interface RawPodcastEpisodeBookmark {
	readonly __typename: 'RawPodcastEpisodeBookmark';
	/**
	 * Date the bookmark was created at
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly bookmarkedAt: Scalars['Date']['output'];
	/**
	 * Id of the podcast episode that has been bookmarked
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly episodeId: Scalars['String']['output'];
	/**
	 * Has the bookmarked been played
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly isPlayed: Scalars['Boolean']['output'];
	/**
	 * Listening time the bookmark is at
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly position: Scalars['Int']['output'];
}

export type RecentlyPlayedContent =
	| Album
	| Artist
	| Flow
	| Livestream
	| Mix
	| Playlist
	| Podcast;

export interface RelatedArtistEdge {
	readonly __typename: 'RelatedArtistEdge';
	readonly cursor: Scalars['String']['output'];
	/**
	 * Can return error codes:
	 * * ERR_ARTIST_NOT_FOUND: Is returned when artist doesn't exist
	 */
	readonly node: Maybe<Artist>;
}

export interface RemoteControl {
	readonly __typename: 'RemoteControl';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly sonos: RemoteControlSonos;
}

export interface RemoteControlSonos {
	readonly __typename: 'RemoteControlSonos';
	/**
	 * Start URL for the association journey between the Deezer account and the Sonos account of the current User
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly associationJourneyStartUrl: Scalars['String']['output'];
}

/** Player repeat mode */
export enum RepeatMode {
	/** Repeat whole queue in loop, Actually, has no meaning when toggled on infinite queue */
	ALL = 'ALL',
	/** No repeat queue is played as given */
	NONE = 'NONE',
	/** Repeat active track one played */
	ONE = 'ONE',
}

/** Player repeat mode */
export enum RepeatModeInput {
	/** Repeat whole queue in loop, Actually, has no meaning when toggled on infinite queue */
	ALL = 'ALL',
	/** No repeat queue is played as given */
	NONE = 'NONE',
	/** Repeat active track one played */
	ONE = 'ONE',
}

export interface ReportCatalogIssueMutationError {
	readonly __typename: 'ReportCatalogIssueMutationError';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly isInvalidId: Scalars['Boolean']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly isUnloggedUser: Scalars['Boolean']['output'];
}

export interface ReportCatalogIssueMutationOutput {
	readonly __typename: 'ReportCatalogIssueMutationOutput';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly success: Scalars['Boolean']['output'];
}

export type ReportCatalogIssueMutationResult =
	| ReportCatalogIssueMutationError
	| ReportCatalogIssueMutationOutput;

export interface SaveBlindTestScoreMutationOutput {
	readonly __typename: 'SaveBlindTestScoreMutationOutput';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly status: Scalars['Boolean']['output'];
}

export interface Search {
	readonly __typename: 'Search';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly mixedResults: SearchMixedResultsConnection;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly queryCorrection: Maybe<QueryCorrection>;
	readonly results: SearchResults;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly suggestions: SearchSuggestionsConnection;
	readonly topResult: SearchTopResult;
}

export interface SearchMixedResultsArgs {
	after: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
	types?: InputMaybe<ReadonlyArray<GraphSearchMixedResultTypeInput>>;
}

export interface SearchSuggestionsArgs {
	after: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
}

export interface SearchAlbumsConnection {
	readonly __typename: 'SearchAlbumsConnection';
	readonly edges: ReadonlyArray<SearchAlbumsConnectionEdge>;
	readonly pageInfo: PageInfo;
	readonly priority: Scalars['Int']['output'];
}

export interface SearchAlbumsConnectionEdge {
	readonly __typename: 'SearchAlbumsConnectionEdge';
	readonly cursor: Scalars['String']['output'];
	/**
	 * Can return error codes:
	 * * ERR_ALBUM_NOT_FOUND: Is returned when album doesn't exist
	 */
	readonly node: Maybe<Album>;
}

export interface SearchArtistsConnection {
	readonly __typename: 'SearchArtistsConnection';
	readonly edges: ReadonlyArray<SearchArtistsConnectionEdge>;
	readonly pageInfo: PageInfo;
	readonly priority: Scalars['Int']['output'];
}

export interface SearchArtistsConnectionEdge {
	readonly __typename: 'SearchArtistsConnectionEdge';
	readonly cursor: Scalars['String']['output'];
	/**
	 * Can return error codes:
	 * * ERR_ARTIST_NOT_FOUND: Is returned when artist doesn't exist
	 */
	readonly node: Maybe<Artist>;
}

export interface SearchChannelsConnection {
	readonly __typename: 'SearchChannelsConnection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly edges: ReadonlyArray<SearchChannelsConnectionEdge>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly pageInfo: PageInfo;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly priority: Scalars['Int']['output'];
}

export interface SearchChannelsConnectionEdge {
	readonly __typename: 'SearchChannelsConnectionEdge';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly cursor: Scalars['String']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly node: Maybe<Channel>;
}

export interface SearchCitiesConnection {
	readonly __typename: 'SearchCitiesConnection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly edges: ReadonlyArray<SearchCitiesConnectionEdge>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly pageInfo: PageInfo;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly priority: Scalars['Int']['output'];
}

export interface SearchCitiesConnectionEdge {
	readonly __typename: 'SearchCitiesConnectionEdge';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly cursor: Scalars['String']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly node: Maybe<City>;
}

export interface SearchFlowConfigConnection {
	readonly __typename: 'SearchFlowConfigConnection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly edges: ReadonlyArray<SearchFlowConfigConnectionEdge>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly pageInfo: PageInfo;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly priority: Scalars['Int']['output'];
}

export interface SearchFlowConfigConnectionEdge {
	readonly __typename: 'SearchFlowConfigConnectionEdge';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly cursor: Scalars['String']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly node: Maybe<FlowConfig>;
}

export interface SearchHistory {
	readonly __typename: 'SearchHistory';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly queries: Maybe<SearchHistoryQueriesConnection>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly successResults: Maybe<SearchHistorySuccessResultsConnection>;
}

export interface SearchHistoryQueriesArgs {
	after: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
}

export interface SearchHistorySuccessResultsArgs {
	after: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
}

export interface SearchHistoryQueriesConnection {
	readonly __typename: 'SearchHistoryQueriesConnection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly edges: ReadonlyArray<SearchHistoryQueriesConnectionEdge>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly pageInfo: PageInfo;
}

export interface SearchHistoryQueriesConnectionEdge {
	readonly __typename: 'SearchHistoryQueriesConnectionEdge';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly cursor: Scalars['String']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly node: Maybe<SearchHistoryQuery>;
}

export interface SearchHistoryQuery {
	readonly __typename: 'SearchHistoryQuery';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly query: Scalars['String']['output'];
}

export type SearchHistorySuccessResult =
	| Album
	| Artist
	| Livestream
	| Mix
	| Playlist
	| Podcast
	| PodcastEpisode
	| Track;

export interface SearchHistorySuccessResultsConnection {
	readonly __typename: 'SearchHistorySuccessResultsConnection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly edges: ReadonlyArray<SearchHistorySuccessResultsConnectionEdge>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly pageInfo: PageInfo;
}

export interface SearchHistorySuccessResultsConnectionEdge {
	readonly __typename: 'SearchHistorySuccessResultsConnectionEdge';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly cursor: Scalars['String']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly node: Maybe<SearchHistorySuccessResult>;
}

export interface SearchHistorySuccessResultsMutationOutput {
	readonly __typename: 'SearchHistorySuccessResultsMutationOutput';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly status: Scalars['Boolean']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly successResults: Maybe<SearchHistorySuccessResultsConnection>;
}

export interface SearchHistorySuccessResultsMutationOutputSuccessResultsArgs {
	after: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
}

export interface SearchLiveEventsConnection {
	readonly __typename: 'SearchLiveEventsConnection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly edges: ReadonlyArray<SearchLiveEventsConnectionEdge>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly pageInfo: PageInfo;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly priority: Scalars['Int']['output'];
}

export interface SearchLiveEventsConnectionEdge {
	readonly __typename: 'SearchLiveEventsConnectionEdge';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly cursor: Scalars['String']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly node: Maybe<LiveEvent>;
}

export interface SearchLivestreamsConnection {
	readonly __typename: 'SearchLivestreamsConnection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly edges: ReadonlyArray<SearchLivestreamsConnectionEdge>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly pageInfo: PageInfo;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly priority: Scalars['Int']['output'];
}

export interface SearchLivestreamsConnectionEdge {
	readonly __typename: 'SearchLivestreamsConnectionEdge';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly cursor: Scalars['String']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly node: Maybe<Livestream>;
}

export type SearchMixedResult =
	| Album
	| Artist
	| Channel
	| FlowConfig
	| Livestream
	| Playlist
	| Podcast
	| PodcastEpisode
	| Track
	| User;

export interface SearchMixedResultsConnection {
	readonly __typename: 'SearchMixedResultsConnection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly edges: ReadonlyArray<SearchMixedResultsConnectionEdge>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly pageInfo: PageInfo;
}

export interface SearchMixedResultsConnectionEdge {
	readonly __typename: 'SearchMixedResultsConnectionEdge';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly cursor: Scalars['String']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly node: Maybe<SearchMixedResult>;
}

export interface SearchPlaylistsConnection {
	readonly __typename: 'SearchPlaylistsConnection';
	readonly edges: ReadonlyArray<SearchPlaylistsConnectionEdge>;
	readonly pageInfo: PageInfo;
	readonly priority: Scalars['Int']['output'];
}

export interface SearchPlaylistsConnectionEdge {
	readonly __typename: 'SearchPlaylistsConnectionEdge';
	readonly cursor: Scalars['String']['output'];
	/**
	 * Can return error codes:
	 * * ERR_PLAYLIST_NOT_FOUND: Is returned when playlist doesn't exist
	 * * ERR_PLAYLIST_ACCESS_NOT_AUTHORIZED: Is returned when playlist access is not allowed
	 */
	readonly node: Maybe<Playlist>;
}

export interface SearchPodcastEpisodesConnection {
	readonly __typename: 'SearchPodcastEpisodesConnection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly edges: ReadonlyArray<SearchPodcastEpisodesConnectionEdge>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly pageInfo: PageInfo;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly priority: Scalars['Int']['output'];
}

export interface SearchPodcastEpisodesConnectionEdge {
	readonly __typename: 'SearchPodcastEpisodesConnectionEdge';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly cursor: Scalars['String']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly node: Maybe<PodcastEpisode>;
}

export interface SearchPodcastsConnection {
	readonly __typename: 'SearchPodcastsConnection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly edges: ReadonlyArray<SearchPodcastsConnectionEdge>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly pageInfo: PageInfo;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly priority: Scalars['Int']['output'];
}

export interface SearchPodcastsConnectionEdge {
	readonly __typename: 'SearchPodcastsConnectionEdge';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly cursor: Scalars['String']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly node: Maybe<Podcast>;
}

export interface SearchResults {
	readonly __typename: 'SearchResults';
	readonly albums: SearchAlbumsConnection;
	readonly artists: SearchArtistsConnection;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly channels: SearchChannelsConnection;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly cities: SearchCitiesConnection;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly flowConfigs: SearchFlowConfigConnection;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly liveEvents: SearchLiveEventsConnection;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly livestreams: SearchLivestreamsConnection;
	readonly playlists: SearchPlaylistsConnection;
	readonly podcastEpisodes: SearchPodcastEpisodesConnection;
	readonly podcasts: SearchPodcastsConnection;
	readonly tracks: SearchTracksConnection;
	readonly tracksByLyrics: SearchTracksConnection;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly users: SearchUsersConnection;
}

export interface SearchResultsAlbumsArgs {
	after: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
}

export interface SearchResultsArtistsArgs {
	after: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
}

export interface SearchResultsChannelsArgs {
	after: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
}

export interface SearchResultsCitiesArgs {
	after: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
}

export interface SearchResultsFlowConfigsArgs {
	after: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
}

export interface SearchResultsLiveEventsArgs {
	after: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
}

export interface SearchResultsLivestreamsArgs {
	after: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
}

export interface SearchResultsPlaylistsArgs {
	after: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
}

export interface SearchResultsPodcastEpisodesArgs {
	after: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
}

export interface SearchResultsPodcastsArgs {
	after: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
}

export interface SearchResultsTracksArgs {
	after: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
}

export interface SearchResultsTracksByLyricsArgs {
	after: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
}

export interface SearchResultsUsersArgs {
	after: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
}

export interface SearchSuggestion {
	readonly __typename: 'SearchSuggestion';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly query: Scalars['String']['output'];
}

export interface SearchSuggestionsConnection {
	readonly __typename: 'SearchSuggestionsConnection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly edges: ReadonlyArray<SearchSuggestionsConnectionEdge>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly pageInfo: PageInfo;
}

export interface SearchSuggestionsConnectionEdge {
	readonly __typename: 'SearchSuggestionsConnectionEdge';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly cursor: Scalars['String']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly node: Maybe<SearchSuggestion>;
}

export interface SearchTopResult {
	readonly __typename: 'SearchTopResult';
	readonly content: Maybe<SearchTopResultContent>;
}

export type SearchTopResultContent =
	| Album
	| Artist
	| Livestream
	| Playlist
	| Podcast
	| PodcastEpisode
	| Track;

export interface SearchTracksConnection {
	readonly __typename: 'SearchTracksConnection';
	readonly edges: ReadonlyArray<SearchTracksConnectionEdge>;
	readonly pageInfo: PageInfo;
	readonly priority: Scalars['Int']['output'];
}

export interface SearchTracksConnectionEdge {
	readonly __typename: 'SearchTracksConnectionEdge';
	readonly cursor: Scalars['String']['output'];
	readonly node: Maybe<Track>;
}

export interface SearchTrendingQueriesConnection {
	readonly __typename: 'SearchTrendingQueriesConnection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly edges: ReadonlyArray<SearchTrendingQueriesConnectionEdge>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly pageInfo: PageInfo;
}

export interface SearchTrendingQueriesConnectionEdge {
	readonly __typename: 'SearchTrendingQueriesConnectionEdge';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly cursor: Scalars['String']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly node: Maybe<TrendingQuery>;
}

export interface SearchUsersConnection {
	readonly __typename: 'SearchUsersConnection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly edges: ReadonlyArray<SearchUsersConnectionEdge>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly pageInfo: PageInfo;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly priority: Scalars['Int']['output'];
}

export interface SearchUsersConnectionEdge {
	readonly __typename: 'SearchUsersConnectionEdge';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly cursor: Scalars['String']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly node: Maybe<User>;
}

export type Section =
	| AdSection
	| ConversionSection
	| GridFullSection
	| GridOneLineSection
	| GridTwoLinesSection
	| HorizontalGridSection
	| InformativeSection
	| ItemHighlightSection
	| LargeCardSection
	| ListSection
	| LongCardHorizontalGridSection
	| MiniBannerSection
	| OnboardingSection
	| SlideshowSection
	| SmallHorizontalGridSection;

export interface SectionEdge {
	readonly __typename: 'SectionEdge';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly cursor: Scalars['String']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly node: Maybe<Section>;
}

export interface SectionTarget {
	readonly __typename: 'SectionTarget';
	/**
	 * The dynamic page uri to be used with dynamicPage query if section's target is a dynamic page
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly dynamicPageUri: Maybe<Scalars['String']['output']>;
	/**
	 * True if the "view more" must absolutely be displayed for the section
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly isForced: Scalars['Boolean']['output'];
	/**
	 * Absolute canonical section's targeted URL on Deezer
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly url: DeezerUrl;
}

export enum SectionTypeInput {
	AD = 'AD',
	CONVERSION = 'CONVERSION',
	GRID_1L = 'GRID_1L',
	GRID_2L = 'GRID_2L',
	GRID_FULL = 'GRID_FULL',
	HORIZONTAL_GRID = 'HORIZONTAL_GRID',
	INFORMATIVE = 'INFORMATIVE',
	ITEM_HIGHLIGHT = 'ITEM_HIGHLIGHT',
	LARGE_CARD = 'LARGE_CARD',
	LIST = 'LIST',
	LONG_CARD_HORIZONTAL_GRID = 'LONG_CARD_HORIZONTAL_GRID',
	MINI_BANNER = 'MINI_BANNER',
	ONBOARDING = 'ONBOARDING',
	SLIDESHOW = 'SLIDESHOW',
	SMALL_HORIZONTAL_GRID = 'SMALL_HORIZONTAL_GRID',
}

export interface SectionsConnection {
	readonly __typename: 'SectionsConnection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly edges: ReadonlyArray<SectionEdge>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly pageInfo: PageInfo;
}

export interface SetDiscoveryTunerOutput {
	readonly __typename: 'SetDiscoveryTunerOutput';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly discoveryTuner: DiscoveryTuner;
}

export interface SharingAssetClipboard {
	readonly __typename: 'SharingAssetClipboard';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly message: Maybe<Scalars['String']['output']>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly socialLink: SocialLink;
}

export interface SharingAssetFacebookStory {
	readonly __typename: 'SharingAssetFacebookStory';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly backgroundFormat: BackgroundFormats;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly backgroundUrl: Scalars['String']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly redirectUrl: Scalars['String']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly stickerUrl: Maybe<Scalars['String']['output']>;
}

export interface SharingAssetInstagram {
	readonly __typename: 'SharingAssetInstagram';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly backgroundFormat: BackgroundFormats;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly backgroundUrl: Scalars['String']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly redirectUrl: Scalars['String']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly stickerUrl: Maybe<Scalars['String']['output']>;
}

export interface SharingAssetSms {
	readonly __typename: 'SharingAssetSMS';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly message: Maybe<Scalars['String']['output']>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly socialLink: SocialLink;
}

export interface SharingAssetSnapchat {
	readonly __typename: 'SharingAssetSnapchat';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly backgroundFormat: BackgroundFormats;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly backgroundUrl: Scalars['String']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly redirectUrl: Scalars['String']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly stickerUrl: Maybe<Scalars['String']['output']>;
}

export interface SharingAssetSystem {
	readonly __typename: 'SharingAssetSystem';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly message: Maybe<Scalars['String']['output']>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly socialLink: SocialLink;
}

export interface SharingAssetTwitter {
	readonly __typename: 'SharingAssetTwitter';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly message: Maybe<Scalars['String']['output']>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly socialLink: SocialLink;
}

export interface SharingAssetWhatsapp {
	readonly __typename: 'SharingAssetWhatsapp';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly imageUrl: Maybe<Scalars['String']['output']>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly message: Maybe<Scalars['String']['output']>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly socialLink: SocialLink;
}

export interface SharingAssets {
	readonly __typename: 'SharingAssets';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly clipboard: SharingAssetClipboard;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly facebook: Maybe<SocialLink>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly facebookStory: Maybe<SharingAssetFacebookStory>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly instagram: Maybe<SharingAssetInstagram>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly messenger: Maybe<SocialLink>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly sms: SharingAssetSms;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly snapchat: Maybe<SharingAssetSnapchat>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly system: SharingAssetSystem;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly twitter: Maybe<SharingAssetTwitter>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly whatsapp: Maybe<SharingAssetWhatsapp>;
}

export interface SimpleStory {
	readonly __typename: 'SimpleStory';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly placeholders: ReadonlyArray<KeyValue>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly storyId: Scalars['String']['output'];
}

export enum SlideshowItemSupportInput {
	ALBUM = 'ALBUM',
	ARTIST = 'ARTIST',
	CHANNEL = 'CHANNEL',
	FLOW = 'FLOW',
	LIVESTREAM = 'LIVESTREAM',
	MIX = 'MIX',
	PLAYLIST = 'PLAYLIST',
	PODCAST = 'PODCAST',
	PROMOTED_CONTENT = 'PROMOTED_CONTENT',
	PROMOTIONAL_VIDEO = 'PROMOTIONAL_VIDEO',
	SMART_TRACKLIST = 'SMART_TRACKLIST',
}

export interface SlideshowSection {
	readonly __typename: 'SlideshowSection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly content: SlideshowSectionContent;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly hasAnimation: Scalars['Boolean']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly subtitle: Maybe<Scalars['String']['output']>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly target: Maybe<SectionTarget>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly title: Maybe<Scalars['String']['output']>;
}

export interface SlideshowSectionContentArgs {
	support: ReadonlyArray<SlideshowItemSupportInput>;
}

export interface SlideshowSectionContent {
	readonly __typename: 'SlideshowSectionContent';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly items: SlideshowSectionContentCollection;
}

export interface SlideshowSectionContentItemsArgs {
	limit?: InputMaybe<Scalars['Int']['input']>;
}

export interface SlideshowSectionContentCollection {
	readonly __typename: 'SlideshowSectionContentCollection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly expectsMoreItems: Scalars['Boolean']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly nodes: ReadonlyArray<SlideshowSectionContentItemNode>;
}

export type SlideshowSectionContentItem =
	| Album
	| Artist
	| Channel
	| Flow
	| Livestream
	| Mix
	| Playlist
	| Podcast
	| PromotedContent
	| PromotionalVideo
	| SmartTracklist;

export interface SlideshowSectionContentItemNode {
	readonly __typename: 'SlideshowSectionContentItemNode';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly item: SlideshowSectionContentItem;
}

export enum SmallHorizontalGridItemSupportInput {
	ALBUM = 'ALBUM',
	ARTIST = 'ARTIST',
	CHANNEL = 'CHANNEL',
	FLOW = 'FLOW',
	LIVESTREAM = 'LIVESTREAM',
	MIX = 'MIX',
	PLAYLIST = 'PLAYLIST',
	PODCAST = 'PODCAST',
}

export interface SmallHorizontalGridSection {
	readonly __typename: 'SmallHorizontalGridSection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly content: SmallHorizontalGridSectionContent;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly subtitle: Maybe<Scalars['String']['output']>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly target: Maybe<SectionTarget>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly title: Maybe<Scalars['String']['output']>;
}

export interface SmallHorizontalGridSectionContentArgs {
	support: ReadonlyArray<SmallHorizontalGridItemSupportInput>;
}

export interface SmallHorizontalGridSectionContent {
	readonly __typename: 'SmallHorizontalGridSectionContent';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly items: SmallHorizontalGridSectionContentCollection;
}

export interface SmallHorizontalGridSectionContentItemsArgs {
	limit?: InputMaybe<Scalars['Int']['input']>;
}

export interface SmallHorizontalGridSectionContentCollection {
	readonly __typename: 'SmallHorizontalGridSectionContentCollection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly expectsMoreItems: Scalars['Boolean']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly nodes: ReadonlyArray<SmallHorizontalGridSectionContentItemNode>;
}

export type SmallHorizontalGridSectionContentItem =
	| Album
	| Artist
	| Channel
	| Flow
	| Livestream
	| Mix
	| Playlist
	| Podcast;

export interface SmallHorizontalGridSectionContentItemNode {
	readonly __typename: 'SmallHorizontalGridSectionContentItemNode';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly item: SmallHorizontalGridSectionContentItem;
}

/**
 * SmartTracklist are playlist generated for a given user
 * (Aka "Generated for you")
 */
export interface SmartTracklist extends MadeForMe, Node {
	readonly __typename: 'SmartTracklist';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly cover: Maybe<Picture>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly id: Scalars['ID']['output'];
	/**
	 * Method of SmartTracklist
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly method: SmartTracklistMethod;
	/**
	 * The full list of the smarttracklist's raw tracks ids.
	 * You may receive one track, or thousands, hence you must fetch this field only when absolutely needed, and only once per query.
	 * We defined the cost of the field accordingly.
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly rawTracks: ReadonlyArray<SmartTracklistRawTrack>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly subTitle: Maybe<Scalars['String']['output']>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly title: Scalars['String']['output'];
	/**
	 * Paginated list of the smarttracklist's tracks
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly tracks: SmartTracklistTracksConnection;
}

/**
 * SmartTracklist are playlist generated for a given user
 * (Aka "Generated for you")
 */
export interface SmartTracklistTracksArgs {
	after: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
}

/** SmartTracklistMethod can be have only one property set at true. */
export interface SmartTracklistMethod {
	readonly __typename: 'SmartTracklistMethod';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly isAnnualMissed: Scalars['Boolean']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly isAnnualTop: Scalars['Boolean']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly isArtistCluster: Scalars['Boolean']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly isDiscoveryTracks: Scalars['Boolean']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly isFamilyMix: Scalars['Boolean']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly isMonthlyTop: Scalars['Boolean']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly isNewReleases: Scalars['Boolean']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly isTopSummerWinter: Scalars['Boolean']['output'];
}

export interface SmartTracklistRawTrack {
	readonly __typename: 'SmartTracklistRawTrack';
	/**
	 * The track itself
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly id: Scalars['ID']['output'];
}

export interface SmartTracklistTracksConnection {
	readonly __typename: 'SmartTracklistTracksConnection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly edges: ReadonlyArray<SmartTracklistTracksEdge>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly pageInfo: PageInfo;
}

export interface SmartTracklistTracksEdge {
	readonly __typename: 'SmartTracklistTracksEdge';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly cursor: Scalars['String']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly node: Maybe<Track>;
}

export interface SocialLink {
	readonly __typename: 'SocialLink';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly link: Scalars['String']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly previewDescription: Maybe<Scalars['String']['output']>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly previewImageUrl: Maybe<Scalars['String']['output']>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly previewTitle: Maybe<Scalars['String']['output']>;
}

export interface SongCatcherHistory {
	readonly __typename: 'SongCatcherHistory';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly cover: UiAsset;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly title: Scalars['String']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly tracks: SongCatcherHistoryConnection;
}

export interface SongCatcherHistoryTracksArgs {
	after: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
}

export interface SongCatcherHistoryConnection {
	readonly __typename: 'SongCatcherHistoryConnection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly edges: ReadonlyArray<SongCatcherHistoryConnectionEdge>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly pageInfo: PageInfo;
}

export interface SongCatcherHistoryConnectionEdge {
	readonly __typename: 'SongCatcherHistoryConnectionEdge';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly addedAt: Maybe<Scalars['Date']['output']>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly cursor: Scalars['String']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly node: Maybe<Track>;
}

export interface SongkickSource {
	readonly __typename: 'SongkickSource';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly id: Scalars['String']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly uri: Maybe<Scalars['String']['output']>;
}

export enum SortOrderInput {
	ASC = 'ASC',
	DESC = 'DESC',
}

export interface Story extends CardTemplate {
	readonly __typename: 'Story';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly background: Maybe<Asset>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly cta: Maybe<Cta>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly header: Maybe<Scalars['String']['output']>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly name: Scalars['String']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly text: Maybe<ReadonlyArray<Scalars['String']['output']>>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly title: Maybe<ReadonlyArray<Scalars['String']['output']>>;
}

export interface SubRights {
	readonly __typename: 'SubRights';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly available: Scalars['Boolean']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly availableAfter: Scalars['Date']['output'];
}

export interface Summary extends CardTemplate {
	readonly __typename: 'Summary';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly background: Maybe<Asset>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly cta: Maybe<Cta>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly header: Maybe<Scalars['String']['output']>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly items: ReadonlyArray<TopItem>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly name: Scalars['String']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly text: ReadonlyArray<Scalars['String']['output']>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly title: Maybe<ReadonlyArray<Scalars['String']['output']>>;
}

export interface Tokens {
	readonly __typename: 'Tokens';
	/**
	 * Opaque string to pass to live and relive for authentication
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly liveToken: Maybe<LiveTokenPackage>;
	/**
	 * Opaque string to pass to the media service alongside track's token to play music.
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly mediaServiceLicenseToken: Maybe<MediaServiceLicenseTokenPackage>;
	/**
	 * Opaque string to pass to REC for authentication
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly recToken: Maybe<RecTokenPackage>;
}

export interface Top extends CardTemplate {
	readonly __typename: 'Top';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly background: Maybe<Asset>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly cta: Maybe<Cta>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly header: Maybe<Scalars['String']['output']>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly items: ReadonlyArray<TopItem>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly name: Scalars['String']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly title: Maybe<ReadonlyArray<Scalars['String']['output']>>;
}

export interface TopArtistUserShareConnection {
	readonly __typename: 'TopArtistUserShareConnection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly edges: ReadonlyArray<TopArtistUserShareEdge>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly pageInfo: PageInfo;
}

export interface TopArtistUserShareEdge {
	readonly __typename: 'TopArtistUserShareEdge';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly nbStreams: Scalars['Int']['output'];
	/**
	 * Can return error codes:
	 * * ERR_ARTIST_NOT_FOUND: Is returned when artist doesn't exist
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly node: Maybe<Artist>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly userShare: Scalars['Int']['output'];
}

export interface TopItem {
	readonly __typename: 'TopItem';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly image: Maybe<FormattedImage>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly text: Maybe<ReadonlyArray<Scalars['String']['output']>>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly title: ReadonlyArray<Scalars['String']['output']>;
}

export interface TopRankedPlayer {
	readonly __typename: 'TopRankedPlayer';
	/**
	 * Top user's best score recorded
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly bestScore: Scalars['Int']['output'];
	/**
	 * Top user's rank
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly rank: Scalars['Int']['output'];
	/**
	 * Information related to the user with the highest score
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly user: Maybe<User>;
}

export interface TopTrackEdge {
	readonly __typename: 'TopTrackEdge';
	readonly cursor: Scalars['String']['output'];
	readonly node: Maybe<Track>;
}

/**
 * Represents track information.
 *
 * A track can be either an album track delivered to Deezer by a music provider or
 * a track uploaded by a user
 *
 * Some fields can vary depending on the languages accepted by the client.
 */
export interface Track extends Node {
	readonly __typename: 'Track';
	readonly ISRC: Maybe<Scalars['String']['output']>;
	/**
	 * The album from which this track is from. Will be null for uploaded tracks
	 *
	 * Can return error codes:
	 * * ERR_ALBUM_NOT_FOUND: Is returned when album doesn't exist
	 */
	readonly album: Maybe<Album>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly bpm: Maybe<Scalars['Float']['output']>;
	/**
	 * Preferably use the contributors field which will return the artists
	 * with names according to your language preference.
	 *
	 * Ideally displayContributorNames should be able to receive an
	 * optional countryCode/language in order to be able to request several translations.
	 * It is not possible for the time being, we need more fine grained
	 * data loaders
	 *
	 * Note: Use after instead of cursor
	 */
	readonly contributorDisplayNames: TrackContributorDisplayNameConnection;
	/**
	 * Paginated list of artists who contributed to the track.
	 *
	 * The list can be filtered by the needed roles. See TrackContributorRoles for a list of roles.
	 * The list can be sorted by roles. The parameter rolesOrder will put first the artists whose roles are
	 * the leftmost of the list.
	 *
	 * Note: Use after instead of cursor
	 */
	readonly contributors: TrackContributorsConnection;
	/**
	 * Access: This field is restricted to applications granted with specific access.
	 * @deprecated Not used and album-related. Will always return null
	 */
	readonly copyright: Maybe<Scalars['String']['output']>;
	readonly diskInfo: Maybe<TrackDiskInfo>;
	/** Duration of the track in seconds */
	readonly duration: Scalars['Int']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly gain: Maybe<Scalars['Float']['output']>;
	readonly id: Scalars['ID']['output'];
	/**
	 * True if the track is available in atmos format
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly isAtmos: Scalars['Boolean']['output'];
	/**
	 * Whether or not the artist is banned from the logged-in user's recommendation
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly isBannedFromRecommendation: Maybe<Scalars['Boolean']['output']>;
	readonly isExplicit: Scalars['Boolean']['output'];
	/**
	 * Whether or not the track is in the currently logged-in user's favorites
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly isFavorite: Maybe<Scalars['Boolean']['output']>;
	/**
	 * Access: This field is restricted to applications granted with specific access.
	 * @deprecated Use ISRC instead
	 */
	readonly isrc: Scalars['String']['output'];
	/**
	 * Link to the lyrics of the track if available
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly lyrics: Maybe<Lyrics>;
	/**
	 * Contains media information to use for the player.
	 * This node will be populated only if some rights can be computed. In some case, it will be null, meaning the track
	 * cannot be read at all. Consider using this field for simple availability check (use mediaRights for deep checks)
	 * You can ask for the field TrackMedia.id only
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly media: Maybe<TrackMedia>;
	/**
	 * Access: This field is restricted to applications granted with specific access.
	 * @deprecated Please refer to the media property on track
	 */
	readonly mediaToken: Maybe<MediaToken>;
	readonly popularity: Maybe<Scalars['Float']['output']>;
	/**
	 * Recommended tracks related to the user
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly recommendedTracks: ReadonlyArray<Maybe<Track>>;
	/**
	 * Access: This field is restricted to applications granted with specific access.
	 * @deprecated Replaced by relatedTracks
	 */
	readonly relatedAlbums: TrackRelatedAlbumsConnection;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly relatedTracks: TrackRelatedTracksConnection;
	readonly releaseDate: Maybe<Scalars['Date']['output']>;
	/** Title of the track, in the first language in order of preferred languages (defaults to original name) */
	readonly title: Scalars['String']['output'];
	/**
	 * Only available in the case of an uploaded track.
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly userUploadInfo: Maybe<TrackUploadInfo>;
}

/**
 * Represents track information.
 *
 * A track can be either an album track delivered to Deezer by a music provider or
 * a track uploaded by a user
 *
 * Some fields can vary depending on the languages accepted by the client.
 */
export interface TrackContributorDisplayNamesArgs {
	after: InputMaybe<Scalars['String']['input']>;
	cursor: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
	roles?: InputMaybe<ReadonlyArray<TrackContributorRoles>>;
}

/**
 * Represents track information.
 *
 * A track can be either an album track delivered to Deezer by a music provider or
 * a track uploaded by a user
 *
 * Some fields can vary depending on the languages accepted by the client.
 */
export interface TrackContributorsArgs {
	after: InputMaybe<Scalars['String']['input']>;
	cursor: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
	roles?: InputMaybe<ReadonlyArray<TrackContributorRoles>>;
	rolesOrder?: InputMaybe<ReadonlyArray<TrackContributorRoles>>;
}

/**
 * Represents track information.
 *
 * A track can be either an album track delivered to Deezer by a music provider or
 * a track uploaded by a user
 *
 * Some fields can vary depending on the languages accepted by the client.
 */
export interface TrackRecommendedTracksArgs {
	nb?: InputMaybe<Scalars['Int']['input']>;
}

/**
 * Represents track information.
 *
 * A track can be either an album track delivered to Deezer by a music provider or
 * a track uploaded by a user
 *
 * Some fields can vary depending on the languages accepted by the client.
 */
export interface TrackRelatedAlbumsArgs {
	after: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
}

/**
 * Represents track information.
 *
 * A track can be either an album track delivered to Deezer by a music provider or
 * a track uploaded by a user
 *
 * Some fields can vary depending on the languages accepted by the client.
 */
export interface TrackRelatedTracksArgs {
	after: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
}

export interface TrackContributorDisplayNameConnection {
	readonly __typename: 'TrackContributorDisplayNameConnection';
	readonly edges: ReadonlyArray<TrackContributorDisplayNameEdge>;
	readonly pageInfo: PageInfo;
}

export interface TrackContributorDisplayNameEdge {
	readonly __typename: 'TrackContributorDisplayNameEdge';
	readonly cursor: Scalars['String']['output'];
	readonly node: Maybe<Scalars['String']['output']>;
	readonly role: TrackContributorRoles;
}

export enum TrackContributorRoles {
	AUTHOR = 'AUTHOR',
	COMPOSER = 'COMPOSER',
	CONDUCTOR = 'CONDUCTOR',
	FEATURED = 'FEATURED',
	MAIN = 'MAIN',
	ORCHESTRA = 'ORCHESTRA',
	SECONDARY = 'SECONDARY',
}

export interface TrackContributorsConnection {
	readonly __typename: 'TrackContributorsConnection';
	readonly edges: ReadonlyArray<TrackContributorsEdge>;
	readonly pageInfo: PageInfo;
}

export interface TrackContributorsEdge {
	readonly __typename: 'TrackContributorsEdge';
	readonly cursor: Scalars['String']['output'];
	readonly node: Contributor;
	readonly roles: ReadonlyArray<TrackContributorRoles>;
}

export interface TrackDiskInfo {
	readonly __typename: 'TrackDiskInfo';
	readonly diskNumber: Maybe<Scalars['Int']['output']>;
	readonly trackNumber: Maybe<Scalars['Int']['output']>;
}

/** Playback information of the track's associated media. This set of data is generally aimed for consumption by the audio players. */
export interface TrackMedia {
	readonly __typename: 'TrackMedia';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly bpm: Maybe<Scalars['Float']['output']>;
	/**
	 * Estimated sizes of the files for the available formats.
	 *
	 * These sizes are meant for estimation purpose the actual media size might differ.
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly estimatedSizes: TrackMediaSizes;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly gain: Maybe<Scalars['Float']['output']>;
	/**
	 * ID of the track's associated media
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly id: Scalars['String']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly rights: MediaRights;
	/**
	 * Media provider token used to acquire CDN URL of the media
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly token: TrackMediaToken;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly version: Scalars['String']['output'];
}

export interface TrackMediaSizes {
	readonly __typename: 'TrackMediaSizes';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly AAC_64: Maybe<Scalars['Int']['output']>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly AAC_96: Maybe<Scalars['Int']['output']>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly AC4_IMS: Maybe<Scalars['Int']['output']>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly DD_JOC: Maybe<Scalars['Int']['output']>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly FLAC: Maybe<Scalars['Int']['output']>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly MP3_32: Maybe<Scalars['Int']['output']>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly MP3_64: Maybe<Scalars['Int']['output']>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly MP3_128: Maybe<Scalars['Int']['output']>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly MP3_192: Maybe<Scalars['Int']['output']>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly MP3_256: Maybe<Scalars['Int']['output']>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly MP3_320: Maybe<Scalars['Int']['output']>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly MP3_MISC: Maybe<Scalars['Int']['output']>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly MP4_RA1: Maybe<Scalars['Int']['output']>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly MP4_RA2: Maybe<Scalars['Int']['output']>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly MP4_RA3: Maybe<Scalars['Int']['output']>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly SBC_256: Maybe<Scalars['Int']['output']>;
}

export interface TrackMediaToken {
	readonly __typename: 'TrackMediaToken';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly expiresAt: Scalars['Date']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly payload: Scalars['String']['output'];
}

export interface TrackMutationBanOut {
	readonly __typename: 'TrackMutationBanOut';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly bannedAt: Scalars['Date']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly status: Scalars['Boolean']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly track: Track;
}

export interface TrackMutationFavoriteAddOut {
	readonly __typename: 'TrackMutationFavoriteAddOut';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly favoritedAt: Scalars['Date']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly track: Track;
}

export interface TrackMutationFavoriteRemoveOut {
	readonly __typename: 'TrackMutationFavoriteRemoveOut';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly track: Track;
}

export interface TrackMutationUnbanOut {
	readonly __typename: 'TrackMutationUnbanOut';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly status: Scalars['Boolean']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly track: Track;
}

export interface TrackRelatedAlbumsConnection {
	readonly __typename: 'TrackRelatedAlbumsConnection';
	readonly edges: ReadonlyArray<TrackRelatedAlbumsEdge>;
	readonly pageInfo: PageInfo;
}

export interface TrackRelatedAlbumsEdge {
	readonly __typename: 'TrackRelatedAlbumsEdge';
	readonly cursor: Scalars['String']['output'];
	/**
	 * Can return error codes:
	 * * ERR_ALBUM_NOT_FOUND: Is returned when album doesn't exist
	 */
	readonly node: Maybe<Album>;
}

export interface TrackRelatedTracksConnection {
	readonly __typename: 'TrackRelatedTracksConnection';
	readonly edges: ReadonlyArray<TrackRelatedTracksEdge>;
	readonly pageInfo: PageInfo;
}

export interface TrackRelatedTracksEdge {
	readonly __typename: 'TrackRelatedTracksEdge';
	readonly cursor: Scalars['String']['output'];
	readonly node: Maybe<Track>;
}

export interface TrackShuffledCollectionConnection {
	readonly __typename: 'TrackShuffledCollectionConnection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly edges: ReadonlyArray<TrackShuffledCollectionConnectionEdge>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly pageInfo: PageInfo;
}

export interface TrackShuffledCollectionConnectionEdge {
	readonly __typename: 'TrackShuffledCollectionConnectionEdge';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly cursor: Scalars['String']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly node: Maybe<Track>;
}

export interface TrackUploadInfo {
	readonly __typename: 'TrackUploadInfo';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly uploadId: Scalars['String']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly userId: Scalars['String']['output'];
}

export interface TracksConnection {
	readonly __typename: 'TracksConnection';
	readonly edges: ReadonlyArray<TracksConnectionEdge>;
	readonly pageInfo: PageInfo;
}

export interface TracksConnectionEdge {
	readonly __typename: 'TracksConnectionEdge';
	readonly cursor: Scalars['String']['output'];
	readonly node: Maybe<Track>;
}

export interface TrendingQuery {
	readonly __typename: 'TrendingQuery';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly query: Maybe<Scalars['String']['output']>;
}

export interface UiAsset {
	readonly __typename: 'UIAsset';
	/** Stable identification (over time) for the asset itself */
	readonly id: Scalars['String']['output'];
	/** List of URLS to get the asset data. All urls will return the same image. Try them one by one in order until success */
	readonly urls: ReadonlyArray<Scalars['String']['output']>;
}

export interface UiAssetUrlsArgs {
	uiAssetRequest: UiAssetRequest;
}

export interface UiAssetRequest {
	/** In case of croped picture (not square) color of the background in hexa decimal like 000000, or FF00FF. If no background color provided, transparent background will be used */
	readonly backgroundColor: InputMaybe<Scalars['String']['input']>;
	/** Height of the picture requested. Max 1500 */
	readonly height: Scalars['Int']['input'];
	/** Asset quality of the picture between 0 and 100. Default is 80 */
	readonly quality: InputMaybe<Scalars['Int']['input']>;
	/** Width of the picture requested. Max 1500 */
	readonly width: Scalars['Int']['input'];
}

export interface Ucps {
	readonly __typename: 'Ucps';
	/**
	 * Access: This field is restricted to applications granted with specific access.
	 * @deprecated No more available
	 */
	readonly ucpsData: Maybe<UcpsData>;
}

export interface UcpsData {
	readonly __typename: 'UcpsData';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly isSubscribed: Scalars['Boolean']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly marketShare: Scalars['Int']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly month: Scalars['Date']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly shareToken: Maybe<Scalars['String']['output']>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly topArtists: Maybe<TopArtistUserShareConnection>;
}

export interface UcpsDataTopArtistsArgs {
	after: InputMaybe<Scalars['String']['input']>;
	cursor: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
}

export interface UnbookmarkPodcastEpisodeOutput {
	readonly __typename: 'UnbookmarkPodcastEpisodeOutput';
	/**
	 * Episode which was unbookmarked
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly episode: PodcastEpisode;
	/**
	 * Was the episode successfully unbookmarked
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly status: Scalars['Boolean']['output'];
}

export interface UploadRights {
	readonly __typename: 'UploadRights';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly available: Scalars['Boolean']['output'];
}

export interface Url extends DeezerUrl {
	readonly __typename: 'Url';
	readonly deepLink: Scalars['String']['output'];
	readonly webUrl: Scalars['String']['output'];
}

export interface User extends Node {
	readonly __typename: 'User';
	/**
	 * Artists for which the user has favorite content
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly artistsWithFavoritedContent: FavoriteContentArtistsConnection;
	/**
	 * User charts
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly charts: UserCharts;
	/**
	 * User's compatibility score with currently logged user
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly compatibilityScore: Maybe<Scalars['Int']['output']>;
	/**
	 * WARNING!!
	 * It returns fake data and should only be used for development at the moment.
	 *
	 * User's compatibility story with currently logged user
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly compatibilityStory: Maybe<SimpleStory>;
	/**
	 * Estimated number of albums favorited by the user; use for display only
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 * @deprecated Access this information through favorites field
	 */
	readonly estimatedFavoriteAlbumsCount: Scalars['Int']['output'];
	/**
	 * Estimated number of user's public playlists
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly estimatedPublicPlaylistCount: Scalars['Int']['output'];
	/**
	 * User favorite tracks playlist
	 *
	 * Can return error codes:
	 * * ERR_PLAYLIST_NOT_FOUND: Is returned when playlist doesn't exist
	 * * ERR_PLAYLIST_ACCESS_NOT_AUTHORIZED: Is returned when playlist access is not allowed
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly favoriteTracksPlaylist: Maybe<Playlist>;
	/**
	 * User's favorites aggregation
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly favorites: Favorites;
	/**
	 * User's flow (only available if User is the logged user)
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 * @deprecated Use PrivateUser.flow instead
	 */
	readonly flow: Maybe<Flow>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly id: Scalars['ID']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly name: Scalars['String']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly picture: Maybe<Picture>;
	/**
	 * Paginated list of all public playlists owned by the user
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly publicPlaylists: UserPublicPlaylistsConnection;
	/**
	 * User's shuffled track collection coming from several sources
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly trackShuffledCollection: Maybe<TrackShuffledCollectionConnection>;
}

export interface UserArtistsWithFavoritedContentArgs {
	after: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
}

export interface UserPublicPlaylistsArgs {
	after: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
	sort: InputMaybe<UserPublicPlaylistSortInput>;
}

export interface UserTrackShuffledCollectionArgs {
	after: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
}

export interface UserBannedArtistConnection {
	readonly __typename: 'UserBannedArtistConnection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly edges: ReadonlyArray<BannedArtistEdge>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly pageInfo: PageInfo;
}

export interface UserBannedTrackConnection {
	readonly __typename: 'UserBannedTrackConnection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly edges: ReadonlyArray<BannedTrackEdge>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly pageInfo: PageInfo;
}

export interface UserCharts {
	readonly __typename: 'UserCharts';
	/**
	 * Top user's streamed artists
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly albums: Maybe<UserChartsAlbumsConnection>;
	/**
	 * Top user's streamed artists
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly artists: Maybe<UserChartsArtistsConnection>;
	/**
	 * Top user's listened playlists
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly playlists: Maybe<UserChartsPlaylistsConnection>;
	/**
	 * Top user's streamed tracks
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly tracks: Maybe<UserChartsTracksConnection>;
}

export interface UserChartsAlbumsArgs {
	after: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
}

export interface UserChartsArtistsArgs {
	after: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
}

export interface UserChartsPlaylistsArgs {
	after: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
}

export interface UserChartsTracksArgs {
	after: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
}

export interface UserChartsAlbumsConnection {
	readonly __typename: 'UserChartsAlbumsConnection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly edges: ReadonlyArray<UserChartsAlbumsEdge>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly pageInfo: PageInfo;
}

export interface UserChartsAlbumsEdge {
	readonly __typename: 'UserChartsAlbumsEdge';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly cursor: Scalars['String']['output'];
	/**
	 * Can return error codes:
	 * * ERR_ALBUM_NOT_FOUND: Is returned when album doesn't exist
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly node: Maybe<Album>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly position: Scalars['Int']['output'];
}

export interface UserChartsArtistsConnection {
	readonly __typename: 'UserChartsArtistsConnection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly edges: ReadonlyArray<UserChartsArtistsEdge>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly pageInfo: PageInfo;
}

export interface UserChartsArtistsEdge {
	readonly __typename: 'UserChartsArtistsEdge';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly cursor: Scalars['String']['output'];
	/**
	 * Can return error codes:
	 * * ERR_ARTIST_NOT_FOUND: Is returned when artist doesn't exist
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly node: Maybe<Artist>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly position: Scalars['Int']['output'];
}

export interface UserChartsPlaylistsConnection {
	readonly __typename: 'UserChartsPlaylistsConnection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly edges: ReadonlyArray<UserChartsPlaylistsEdge>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly pageInfo: PageInfo;
}

export interface UserChartsPlaylistsEdge {
	readonly __typename: 'UserChartsPlaylistsEdge';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly cursor: Scalars['String']['output'];
	/**
	 * Can return error codes:
	 * * ERR_PLAYLIST_NOT_FOUND: Is returned when playlist doesn't exist
	 * * ERR_PLAYLIST_ACCESS_NOT_AUTHORIZED: Is returned when playlist access is not allowed
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly node: Maybe<Playlist>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly position: Scalars['Int']['output'];
}

export interface UserChartsTracksConnection {
	readonly __typename: 'UserChartsTracksConnection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly edges: ReadonlyArray<UserChartsTracksEdge>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly pageInfo: PageInfo;
}

export interface UserChartsTracksEdge {
	readonly __typename: 'UserChartsTracksEdge';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly cursor: Scalars['String']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly node: Maybe<Track>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly position: Scalars['Int']['output'];
}

export interface UserFavoriteAlbumConnection {
	readonly __typename: 'UserFavoriteAlbumConnection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly edges: ReadonlyArray<FavoriteAlbumEdge>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly pageInfo: PageInfo;
}

export interface UserFavoriteArtistConnection {
	readonly __typename: 'UserFavoriteArtistConnection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly edges: ReadonlyArray<FavoriteArtistEdge>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly pageInfo: PageInfo;
}

export interface UserFavoritePlaylistConnection {
	readonly __typename: 'UserFavoritePlaylistConnection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly edges: ReadonlyArray<FavoritePlaylistEdge>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly pageInfo: PageInfo;
}

export interface UserFavoritePodcastConnection {
	readonly __typename: 'UserFavoritePodcastConnection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly edges: ReadonlyArray<FavoritePodcastEdge>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly pageInfo: PageInfo;
}

export interface UserFavoriteTrackConnection {
	readonly __typename: 'UserFavoriteTrackConnection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly edges: ReadonlyArray<FavoriteTrackEdge>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly pageInfo: PageInfo;
}

export enum UserPublicPlaylistSortByInput {
	LAST_MODIFICATION_DATE = 'LAST_MODIFICATION_DATE',
	TITLE = 'TITLE',
}

export interface UserPublicPlaylistSortInput {
	readonly by: InputMaybe<UserPublicPlaylistSortByInput>;
	readonly order: InputMaybe<SortOrderInput>;
}

export interface UserPublicPlaylistsConnection {
	readonly __typename: 'UserPublicPlaylistsConnection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly edges: ReadonlyArray<UserPublicPlaylistsConnectionEdge>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly pageInfo: PageInfo;
}

export interface UserPublicPlaylistsConnectionEdge {
	readonly __typename: 'UserPublicPlaylistsConnectionEdge';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly cursor: Scalars['String']['output'];
	/**
	 * Can return error codes:
	 * * ERR_PLAYLIST_NOT_FOUND: Is returned when playlist doesn't exist
	 * * ERR_PLAYLIST_ACCESS_NOT_AUTHORIZED: Is returned when playlist access is not allowed
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly node: Maybe<Playlist>;
}

export interface UserSearchedQueriesHistoryMutationOutput {
	readonly __typename: 'UserSearchedQueriesHistoryMutationOutput';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly queries: Maybe<SearchHistoryQueriesConnection>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly success: Scalars['Boolean']['output'];
}

export interface UserSearchedQueriesHistoryMutationOutputQueriesArgs {
	after: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
}

export interface UsersConnection {
	readonly __typename: 'UsersConnection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly edges: ReadonlyArray<UsersConnectionEdge>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly pageInfo: PageInfo;
}

export interface UsersConnectionEdge {
	readonly __typename: 'UsersConnectionEdge';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly cursor: Scalars['String']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly node: Maybe<User>;
}

export interface ValidateOnboardingArtistsPickerStepInput {
	readonly artistIds: ReadonlyArray<Scalars['String']['input']>;
}

export interface ValidateOnboardingArtistsPickerStepOutput {
	readonly __typename: 'ValidateOnboardingArtistsPickerStepOutput';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly onboarding: Onboarding;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly status: Scalars['Boolean']['output'];
}

export interface ValidateOnboardingChooseNextStepStepOutput {
	readonly __typename: 'ValidateOnboardingChooseNextStepStepOutput';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly onboarding: Onboarding;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly status: Scalars['Boolean']['output'];
}

export interface ValidateOnboardingImportLibraryStepInput {
	readonly hasSkipped: Scalars['Boolean']['input'];
}

export interface ValidateOnboardingImportLibraryStepOutput {
	readonly __typename: 'ValidateOnboardingImportLibraryStepOutput';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly onboarding: Onboarding;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly status: Scalars['Boolean']['output'];
}

export interface Video {
	readonly __typename: 'Video';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly url: Scalars['String']['output'];
}

export type VideoContributor = Artist;

export interface VideoContributorsConnection {
	readonly __typename: 'VideoContributorsConnection';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly edges: ReadonlyArray<VideoContributorsEdge>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly pageInfo: PageInfo;
}

export interface VideoContributorsEdge {
	readonly __typename: 'VideoContributorsEdge';
	/** Access: This field is restricted to applications granted with specific access. */
	readonly cursor: Scalars['String']['output'];
	/** Access: This field is restricted to applications granted with specific access. */
	readonly node: Maybe<VideoContributor>;
}

export interface VideoStream extends Node {
	readonly __typename: 'VideoStream';
	/**
	 * Contributors on the videos, may be non artists contributors
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly contributors: VideoContributorsConnection;
	/**
	 * External URL used to display the video
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly externalUrl: Maybe<ExternalUrl>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly id: Scalars['ID']['output'];
	/**
	 * Asset picture of the video stream
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly image: Maybe<Picture>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly subtitle: Maybe<Scalars['String']['output']>;
	/** Access: This field is restricted to applications granted with specific access. */
	readonly title: Maybe<Scalars['String']['output']>;
	/**
	 * Type of video stream
	 *
	 * Access: This field is restricted to applications granted with specific access.
	 */
	readonly type: VideoStreamType;
}

export interface VideoStreamContributorsArgs {
	after: InputMaybe<Scalars['String']['input']>;
	cursor: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
}

export enum VideoStreamType {
	DEEZER_SESSION = 'DEEZER_SESSION',
	DOCUMENTARY = 'DOCUMENTARY',
	INTERVIEW = 'INTERVIEW',
	REPLAY = 'REPLAY',
	TRAILER = 'TRAILER',
}

export type AlbumFragment = {
	readonly __typename: 'Album';
	readonly id: string;
	readonly displayTitle: string;
	readonly cover: {
		readonly __typename: 'Picture';
		readonly urls: ReadonlyArray<string>;
	} | null;
};

export type ArtistFragment = {
	readonly __typename: 'Artist';
	readonly id: string;
	readonly name: string;
	readonly picture: {
		readonly __typename: 'Picture';
		readonly medium: ReadonlyArray<string>;
	} | null;
};

export type CuratedPlaylistFragment = {
	readonly __typename: 'Playlist';
	readonly id: string;
	readonly rawTracks: ReadonlyArray<{
		readonly __typename: 'PlaylistRawTrack';
		readonly trackId: string;
	}>;
};

export type GroupFragment = {
	readonly __typename: 'MusicTogetherGroup';
	readonly id: string;
	readonly name: string;
	readonly isFamily: boolean;
	readonly members: {
		readonly __typename: 'MusicTogetherGroupMembersConnection';
		readonly edges: ReadonlyArray<{
			readonly __typename: 'MusicTogetherGroupMembersConnectionEdge';
			readonly node: {
				readonly __typename: 'MusicTogetherGroupMember';
				readonly id: string;
				readonly name: string;
				readonly picture: {
					readonly __typename: 'Picture';
					readonly medium: ReadonlyArray<string>;
				} | null;
			} | null;
		}>;
	};
};

export type MemberFragment = {
	readonly __typename: 'MusicTogetherGroupMember';
	readonly id: string;
	readonly name: string;
	readonly picture: {
		readonly __typename: 'Picture';
		readonly medium: ReadonlyArray<string>;
	} | null;
};

export type PlaylistFragment = {
	readonly __typename: 'Playlist';
	readonly id: string;
	readonly tracks: {
		readonly __typename: 'PlaylistTracksConnection';
		readonly edges: ReadonlyArray<{
			readonly __typename: 'PlaylistTracksEdge';
			readonly node: {readonly __typename: 'Track'; readonly id: string} | null;
		}>;
	};
};

export type TrackFragment = {
	readonly __typename: 'Track';
	readonly id: string;
	readonly title: string;
	readonly ISRC: string | null;
	readonly isExplicit: boolean;
	readonly album: {
		readonly __typename: 'Album';
		readonly id: string;
		readonly displayTitle: string;
		readonly cover: {
			readonly __typename: 'Picture';
			readonly urls: ReadonlyArray<string>;
		} | null;
	} | null;
	readonly contributorDisplayNames: {
		readonly __typename: 'TrackContributorDisplayNameConnection';
		readonly edges: ReadonlyArray<{
			readonly __typename: 'TrackContributorDisplayNameEdge';
			readonly node: string | null;
		}>;
	};
};

export type GroupCreateMutationVariables = Exact<{[key: string]: never}>;

export type GroupCreateMutation = {
	readonly __typename: 'Mutation';
	readonly musicTogetherCreateGroup:
		| {
				readonly __typename: 'MusicTogetherCreateGroupError';
				readonly hasReachedGroupsLimit: boolean | null;
		  }
		| {
				readonly __typename: 'MusicTogetherCreateGroupOutput';
				readonly group: {
					readonly __typename: 'MusicTogetherGroup';
					readonly id: string;
					readonly name: string;
					readonly isFamily: boolean;
					readonly members: {
						readonly __typename: 'MusicTogetherGroupMembersConnection';
						readonly edges: ReadonlyArray<{
							readonly __typename: 'MusicTogetherGroupMembersConnectionEdge';
							readonly node: {
								readonly __typename: 'MusicTogetherGroupMember';
								readonly id: string;
								readonly name: string;
								readonly picture: {
									readonly __typename: 'Picture';
									readonly medium: ReadonlyArray<string>;
								} | null;
							} | null;
						}>;
					};
				};
		  };
};

export type GroupJoinMutationVariables = Exact<{
	input: MusicTogetherJoinGroupInput;
}>;

export type GroupJoinMutation = {
	readonly __typename: 'Mutation';
	readonly musicTogetherJoinGroup:
		| {
				readonly __typename: 'MusicTogetherJoinGroupError';
				readonly isGroupFull: boolean | null;
		  }
		| {
				readonly __typename: 'MusicTogetherJoinGroupOutput';
				readonly group: {
					readonly __typename: 'MusicTogetherGroup';
					readonly id: string;
					readonly name: string;
					readonly isFamily: boolean;
					readonly suggestedTracklist: {
						readonly __typename: 'MusicTogetherGroupSuggestedTracklist';
						readonly isRefreshing: boolean;
						readonly tracklist: {
							readonly __typename: 'QueueChunk';
							readonly id: string;
						} | null;
					} | null;
					readonly members: {
						readonly __typename: 'MusicTogetherGroupMembersConnection';
						readonly edges: ReadonlyArray<{
							readonly __typename: 'MusicTogetherGroupMembersConnectionEdge';
							readonly node: {
								readonly __typename: 'MusicTogetherGroupMember';
								readonly id: string;
								readonly name: string;
								readonly picture: {
									readonly __typename: 'Picture';
									readonly medium: ReadonlyArray<string>;
								} | null;
							} | null;
						}>;
					};
				};
		  };
};

export type GroupLeaveMutationVariables = Exact<{
	input: MusicTogetherLeaveGroupInput;
}>;

export type GroupLeaveMutation = {
	readonly __typename: 'Mutation';
	readonly musicTogetherLeaveGroup:
		| {
				readonly __typename: 'MusicTogetherLeaveGroupError';
				readonly userNotInGroup: boolean | null;
		  }
		| {
				readonly __typename: 'MusicTogetherLeaveGroupOutput';
				readonly group: {
					readonly __typename: 'MusicTogetherGroup';
					readonly id: string;
					readonly name: string;
					readonly members: {
						readonly __typename: 'MusicTogetherGroupMembersConnection';
						readonly edges: ReadonlyArray<{
							readonly __typename: 'MusicTogetherGroupMembersConnectionEdge';
							readonly node: {
								readonly __typename: 'MusicTogetherGroupMember';
								readonly id: string;
								readonly name: string;
								readonly picture: {
									readonly __typename: 'Picture';
									readonly medium: ReadonlyArray<string>;
								} | null;
							} | null;
						}>;
					};
				} | null;
		  };
};

export type GroupRefreshMutationVariables = Exact<{
	input: MusicTogetherRefreshSuggestedTracklistInput;
}>;

export type GroupRefreshMutation = {
	readonly __typename: 'Mutation';
	readonly musicTogetherRefreshSuggestedTracklist:
		| {
				readonly __typename: 'MusicTogetherRefreshSuggestedTracklistError';
				readonly userNotInGroup: boolean | null;
		  }
		| {
				readonly __typename: 'MusicTogetherRefreshSuggestedTracklistOutput';
				readonly group: {
					readonly __typename: 'MusicTogetherGroup';
					readonly id: string;
					readonly name: string;
					readonly isFamily: boolean;
					readonly suggestedTracklist: {
						readonly __typename: 'MusicTogetherGroupSuggestedTracklist';
						readonly isRefreshing: boolean;
						readonly tracklist: {
							readonly __typename: 'QueueChunk';
							readonly id: string;
						} | null;
					} | null;
					readonly members: {
						readonly __typename: 'MusicTogetherGroupMembersConnection';
						readonly edges: ReadonlyArray<{
							readonly __typename: 'MusicTogetherGroupMembersConnectionEdge';
							readonly node: {
								readonly __typename: 'MusicTogetherGroupMember';
								readonly id: string;
								readonly name: string;
								readonly picture: {
									readonly __typename: 'Picture';
									readonly medium: ReadonlyArray<string>;
								} | null;
							} | null;
						}>;
					};
				};
		  };
};

export type UpdateGroupSettingsMutationVariables = Exact<{
	input: MusicTogetherUpdateGroupSettingsInput;
}>;

export type UpdateGroupSettingsMutation = {
	readonly __typename: 'Mutation';
	readonly musicTogetherUpdateGroupSettings:
		| {
				readonly __typename: 'MusicTogetherUpdateGroupSettingsError';
				readonly userNotInGroup: boolean | null;
				readonly isInvalidGroupName: boolean | null;
		  }
		| {
				readonly __typename: 'MusicTogetherUpdateGroupSettingsOutput';
				readonly group: {
					readonly __typename: 'MusicTogetherGroup';
					readonly id: string;
					readonly name: string;
					readonly isFamily: boolean;
					readonly members: {
						readonly __typename: 'MusicTogetherGroupMembersConnection';
						readonly edges: ReadonlyArray<{
							readonly __typename: 'MusicTogetherGroupMembersConnectionEdge';
							readonly node: {
								readonly __typename: 'MusicTogetherGroupMember';
								readonly id: string;
								readonly name: string;
								readonly picture: {
									readonly __typename: 'Picture';
									readonly medium: ReadonlyArray<string>;
								} | null;
							} | null;
						}>;
					};
				};
		  };
};

export type ValidateOnboardingArtistsPickerStepMutationVariables = Exact<{
	context?: OnboardingContextInput;
	input: ValidateOnboardingArtistsPickerStepInput;
}>;

export type ValidateOnboardingArtistsPickerStepMutation = {
	readonly __typename: 'Mutation';
	readonly validateOnboardingArtistsPickerStep: {
		readonly __typename: 'ValidateOnboardingArtistsPickerStepOutput';
		readonly onboarding: {
			readonly __typename: 'Onboarding';
			readonly shouldBeOnboarded: boolean;
		};
	};
};

export type ValidateOnboardingChooseNextStepStepMutationVariables = Exact<{
	context?: OnboardingContextInput;
}>;

export type ValidateOnboardingChooseNextStepStepMutation = {
	readonly __typename: 'Mutation';
	readonly validateOnboardingChooseNextStepStep: {
		readonly __typename: 'ValidateOnboardingChooseNextStepStepOutput';
		readonly onboarding: {
			readonly __typename: 'Onboarding';
			readonly shouldBeOnboarded: boolean;
		};
	};
};

export type ValidateOnboardingImportLibraryStepMutationVariables = Exact<{
	context?: OnboardingContextInput;
	input: ValidateOnboardingImportLibraryStepInput;
}>;

export type ValidateOnboardingImportLibraryStepMutation = {
	readonly __typename: 'Mutation';
	readonly validateOnboardingImportLibraryStep: {
		readonly __typename: 'ValidateOnboardingImportLibraryStepOutput';
		readonly onboarding: {
			readonly __typename: 'Onboarding';
			readonly shouldBeOnboarded: boolean;
		};
	};
};

export type AppendTrackToPlaylistMutationVariables = Exact<{
	input: PlaylistAppendTracksMutationInput;
}>;

export type AppendTrackToPlaylistMutation = {
	readonly __typename: 'Mutation';
	readonly appendTracksToPlaylist: {
		readonly __typename: 'PlaylistAppendTracksMutationOutput';
		readonly addedTrackIds: ReadonlyArray<string>;
	};
};

export type RemoveTracksFromPlaylistMutationVariables = Exact<{
	input: PlaylistRemoveTracksMutationInput;
}>;

export type RemoveTracksFromPlaylistMutation = {
	readonly __typename: 'Mutation';
	readonly removeTracksFromPlaylist: {
		readonly __typename: 'PlaylistRemoveTracksMutationOutput';
		readonly removedTrackIds: ReadonlyArray<string>;
	};
};

export type ArtistsPickerQueryVariables = Exact<{
	endCursor: InputMaybe<Scalars['String']['input']>;
	first: Scalars['Int']['input'];
}>;

export type ArtistsPickerQuery = {
	readonly __typename: 'Query';
	readonly onboardingSteps: {
		readonly __typename: 'OnboardingSteps';
		readonly artistsPicker: {
			readonly __typename: 'ArtistsPickerStep';
			readonly artists: {
				readonly __typename: 'OnboardingArtistsConnection';
				readonly pageInfo: {
					readonly __typename: 'PageInfo';
					readonly hasNextPage: boolean;
					readonly endCursor: string | null;
				};
				readonly edges: ReadonlyArray<{
					readonly __typename: 'OnboardingArtistsEdge';
					readonly node: {
						readonly __typename: 'Artist';
						readonly id: string;
						readonly name: string;
						readonly picture: {
							readonly __typename: 'Picture';
							readonly medium: ReadonlyArray<string>;
						} | null;
					} | null;
				}>;
			};
		};
	} | null;
};

export type GatekeepsQueryVariables = Exact<{
	names: ReadonlyArray<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type GatekeepsQuery = {
	readonly __typename: 'Query';
	readonly gatekeeps: {
		readonly __typename: 'Gatekeeper';
		readonly gatekeeps: ReadonlyArray<{
			readonly __typename: 'Gatekeep';
			readonly name: string;
			readonly isOpen: boolean;
		}>;
	};
};

export type GroupAffinityQueryVariables = Exact<{
	musicTogetherGroupId: Scalars['ID']['input'];
	memberId: Scalars['ID']['input'];
}>;

export type GroupAffinityQuery = {
	readonly __typename: 'Query';
	readonly musicTogetherAffinity: {
		readonly __typename: 'MusicTogetherAffinity';
		readonly compatibilityScore: number;
		readonly discoveryTracks: {
			readonly __typename: 'MusicTogetherDiscoveryTracksConnection';
			readonly edges: ReadonlyArray<{
				readonly __typename: 'MusicTogetherDiscoveryTracksConnectionEdge';
				readonly node: {
					readonly __typename: 'Track';
					readonly id: string;
					readonly title: string;
					readonly ISRC: string | null;
					readonly isExplicit: boolean;
					readonly album: {
						readonly __typename: 'Album';
						readonly id: string;
						readonly displayTitle: string;
						readonly cover: {
							readonly __typename: 'Picture';
							readonly urls: ReadonlyArray<string>;
						} | null;
					} | null;
					readonly contributorDisplayNames: {
						readonly __typename: 'TrackContributorDisplayNameConnection';
						readonly edges: ReadonlyArray<{
							readonly __typename: 'TrackContributorDisplayNameEdge';
							readonly node: string | null;
						}>;
					};
				} | null;
			}>;
		};
		readonly discoveryArtists: {
			readonly __typename: 'MusicTogetherDiscoveryArtistsConnection';
			readonly edges: ReadonlyArray<{
				readonly __typename: 'MusicTogetherDiscoveryArtistsConnectionEdge';
				readonly node: {
					readonly __typename: 'Artist';
					readonly id: string;
					readonly name: string;
					readonly picture: {
						readonly __typename: 'Picture';
						readonly medium: ReadonlyArray<string>;
					} | null;
				} | null;
			}>;
		};
	} | null;
};

export type GroupCuratedRawQueryVariables = Exact<{
	musicTogetherGroupId: Scalars['ID']['input'];
}>;

export type GroupCuratedRawQuery = {
	readonly __typename: 'Query';
	readonly musicTogetherGroup: {
		readonly __typename: 'MusicTogetherGroup';
		readonly id: string;
		readonly name: string;
		readonly curatedTracklist: {
			readonly __typename: 'Playlist';
			readonly id: string;
			readonly rawTracks: ReadonlyArray<{
				readonly __typename: 'PlaylistRawTrack';
				readonly trackId: string;
			}>;
		} | null;
	} | null;
};

export type GroupCuratedTracklistQueryVariables = Exact<{
	musicTogetherGroupId: Scalars['ID']['input'];
	endCursor: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GroupCuratedTracklistQuery = {
	readonly __typename: 'Query';
	readonly musicTogetherGroup: {
		readonly __typename: 'MusicTogetherGroup';
		readonly curatedTracklist: {
			readonly __typename: 'Playlist';
			readonly id: string;
			readonly tracks: {
				readonly __typename: 'PlaylistTracksConnection';
				readonly pageInfo: {
					readonly __typename: 'PageInfo';
					readonly hasNextPage: boolean;
					readonly endCursor: string | null;
				};
				readonly edges: ReadonlyArray<{
					readonly __typename: 'PlaylistTracksEdge';
					readonly node: {
						readonly __typename: 'Track';
						readonly id: string;
						readonly title: string;
						readonly ISRC: string | null;
						readonly isExplicit: boolean;
						readonly album: {
							readonly __typename: 'Album';
							readonly id: string;
							readonly displayTitle: string;
							readonly cover: {
								readonly __typename: 'Picture';
								readonly urls: ReadonlyArray<string>;
							} | null;
						} | null;
						readonly contributorDisplayNames: {
							readonly __typename: 'TrackContributorDisplayNameConnection';
							readonly edges: ReadonlyArray<{
								readonly __typename: 'TrackContributorDisplayNameEdge';
								readonly node: string | null;
							}>;
						};
					} | null;
					readonly actor: {
						readonly __typename: 'PlaylistActor';
						readonly id: string;
						readonly name: string;
						readonly picture: {
							readonly __typename: 'Picture';
							readonly medium: ReadonlyArray<string>;
						} | null;
					} | null;
				}>;
			};
		} | null;
	} | null;
};

export type GroupInfoQueryVariables = Exact<{
	musicTogetherGroupId: Scalars['ID']['input'];
}>;

export type GroupInfoQuery = {
	readonly __typename: 'Query';
	readonly musicTogetherGroup: {
		readonly __typename: 'MusicTogetherGroup';
		readonly id: string;
		readonly name: string;
		readonly isReady: boolean;
		readonly isFamily: boolean;
		readonly members: {
			readonly __typename: 'MusicTogetherGroupMembersConnection';
			readonly edges: ReadonlyArray<{
				readonly __typename: 'MusicTogetherGroupMembersConnectionEdge';
				readonly affinity: {
					readonly __typename: 'MusicTogetherAffinity';
					readonly compatibilityScore: number;
				} | null;
				readonly node: {
					readonly __typename: 'MusicTogetherGroupMember';
					readonly id: string;
					readonly name: string;
					readonly picture: {
						readonly __typename: 'Picture';
						readonly medium: ReadonlyArray<string>;
					} | null;
				} | null;
			}>;
		};
		readonly affinitiesStory: {
			readonly __typename: 'SimpleStory';
			readonly storyId: string;
			readonly placeholders: ReadonlyArray<{
				readonly __typename: 'KeyValue';
				readonly key: string;
				readonly value: string;
			}>;
		} | null;
	} | null;
};

export type GroupSuggestionTracklistQueryVariables = Exact<{
	musicTogetherGroupId: Scalars['ID']['input'];
	endCursor: InputMaybe<Scalars['String']['input']>;
	first?: InputMaybe<Scalars['Int']['input']>;
	mood?: MusicTogetherSuggestedTracklistMoodInput;
}>;

export type GroupSuggestionTracklistQuery = {
	readonly __typename: 'Query';
	readonly musicTogetherGroup: {
		readonly __typename: 'MusicTogetherGroup';
		readonly suggestedTracklist: {
			readonly __typename: 'MusicTogetherGroupSuggestedTracklist';
			readonly isRefreshing: boolean;
			readonly tracklist: {
				readonly __typename: 'QueueChunk';
				readonly id: string;
				readonly tracks: {
					readonly __typename: 'QueueTracksConnection';
					readonly pageInfo: {
						readonly __typename: 'PageInfo';
						readonly hasNextPage: boolean;
						readonly endCursor: string | null;
					};
					readonly edges: ReadonlyArray<{
						readonly __typename: 'QueueTracksEdge';
						readonly node: {
							readonly __typename: 'Track';
							readonly id: string;
							readonly title: string;
							readonly ISRC: string | null;
							readonly isExplicit: boolean;
							readonly album: {
								readonly __typename: 'Album';
								readonly id: string;
								readonly displayTitle: string;
								readonly cover: {
									readonly __typename: 'Picture';
									readonly urls: ReadonlyArray<string>;
								} | null;
							} | null;
							readonly contributorDisplayNames: {
								readonly __typename: 'TrackContributorDisplayNameConnection';
								readonly edges: ReadonlyArray<{
									readonly __typename: 'TrackContributorDisplayNameEdge';
									readonly node: string | null;
								}>;
							};
						} | null;
						readonly metadata: {
							readonly __typename: 'QueueTrackMetadata';
							readonly musicTogether: {
								readonly __typename: 'MusicTogetherTrackMetadata';
								readonly trackOrigin: ReadonlyArray<{
									readonly __typename: 'MusicTogetherTrackOrigin';
									readonly pickedFromFavoriteTracks: boolean;
									readonly streamedPastYear: boolean;
									readonly recentlyStreamedOnRepeat: boolean;
									readonly pickedFromFavoriteArtists: boolean;
									readonly member: {
										readonly __typename: 'MusicTogetherGroupMember';
										readonly id: string;
										readonly name: string;
										readonly picture: {
											readonly __typename: 'Picture';
											readonly medium: ReadonlyArray<string>;
										} | null;
									};
								} | null> | null;
							} | null;
						} | null;
					}>;
				};
			} | null;
		} | null;
	} | null;
};

export type MeQueryVariables = Exact<{[key: string]: never}>;

export type MeQuery = {
	readonly __typename: 'Query';
	readonly me: {
		readonly __typename: 'PrivateUser';
		readonly user: {
			readonly __typename: 'User';
			readonly id: string;
			readonly name: string;
			readonly picture: {
				readonly __typename: 'Picture';
				readonly medium: ReadonlyArray<string>;
			} | null;
		};
		readonly mediaServiceLicenseToken: {
			readonly __typename: 'MediaServiceLicenseTokenPackage';
			readonly token: string | null;
			readonly expirationDate: Date | null;
		} | null;
		readonly onboarding: {
			readonly __typename: 'Onboarding';
			readonly shouldBeOnboarded: boolean;
			readonly currentStep: string | null;
		};
	} | null;
	readonly onboardingSteps: {
		readonly __typename: 'OnboardingSteps';
		readonly chooseNextStep: {
			readonly __typename: 'ChooseNextStepStep';
			readonly steps: ReadonlyArray<string>;
		};
	} | null;
};

export type MeGroupsQueryVariables = Exact<{
	first: Scalars['Int']['input'];
}>;

export type MeGroupsQuery = {
	readonly __typename: 'Query';
	readonly me: {
		readonly __typename: 'PrivateUser';
		readonly musicTogetherGroups: {
			readonly __typename: 'PrivateUserMusicTogetherGroupsConnection';
			readonly edges: ReadonlyArray<{
				readonly __typename: 'PrivateUserMusicTogetherGroupsConnectionEdge';
				readonly node: {
					readonly __typename: 'MusicTogetherGroup';
					readonly id: string;
					readonly name: string;
					readonly isFamily: boolean;
					readonly members: {
						readonly __typename: 'MusicTogetherGroupMembersConnection';
						readonly edges: ReadonlyArray<{
							readonly __typename: 'MusicTogetherGroupMembersConnectionEdge';
							readonly node: {
								readonly __typename: 'MusicTogetherGroupMember';
								readonly id: string;
								readonly name: string;
								readonly picture: {
									readonly __typename: 'Picture';
									readonly medium: ReadonlyArray<string>;
								} | null;
							} | null;
						}>;
					};
				} | null;
			}>;
		};
	} | null;
};

export type RelatedArtistsQueryVariables = Exact<{
	endCursor: InputMaybe<Scalars['String']['input']>;
	first: Scalars['Int']['input'];
	artistId: Scalars['String']['input'];
	excludedArtistIds: InputMaybe<
		ReadonlyArray<Scalars['String']['input']> | Scalars['String']['input']
	>;
}>;

export type RelatedArtistsQuery = {
	readonly __typename: 'Query';
	readonly onboardingSteps: {
		readonly __typename: 'OnboardingSteps';
		readonly artistsPicker: {
			readonly __typename: 'ArtistsPickerStep';
			readonly relatedArtists: {
				readonly __typename: 'OnboardingRelatedArtistsConnection';
				readonly pageInfo: {
					readonly __typename: 'PageInfo';
					readonly hasNextPage: boolean;
					readonly endCursor: string | null;
				};
				readonly edges: ReadonlyArray<{
					readonly __typename: 'OnboardingRelatedArtistsEdge';
					readonly node: {
						readonly __typename: 'Artist';
						readonly id: string;
						readonly name: string;
						readonly picture: {
							readonly __typename: 'Picture';
							readonly medium: ReadonlyArray<string>;
						} | null;
					} | null;
				}>;
			};
		};
	} | null;
};

export type SearchArtistsQueryVariables = Exact<{
	query: Scalars['String']['input'];
}>;

export type SearchArtistsQuery = {
	readonly __typename: 'Query';
	readonly search: {
		readonly __typename: 'Search';
		readonly results: {
			readonly __typename: 'SearchResults';
			readonly artists: {
				readonly __typename: 'SearchArtistsConnection';
				readonly edges: ReadonlyArray<{
					readonly __typename: 'SearchArtistsConnectionEdge';
					readonly node: {
						readonly __typename: 'Artist';
						readonly id: string;
						readonly name: string;
						readonly picture: {
							readonly __typename: 'Picture';
							readonly medium: ReadonlyArray<string>;
						} | null;
					} | null;
				}>;
			};
		};
	} | null;
};

export type SearchTracksQueryVariables = Exact<{
	query: Scalars['String']['input'];
}>;

export type SearchTracksQuery = {
	readonly __typename: 'Query';
	readonly search: {
		readonly __typename: 'Search';
		readonly results: {
			readonly __typename: 'SearchResults';
			readonly tracks: {
				readonly __typename: 'SearchTracksConnection';
				readonly edges: ReadonlyArray<{
					readonly __typename: 'SearchTracksConnectionEdge';
					readonly node: {
						readonly __typename: 'Track';
						readonly id: string;
						readonly title: string;
						readonly ISRC: string | null;
						readonly isExplicit: boolean;
						readonly album: {
							readonly __typename: 'Album';
							readonly id: string;
							readonly displayTitle: string;
							readonly cover: {
								readonly __typename: 'Picture';
								readonly urls: ReadonlyArray<string>;
							} | null;
						} | null;
						readonly contributorDisplayNames: {
							readonly __typename: 'TrackContributorDisplayNameConnection';
							readonly edges: ReadonlyArray<{
								readonly __typename: 'TrackContributorDisplayNameEdge';
								readonly node: string | null;
							}>;
						};
					} | null;
				}>;
			};
		};
	} | null;
};

export type TokensQueryVariables = Exact<{[key: string]: never}>;

export type TokensQuery = {
	readonly __typename: 'Query';
	readonly tokens: {
		readonly __typename: 'Tokens';
		readonly mediaServiceLicenseToken: {
			readonly __typename: 'MediaServiceLicenseTokenPackage';
			readonly token: string | null;
			readonly expirationDate: Date | null;
		} | null;
		readonly recToken: {
			readonly __typename: 'RECTokenPackage';
			readonly token: string | null;
			readonly expirationDate: Date | null;
		} | null;
	};
};

export type PlaylistQueryVariables = Exact<{
	playlistId: Scalars['String']['input'];
}>;

export type PlaylistQuery = {
	readonly __typename: 'Query';
	readonly playlist: {
		readonly __typename: 'Playlist';
		readonly tracks: {
			readonly __typename: 'PlaylistTracksConnection';
			readonly edges: ReadonlyArray<{
				readonly __typename: 'PlaylistTracksEdge';
				readonly node: {
					readonly __typename: 'Track';
					readonly id: string;
					readonly title: string;
					readonly ISRC: string | null;
					readonly isExplicit: boolean;
					readonly album: {
						readonly __typename: 'Album';
						readonly id: string;
						readonly displayTitle: string;
						readonly cover: {
							readonly __typename: 'Picture';
							readonly urls: ReadonlyArray<string>;
						} | null;
					} | null;
					readonly contributorDisplayNames: {
						readonly __typename: 'TrackContributorDisplayNameConnection';
						readonly edges: ReadonlyArray<{
							readonly __typename: 'TrackContributorDisplayNameEdge';
							readonly node: string | null;
						}>;
					};
				} | null;
			}>;
		};
	} | null;
};

export type TrackQueryVariables = Exact<{
	trackId: Scalars['String']['input'];
}>;

export type TrackQuery = {
	readonly __typename: 'Query';
	readonly track: {
		readonly __typename: 'Track';
		readonly id: string;
		readonly title: string;
		readonly ISRC: string | null;
		readonly isExplicit: boolean;
		readonly media: {
			readonly __typename: 'TrackMedia';
			readonly token: {
				readonly __typename: 'TrackMediaToken';
				readonly payload: string;
				readonly expiresAt: Date;
			};
		} | null;
		readonly album: {
			readonly __typename: 'Album';
			readonly id: string;
			readonly displayTitle: string;
			readonly cover: {
				readonly __typename: 'Picture';
				readonly urls: ReadonlyArray<string>;
			} | null;
		} | null;
		readonly contributorDisplayNames: {
			readonly __typename: 'TrackContributorDisplayNameConnection';
			readonly edges: ReadonlyArray<{
				readonly __typename: 'TrackContributorDisplayNameEdge';
				readonly node: string | null;
			}>;
		};
	} | null;
};

export const ArtistFragmentDoc = gql`
	fragment Artist on Artist {
		id
		name
		picture {
			medium: urls(pictureRequest: {height: 200, width: 200})
		}
	}
`;
export const CuratedPlaylistFragmentDoc = gql`
	fragment CuratedPlaylist on Playlist {
		id
		rawTracks {
			trackId
		}
	}
`;
export const MemberFragmentDoc = gql`
	fragment Member on MusicTogetherGroupMember {
		id
		name
		picture {
			medium: urls(pictureRequest: {height: 200, width: 200})
		}
	}
`;
export const GroupFragmentDoc = gql`
	fragment Group on MusicTogetherGroup {
		id
		name
		isFamily
		members {
			edges {
				node {
					...Member
				}
			}
		}
	}
	${MemberFragmentDoc}
`;
export const PlaylistFragmentDoc = gql`
	fragment Playlist on Playlist {
		id
		tracks(first: 50, after: null) {
			edges {
				node {
					id
				}
			}
		}
	}
`;
export const AlbumFragmentDoc = gql`
	fragment Album on Album {
		id
		displayTitle
		cover(pictureRequest: {width: 264, height: 264}) {
			urls(pictureRequest: {width: 264, height: 264})
		}
	}
`;
export const TrackFragmentDoc = gql`
	fragment Track on Track {
		id
		title
		ISRC
		album {
			...Album
		}
		isExplicit
		contributorDisplayNames(first: 1, roles: [MAIN]) {
			edges {
				node
			}
		}
	}
	${AlbumFragmentDoc}
`;
export const GroupCreateDocument = gql`
	mutation GroupCreate {
		musicTogetherCreateGroup {
			... on MusicTogetherCreateGroupOutput {
				group {
					...Group
				}
			}
			... on MusicTogetherCreateGroupError {
				hasReachedGroupsLimit
			}
		}
	}
	${GroupFragmentDoc}
`;
export type GroupCreateMutationFn = ApolloReactCommon.MutationFunction<
	GroupCreateMutation,
	GroupCreateMutationVariables
>;

/**
 * __useGroupCreateMutation__
 *
 * To run a mutation, you first call `useGroupCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGroupCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [groupCreateMutation, { data, loading, error }] = useGroupCreateMutation({
 *   variables: {
 *   },
 * });
 */
export function useGroupCreateMutation(
	baseOptions?: ApolloReactHooks.MutationHookOptions<
		GroupCreateMutation,
		GroupCreateMutationVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return ApolloReactHooks.useMutation<
		GroupCreateMutation,
		GroupCreateMutationVariables
	>(GroupCreateDocument, options);
}
export type GroupCreateMutationHookResult = ReturnType<
	typeof useGroupCreateMutation
>;
export type GroupCreateMutationResult =
	ApolloReactCommon.MutationResult<GroupCreateMutation>;
export type GroupCreateMutationOptions = ApolloReactCommon.BaseMutationOptions<
	GroupCreateMutation,
	GroupCreateMutationVariables
>;
export const GroupJoinDocument = gql`
	mutation GroupJoin($input: MusicTogetherJoinGroupInput!) {
		musicTogetherJoinGroup(input: $input) {
			... on MusicTogetherJoinGroupOutput {
				group {
					suggestedTracklist {
						isRefreshing
						tracklist {
							id
						}
					}
					...Group
				}
			}
			... on MusicTogetherJoinGroupError {
				isGroupFull
			}
		}
	}
	${GroupFragmentDoc}
`;
export type GroupJoinMutationFn = ApolloReactCommon.MutationFunction<
	GroupJoinMutation,
	GroupJoinMutationVariables
>;

/**
 * __useGroupJoinMutation__
 *
 * To run a mutation, you first call `useGroupJoinMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGroupJoinMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [groupJoinMutation, { data, loading, error }] = useGroupJoinMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGroupJoinMutation(
	baseOptions?: ApolloReactHooks.MutationHookOptions<
		GroupJoinMutation,
		GroupJoinMutationVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return ApolloReactHooks.useMutation<
		GroupJoinMutation,
		GroupJoinMutationVariables
	>(GroupJoinDocument, options);
}
export type GroupJoinMutationHookResult = ReturnType<
	typeof useGroupJoinMutation
>;
export type GroupJoinMutationResult =
	ApolloReactCommon.MutationResult<GroupJoinMutation>;
export type GroupJoinMutationOptions = ApolloReactCommon.BaseMutationOptions<
	GroupJoinMutation,
	GroupJoinMutationVariables
>;
export const GroupLeaveDocument = gql`
	mutation GroupLeave($input: MusicTogetherLeaveGroupInput!) {
		musicTogetherLeaveGroup(input: $input) {
			... on MusicTogetherLeaveGroupOutput {
				group {
					id
					name
					members {
						edges {
							node {
								...Member
							}
						}
					}
				}
			}
			... on MusicTogetherLeaveGroupError {
				userNotInGroup
			}
		}
	}
	${MemberFragmentDoc}
`;
export type GroupLeaveMutationFn = ApolloReactCommon.MutationFunction<
	GroupLeaveMutation,
	GroupLeaveMutationVariables
>;

/**
 * __useGroupLeaveMutation__
 *
 * To run a mutation, you first call `useGroupLeaveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGroupLeaveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [groupLeaveMutation, { data, loading, error }] = useGroupLeaveMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGroupLeaveMutation(
	baseOptions?: ApolloReactHooks.MutationHookOptions<
		GroupLeaveMutation,
		GroupLeaveMutationVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return ApolloReactHooks.useMutation<
		GroupLeaveMutation,
		GroupLeaveMutationVariables
	>(GroupLeaveDocument, options);
}
export type GroupLeaveMutationHookResult = ReturnType<
	typeof useGroupLeaveMutation
>;
export type GroupLeaveMutationResult =
	ApolloReactCommon.MutationResult<GroupLeaveMutation>;
export type GroupLeaveMutationOptions = ApolloReactCommon.BaseMutationOptions<
	GroupLeaveMutation,
	GroupLeaveMutationVariables
>;
export const GroupRefreshDocument = gql`
	mutation GroupRefresh($input: MusicTogetherRefreshSuggestedTracklistInput!) {
		musicTogetherRefreshSuggestedTracklist(input: $input) {
			... on MusicTogetherRefreshSuggestedTracklistOutput {
				group {
					suggestedTracklist {
						isRefreshing
						tracklist {
							id
						}
					}
					...Group
				}
			}
			... on MusicTogetherRefreshSuggestedTracklistError {
				userNotInGroup
			}
		}
	}
	${GroupFragmentDoc}
`;
export type GroupRefreshMutationFn = ApolloReactCommon.MutationFunction<
	GroupRefreshMutation,
	GroupRefreshMutationVariables
>;

/**
 * __useGroupRefreshMutation__
 *
 * To run a mutation, you first call `useGroupRefreshMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGroupRefreshMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [groupRefreshMutation, { data, loading, error }] = useGroupRefreshMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGroupRefreshMutation(
	baseOptions?: ApolloReactHooks.MutationHookOptions<
		GroupRefreshMutation,
		GroupRefreshMutationVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return ApolloReactHooks.useMutation<
		GroupRefreshMutation,
		GroupRefreshMutationVariables
	>(GroupRefreshDocument, options);
}
export type GroupRefreshMutationHookResult = ReturnType<
	typeof useGroupRefreshMutation
>;
export type GroupRefreshMutationResult =
	ApolloReactCommon.MutationResult<GroupRefreshMutation>;
export type GroupRefreshMutationOptions = ApolloReactCommon.BaseMutationOptions<
	GroupRefreshMutation,
	GroupRefreshMutationVariables
>;
export const UpdateGroupSettingsDocument = gql`
	mutation UpdateGroupSettings($input: MusicTogetherUpdateGroupSettingsInput!) {
		musicTogetherUpdateGroupSettings(input: $input) {
			... on MusicTogetherUpdateGroupSettingsOutput {
				group {
					...Group
				}
			}
			... on MusicTogetherUpdateGroupSettingsError {
				userNotInGroup
				isInvalidGroupName
			}
		}
	}
	${GroupFragmentDoc}
`;
export type UpdateGroupSettingsMutationFn = ApolloReactCommon.MutationFunction<
	UpdateGroupSettingsMutation,
	UpdateGroupSettingsMutationVariables
>;

/**
 * __useUpdateGroupSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateGroupSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGroupSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGroupSettingsMutation, { data, loading, error }] = useUpdateGroupSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateGroupSettingsMutation(
	baseOptions?: ApolloReactHooks.MutationHookOptions<
		UpdateGroupSettingsMutation,
		UpdateGroupSettingsMutationVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return ApolloReactHooks.useMutation<
		UpdateGroupSettingsMutation,
		UpdateGroupSettingsMutationVariables
	>(UpdateGroupSettingsDocument, options);
}
export type UpdateGroupSettingsMutationHookResult = ReturnType<
	typeof useUpdateGroupSettingsMutation
>;
export type UpdateGroupSettingsMutationResult =
	ApolloReactCommon.MutationResult<UpdateGroupSettingsMutation>;
export type UpdateGroupSettingsMutationOptions =
	ApolloReactCommon.BaseMutationOptions<
		UpdateGroupSettingsMutation,
		UpdateGroupSettingsMutationVariables
	>;
export const ValidateOnboardingArtistsPickerStepDocument = gql`
	mutation ValidateOnboardingArtistsPickerStep(
		$context: OnboardingContextInput! = MUSIC_TOGETHER
		$input: ValidateOnboardingArtistsPickerStepInput!
	) {
		validateOnboardingArtistsPickerStep(context: $context, input: $input) {
			onboarding {
				shouldBeOnboarded
			}
		}
	}
`;
export type ValidateOnboardingArtistsPickerStepMutationFn =
	ApolloReactCommon.MutationFunction<
		ValidateOnboardingArtistsPickerStepMutation,
		ValidateOnboardingArtistsPickerStepMutationVariables
	>;

/**
 * __useValidateOnboardingArtistsPickerStepMutation__
 *
 * To run a mutation, you first call `useValidateOnboardingArtistsPickerStepMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateOnboardingArtistsPickerStepMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateOnboardingArtistsPickerStepMutation, { data, loading, error }] = useValidateOnboardingArtistsPickerStepMutation({
 *   variables: {
 *      context: // value for 'context'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useValidateOnboardingArtistsPickerStepMutation(
	baseOptions?: ApolloReactHooks.MutationHookOptions<
		ValidateOnboardingArtistsPickerStepMutation,
		ValidateOnboardingArtistsPickerStepMutationVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return ApolloReactHooks.useMutation<
		ValidateOnboardingArtistsPickerStepMutation,
		ValidateOnboardingArtistsPickerStepMutationVariables
	>(ValidateOnboardingArtistsPickerStepDocument, options);
}
export type ValidateOnboardingArtistsPickerStepMutationHookResult = ReturnType<
	typeof useValidateOnboardingArtistsPickerStepMutation
>;
export type ValidateOnboardingArtistsPickerStepMutationResult =
	ApolloReactCommon.MutationResult<ValidateOnboardingArtistsPickerStepMutation>;
export type ValidateOnboardingArtistsPickerStepMutationOptions =
	ApolloReactCommon.BaseMutationOptions<
		ValidateOnboardingArtistsPickerStepMutation,
		ValidateOnboardingArtistsPickerStepMutationVariables
	>;
export const ValidateOnboardingChooseNextStepStepDocument = gql`
	mutation ValidateOnboardingChooseNextStepStep(
		$context: OnboardingContextInput! = MUSIC_TOGETHER
	) {
		validateOnboardingChooseNextStepStep(context: $context) {
			onboarding {
				shouldBeOnboarded
			}
		}
	}
`;
export type ValidateOnboardingChooseNextStepStepMutationFn =
	ApolloReactCommon.MutationFunction<
		ValidateOnboardingChooseNextStepStepMutation,
		ValidateOnboardingChooseNextStepStepMutationVariables
	>;

/**
 * __useValidateOnboardingChooseNextStepStepMutation__
 *
 * To run a mutation, you first call `useValidateOnboardingChooseNextStepStepMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateOnboardingChooseNextStepStepMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateOnboardingChooseNextStepStepMutation, { data, loading, error }] = useValidateOnboardingChooseNextStepStepMutation({
 *   variables: {
 *      context: // value for 'context'
 *   },
 * });
 */
export function useValidateOnboardingChooseNextStepStepMutation(
	baseOptions?: ApolloReactHooks.MutationHookOptions<
		ValidateOnboardingChooseNextStepStepMutation,
		ValidateOnboardingChooseNextStepStepMutationVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return ApolloReactHooks.useMutation<
		ValidateOnboardingChooseNextStepStepMutation,
		ValidateOnboardingChooseNextStepStepMutationVariables
	>(ValidateOnboardingChooseNextStepStepDocument, options);
}
export type ValidateOnboardingChooseNextStepStepMutationHookResult = ReturnType<
	typeof useValidateOnboardingChooseNextStepStepMutation
>;
export type ValidateOnboardingChooseNextStepStepMutationResult =
	ApolloReactCommon.MutationResult<ValidateOnboardingChooseNextStepStepMutation>;
export type ValidateOnboardingChooseNextStepStepMutationOptions =
	ApolloReactCommon.BaseMutationOptions<
		ValidateOnboardingChooseNextStepStepMutation,
		ValidateOnboardingChooseNextStepStepMutationVariables
	>;
export const ValidateOnboardingImportLibraryStepDocument = gql`
	mutation ValidateOnboardingImportLibraryStep(
		$context: OnboardingContextInput! = MUSIC_TOGETHER
		$input: ValidateOnboardingImportLibraryStepInput!
	) {
		validateOnboardingImportLibraryStep(context: $context, input: $input) {
			onboarding {
				shouldBeOnboarded
			}
		}
	}
`;
export type ValidateOnboardingImportLibraryStepMutationFn =
	ApolloReactCommon.MutationFunction<
		ValidateOnboardingImportLibraryStepMutation,
		ValidateOnboardingImportLibraryStepMutationVariables
	>;

/**
 * __useValidateOnboardingImportLibraryStepMutation__
 *
 * To run a mutation, you first call `useValidateOnboardingImportLibraryStepMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateOnboardingImportLibraryStepMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateOnboardingImportLibraryStepMutation, { data, loading, error }] = useValidateOnboardingImportLibraryStepMutation({
 *   variables: {
 *      context: // value for 'context'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useValidateOnboardingImportLibraryStepMutation(
	baseOptions?: ApolloReactHooks.MutationHookOptions<
		ValidateOnboardingImportLibraryStepMutation,
		ValidateOnboardingImportLibraryStepMutationVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return ApolloReactHooks.useMutation<
		ValidateOnboardingImportLibraryStepMutation,
		ValidateOnboardingImportLibraryStepMutationVariables
	>(ValidateOnboardingImportLibraryStepDocument, options);
}
export type ValidateOnboardingImportLibraryStepMutationHookResult = ReturnType<
	typeof useValidateOnboardingImportLibraryStepMutation
>;
export type ValidateOnboardingImportLibraryStepMutationResult =
	ApolloReactCommon.MutationResult<ValidateOnboardingImportLibraryStepMutation>;
export type ValidateOnboardingImportLibraryStepMutationOptions =
	ApolloReactCommon.BaseMutationOptions<
		ValidateOnboardingImportLibraryStepMutation,
		ValidateOnboardingImportLibraryStepMutationVariables
	>;
export const AppendTrackToPlaylistDocument = gql`
	mutation AppendTrackToPlaylist($input: PlaylistAppendTracksMutationInput!) {
		appendTracksToPlaylist(input: $input) {
			addedTrackIds
		}
	}
`;
export type AppendTrackToPlaylistMutationFn =
	ApolloReactCommon.MutationFunction<
		AppendTrackToPlaylistMutation,
		AppendTrackToPlaylistMutationVariables
	>;

/**
 * __useAppendTrackToPlaylistMutation__
 *
 * To run a mutation, you first call `useAppendTrackToPlaylistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAppendTrackToPlaylistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [appendTrackToPlaylistMutation, { data, loading, error }] = useAppendTrackToPlaylistMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAppendTrackToPlaylistMutation(
	baseOptions?: ApolloReactHooks.MutationHookOptions<
		AppendTrackToPlaylistMutation,
		AppendTrackToPlaylistMutationVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return ApolloReactHooks.useMutation<
		AppendTrackToPlaylistMutation,
		AppendTrackToPlaylistMutationVariables
	>(AppendTrackToPlaylistDocument, options);
}
export type AppendTrackToPlaylistMutationHookResult = ReturnType<
	typeof useAppendTrackToPlaylistMutation
>;
export type AppendTrackToPlaylistMutationResult =
	ApolloReactCommon.MutationResult<AppendTrackToPlaylistMutation>;
export type AppendTrackToPlaylistMutationOptions =
	ApolloReactCommon.BaseMutationOptions<
		AppendTrackToPlaylistMutation,
		AppendTrackToPlaylistMutationVariables
	>;
export const RemoveTracksFromPlaylistDocument = gql`
	mutation RemoveTracksFromPlaylist(
		$input: PlaylistRemoveTracksMutationInput!
	) {
		removeTracksFromPlaylist(input: $input) {
			removedTrackIds
		}
	}
`;
export type RemoveTracksFromPlaylistMutationFn =
	ApolloReactCommon.MutationFunction<
		RemoveTracksFromPlaylistMutation,
		RemoveTracksFromPlaylistMutationVariables
	>;

/**
 * __useRemoveTracksFromPlaylistMutation__
 *
 * To run a mutation, you first call `useRemoveTracksFromPlaylistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTracksFromPlaylistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTracksFromPlaylistMutation, { data, loading, error }] = useRemoveTracksFromPlaylistMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveTracksFromPlaylistMutation(
	baseOptions?: ApolloReactHooks.MutationHookOptions<
		RemoveTracksFromPlaylistMutation,
		RemoveTracksFromPlaylistMutationVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return ApolloReactHooks.useMutation<
		RemoveTracksFromPlaylistMutation,
		RemoveTracksFromPlaylistMutationVariables
	>(RemoveTracksFromPlaylistDocument, options);
}
export type RemoveTracksFromPlaylistMutationHookResult = ReturnType<
	typeof useRemoveTracksFromPlaylistMutation
>;
export type RemoveTracksFromPlaylistMutationResult =
	ApolloReactCommon.MutationResult<RemoveTracksFromPlaylistMutation>;
export type RemoveTracksFromPlaylistMutationOptions =
	ApolloReactCommon.BaseMutationOptions<
		RemoveTracksFromPlaylistMutation,
		RemoveTracksFromPlaylistMutationVariables
	>;
export const ArtistsPickerDocument = gql`
	query ArtistsPicker($endCursor: String, $first: Int!) {
		onboardingSteps {
			artistsPicker {
				artists(first: $first, after: $endCursor) {
					pageInfo {
						hasNextPage
						endCursor
					}
					edges {
						node {
							...Artist
						}
					}
				}
			}
		}
	}
	${ArtistFragmentDoc}
`;

/**
 * __useArtistsPickerQuery__
 *
 * To run a query within a React component, call `useArtistsPickerQuery` and pass it any options that fit your needs.
 * When your component renders, `useArtistsPickerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArtistsPickerQuery({
 *   variables: {
 *      endCursor: // value for 'endCursor'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useArtistsPickerQuery(
	baseOptions: ApolloReactHooks.QueryHookOptions<
		ArtistsPickerQuery,
		ArtistsPickerQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return ApolloReactHooks.useQuery<
		ArtistsPickerQuery,
		ArtistsPickerQueryVariables
	>(ArtistsPickerDocument, options);
}
export function useArtistsPickerLazyQuery(
	baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
		ArtistsPickerQuery,
		ArtistsPickerQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return ApolloReactHooks.useLazyQuery<
		ArtistsPickerQuery,
		ArtistsPickerQueryVariables
	>(ArtistsPickerDocument, options);
}
export type ArtistsPickerQueryHookResult = ReturnType<
	typeof useArtistsPickerQuery
>;
export type ArtistsPickerLazyQueryHookResult = ReturnType<
	typeof useArtistsPickerLazyQuery
>;
export type ArtistsPickerQueryResult = ApolloReactCommon.QueryResult<
	ArtistsPickerQuery,
	ArtistsPickerQueryVariables
>;
export const GatekeepsDocument = gql`
	query Gatekeeps($names: [String!]!) {
		gatekeeps(names: $names) {
			gatekeeps {
				name
				isOpen
			}
		}
	}
`;

/**
 * __useGatekeepsQuery__
 *
 * To run a query within a React component, call `useGatekeepsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGatekeepsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGatekeepsQuery({
 *   variables: {
 *      names: // value for 'names'
 *   },
 * });
 */
export function useGatekeepsQuery(
	baseOptions: ApolloReactHooks.QueryHookOptions<
		GatekeepsQuery,
		GatekeepsQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return ApolloReactHooks.useQuery<GatekeepsQuery, GatekeepsQueryVariables>(
		GatekeepsDocument,
		options,
	);
}
export function useGatekeepsLazyQuery(
	baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
		GatekeepsQuery,
		GatekeepsQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return ApolloReactHooks.useLazyQuery<GatekeepsQuery, GatekeepsQueryVariables>(
		GatekeepsDocument,
		options,
	);
}
export type GatekeepsQueryHookResult = ReturnType<typeof useGatekeepsQuery>;
export type GatekeepsLazyQueryHookResult = ReturnType<
	typeof useGatekeepsLazyQuery
>;
export type GatekeepsQueryResult = ApolloReactCommon.QueryResult<
	GatekeepsQuery,
	GatekeepsQueryVariables
>;
export const GroupAffinityDocument = gql`
	query GroupAffinity($musicTogetherGroupId: ID!, $memberId: ID!) {
		musicTogetherAffinity(
			musicTogetherGroupId: $musicTogetherGroupId
			memberId: $memberId
		) {
			compatibilityScore
			discoveryTracks {
				edges {
					node {
						...Track
					}
				}
			}
			discoveryArtists {
				edges {
					node {
						...Artist
					}
				}
			}
		}
	}
	${TrackFragmentDoc}
	${ArtistFragmentDoc}
`;

/**
 * __useGroupAffinityQuery__
 *
 * To run a query within a React component, call `useGroupAffinityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupAffinityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupAffinityQuery({
 *   variables: {
 *      musicTogetherGroupId: // value for 'musicTogetherGroupId'
 *      memberId: // value for 'memberId'
 *   },
 * });
 */
export function useGroupAffinityQuery(
	baseOptions: ApolloReactHooks.QueryHookOptions<
		GroupAffinityQuery,
		GroupAffinityQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return ApolloReactHooks.useQuery<
		GroupAffinityQuery,
		GroupAffinityQueryVariables
	>(GroupAffinityDocument, options);
}
export function useGroupAffinityLazyQuery(
	baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
		GroupAffinityQuery,
		GroupAffinityQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return ApolloReactHooks.useLazyQuery<
		GroupAffinityQuery,
		GroupAffinityQueryVariables
	>(GroupAffinityDocument, options);
}
export type GroupAffinityQueryHookResult = ReturnType<
	typeof useGroupAffinityQuery
>;
export type GroupAffinityLazyQueryHookResult = ReturnType<
	typeof useGroupAffinityLazyQuery
>;
export type GroupAffinityQueryResult = ApolloReactCommon.QueryResult<
	GroupAffinityQuery,
	GroupAffinityQueryVariables
>;
export const GroupCuratedRawDocument = gql`
	query GroupCuratedRaw($musicTogetherGroupId: ID!) {
		musicTogetherGroup(musicTogetherGroupId: $musicTogetherGroupId) {
			id
			name
			curatedTracklist {
				...CuratedPlaylist
			}
		}
	}
	${CuratedPlaylistFragmentDoc}
`;

/**
 * __useGroupCuratedRawQuery__
 *
 * To run a query within a React component, call `useGroupCuratedRawQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupCuratedRawQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupCuratedRawQuery({
 *   variables: {
 *      musicTogetherGroupId: // value for 'musicTogetherGroupId'
 *   },
 * });
 */
export function useGroupCuratedRawQuery(
	baseOptions: ApolloReactHooks.QueryHookOptions<
		GroupCuratedRawQuery,
		GroupCuratedRawQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return ApolloReactHooks.useQuery<
		GroupCuratedRawQuery,
		GroupCuratedRawQueryVariables
	>(GroupCuratedRawDocument, options);
}
export function useGroupCuratedRawLazyQuery(
	baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
		GroupCuratedRawQuery,
		GroupCuratedRawQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return ApolloReactHooks.useLazyQuery<
		GroupCuratedRawQuery,
		GroupCuratedRawQueryVariables
	>(GroupCuratedRawDocument, options);
}
export type GroupCuratedRawQueryHookResult = ReturnType<
	typeof useGroupCuratedRawQuery
>;
export type GroupCuratedRawLazyQueryHookResult = ReturnType<
	typeof useGroupCuratedRawLazyQuery
>;
export type GroupCuratedRawQueryResult = ApolloReactCommon.QueryResult<
	GroupCuratedRawQuery,
	GroupCuratedRawQueryVariables
>;
export const GroupCuratedTracklistDocument = gql`
	query GroupCuratedTracklist(
		$musicTogetherGroupId: ID!
		$endCursor: String
		$first: Int = 50
	) {
		musicTogetherGroup(musicTogetherGroupId: $musicTogetherGroupId) {
			curatedTracklist {
				id
				tracks(first: $first, after: $endCursor) {
					pageInfo {
						hasNextPage
						endCursor
					}
					edges {
						node {
							...Track
						}
						actor {
							id
							name
							picture {
								medium: urls(pictureRequest: {height: 200, width: 200})
							}
						}
					}
				}
			}
		}
	}
	${TrackFragmentDoc}
`;

/**
 * __useGroupCuratedTracklistQuery__
 *
 * To run a query within a React component, call `useGroupCuratedTracklistQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupCuratedTracklistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupCuratedTracklistQuery({
 *   variables: {
 *      musicTogetherGroupId: // value for 'musicTogetherGroupId'
 *      endCursor: // value for 'endCursor'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useGroupCuratedTracklistQuery(
	baseOptions: ApolloReactHooks.QueryHookOptions<
		GroupCuratedTracklistQuery,
		GroupCuratedTracklistQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return ApolloReactHooks.useQuery<
		GroupCuratedTracklistQuery,
		GroupCuratedTracklistQueryVariables
	>(GroupCuratedTracklistDocument, options);
}
export function useGroupCuratedTracklistLazyQuery(
	baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
		GroupCuratedTracklistQuery,
		GroupCuratedTracklistQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return ApolloReactHooks.useLazyQuery<
		GroupCuratedTracklistQuery,
		GroupCuratedTracklistQueryVariables
	>(GroupCuratedTracklistDocument, options);
}
export type GroupCuratedTracklistQueryHookResult = ReturnType<
	typeof useGroupCuratedTracklistQuery
>;
export type GroupCuratedTracklistLazyQueryHookResult = ReturnType<
	typeof useGroupCuratedTracklistLazyQuery
>;
export type GroupCuratedTracklistQueryResult = ApolloReactCommon.QueryResult<
	GroupCuratedTracklistQuery,
	GroupCuratedTracklistQueryVariables
>;
export const GroupInfoDocument = gql`
	query GroupInfo($musicTogetherGroupId: ID!) {
		musicTogetherGroup(musicTogetherGroupId: $musicTogetherGroupId) {
			id
			name
			isReady
			isFamily
			members {
				edges {
					affinity {
						compatibilityScore
					}
					node {
						...Member
					}
				}
			}
			affinitiesStory {
				storyId
				placeholders {
					key
					value
				}
			}
		}
	}
	${MemberFragmentDoc}
`;

/**
 * __useGroupInfoQuery__
 *
 * To run a query within a React component, call `useGroupInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupInfoQuery({
 *   variables: {
 *      musicTogetherGroupId: // value for 'musicTogetherGroupId'
 *   },
 * });
 */
export function useGroupInfoQuery(
	baseOptions: ApolloReactHooks.QueryHookOptions<
		GroupInfoQuery,
		GroupInfoQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return ApolloReactHooks.useQuery<GroupInfoQuery, GroupInfoQueryVariables>(
		GroupInfoDocument,
		options,
	);
}
export function useGroupInfoLazyQuery(
	baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
		GroupInfoQuery,
		GroupInfoQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return ApolloReactHooks.useLazyQuery<GroupInfoQuery, GroupInfoQueryVariables>(
		GroupInfoDocument,
		options,
	);
}
export type GroupInfoQueryHookResult = ReturnType<typeof useGroupInfoQuery>;
export type GroupInfoLazyQueryHookResult = ReturnType<
	typeof useGroupInfoLazyQuery
>;
export type GroupInfoQueryResult = ApolloReactCommon.QueryResult<
	GroupInfoQuery,
	GroupInfoQueryVariables
>;
export const GroupSuggestionTracklistDocument = gql`
	query GroupSuggestionTracklist(
		$musicTogetherGroupId: ID!
		$endCursor: String
		$first: Int = 50
		$mood: MusicTogetherSuggestedTracklistMoodInput! = NONE
	) {
		musicTogetherGroup(musicTogetherGroupId: $musicTogetherGroupId) {
			suggestedTracklist(mood: $mood) {
				isRefreshing
				tracklist {
					id
					tracks(first: $first, after: $endCursor) {
						pageInfo {
							hasNextPage
							endCursor
						}
						edges {
							node {
								...Track
							}
							metadata {
								musicTogether {
									trackOrigin {
										pickedFromFavoriteTracks
										streamedPastYear
										recentlyStreamedOnRepeat
										pickedFromFavoriteArtists
										member {
											...Member
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
	${TrackFragmentDoc}
	${MemberFragmentDoc}
`;

/**
 * __useGroupSuggestionTracklistQuery__
 *
 * To run a query within a React component, call `useGroupSuggestionTracklistQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupSuggestionTracklistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupSuggestionTracklistQuery({
 *   variables: {
 *      musicTogetherGroupId: // value for 'musicTogetherGroupId'
 *      endCursor: // value for 'endCursor'
 *      first: // value for 'first'
 *      mood: // value for 'mood'
 *   },
 * });
 */
export function useGroupSuggestionTracklistQuery(
	baseOptions: ApolloReactHooks.QueryHookOptions<
		GroupSuggestionTracklistQuery,
		GroupSuggestionTracklistQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return ApolloReactHooks.useQuery<
		GroupSuggestionTracklistQuery,
		GroupSuggestionTracklistQueryVariables
	>(GroupSuggestionTracklistDocument, options);
}
export function useGroupSuggestionTracklistLazyQuery(
	baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
		GroupSuggestionTracklistQuery,
		GroupSuggestionTracklistQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return ApolloReactHooks.useLazyQuery<
		GroupSuggestionTracklistQuery,
		GroupSuggestionTracklistQueryVariables
	>(GroupSuggestionTracklistDocument, options);
}
export type GroupSuggestionTracklistQueryHookResult = ReturnType<
	typeof useGroupSuggestionTracklistQuery
>;
export type GroupSuggestionTracklistLazyQueryHookResult = ReturnType<
	typeof useGroupSuggestionTracklistLazyQuery
>;
export type GroupSuggestionTracklistQueryResult = ApolloReactCommon.QueryResult<
	GroupSuggestionTracklistQuery,
	GroupSuggestionTracklistQueryVariables
>;
export const MeDocument = gql`
	query Me {
		me {
			user {
				id
				name
				picture {
					medium: urls(pictureRequest: {height: 200, width: 200})
				}
			}
			mediaServiceLicenseToken {
				token
				expirationDate
			}
			onboarding(context: MUSIC_TOGETHER) {
				shouldBeOnboarded
				currentStep
			}
		}
		onboardingSteps(context: MUSIC_TOGETHER) {
			chooseNextStep {
				steps
			}
		}
	}
`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(
	baseOptions?: ApolloReactHooks.QueryHookOptions<MeQuery, MeQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return ApolloReactHooks.useQuery<MeQuery, MeQueryVariables>(
		MeDocument,
		options,
	);
}
export function useMeLazyQuery(
	baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
		MeQuery,
		MeQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return ApolloReactHooks.useLazyQuery<MeQuery, MeQueryVariables>(
		MeDocument,
		options,
	);
}
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = ApolloReactCommon.QueryResult<
	MeQuery,
	MeQueryVariables
>;
export const MeGroupsDocument = gql`
	query MeGroups($first: Int!) {
		me {
			musicTogetherGroups(first: $first) {
				edges {
					node {
						...Group
					}
				}
			}
		}
	}
	${GroupFragmentDoc}
`;

/**
 * __useMeGroupsQuery__
 *
 * To run a query within a React component, call `useMeGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeGroupsQuery({
 *   variables: {
 *      first: // value for 'first'
 *   },
 * });
 */
export function useMeGroupsQuery(
	baseOptions: ApolloReactHooks.QueryHookOptions<
		MeGroupsQuery,
		MeGroupsQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return ApolloReactHooks.useQuery<MeGroupsQuery, MeGroupsQueryVariables>(
		MeGroupsDocument,
		options,
	);
}
export function useMeGroupsLazyQuery(
	baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
		MeGroupsQuery,
		MeGroupsQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return ApolloReactHooks.useLazyQuery<MeGroupsQuery, MeGroupsQueryVariables>(
		MeGroupsDocument,
		options,
	);
}
export type MeGroupsQueryHookResult = ReturnType<typeof useMeGroupsQuery>;
export type MeGroupsLazyQueryHookResult = ReturnType<
	typeof useMeGroupsLazyQuery
>;
export type MeGroupsQueryResult = ApolloReactCommon.QueryResult<
	MeGroupsQuery,
	MeGroupsQueryVariables
>;
export const RelatedArtistsDocument = gql`
	query RelatedArtists(
		$endCursor: String
		$first: Int!
		$artistId: String!
		$excludedArtistIds: [String!]
	) {
		onboardingSteps {
			artistsPicker {
				relatedArtists(
					first: $first
					after: $endCursor
					artistId: $artistId
					excludedArtistIds: $excludedArtistIds
				) {
					pageInfo {
						hasNextPage
						endCursor
					}
					edges {
						node {
							...Artist
						}
					}
				}
			}
		}
	}
	${ArtistFragmentDoc}
`;

/**
 * __useRelatedArtistsQuery__
 *
 * To run a query within a React component, call `useRelatedArtistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRelatedArtistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRelatedArtistsQuery({
 *   variables: {
 *      endCursor: // value for 'endCursor'
 *      first: // value for 'first'
 *      artistId: // value for 'artistId'
 *      excludedArtistIds: // value for 'excludedArtistIds'
 *   },
 * });
 */
export function useRelatedArtistsQuery(
	baseOptions: ApolloReactHooks.QueryHookOptions<
		RelatedArtistsQuery,
		RelatedArtistsQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return ApolloReactHooks.useQuery<
		RelatedArtistsQuery,
		RelatedArtistsQueryVariables
	>(RelatedArtistsDocument, options);
}
export function useRelatedArtistsLazyQuery(
	baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
		RelatedArtistsQuery,
		RelatedArtistsQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return ApolloReactHooks.useLazyQuery<
		RelatedArtistsQuery,
		RelatedArtistsQueryVariables
	>(RelatedArtistsDocument, options);
}
export type RelatedArtistsQueryHookResult = ReturnType<
	typeof useRelatedArtistsQuery
>;
export type RelatedArtistsLazyQueryHookResult = ReturnType<
	typeof useRelatedArtistsLazyQuery
>;
export type RelatedArtistsQueryResult = ApolloReactCommon.QueryResult<
	RelatedArtistsQuery,
	RelatedArtistsQueryVariables
>;
export const SearchArtistsDocument = gql`
	query SearchArtists($query: String!) {
		search(query: $query) {
			results {
				artists {
					edges {
						node {
							...Artist
						}
					}
				}
			}
		}
	}
	${ArtistFragmentDoc}
`;

/**
 * __useSearchArtistsQuery__
 *
 * To run a query within a React component, call `useSearchArtistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchArtistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchArtistsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useSearchArtistsQuery(
	baseOptions: ApolloReactHooks.QueryHookOptions<
		SearchArtistsQuery,
		SearchArtistsQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return ApolloReactHooks.useQuery<
		SearchArtistsQuery,
		SearchArtistsQueryVariables
	>(SearchArtistsDocument, options);
}
export function useSearchArtistsLazyQuery(
	baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
		SearchArtistsQuery,
		SearchArtistsQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return ApolloReactHooks.useLazyQuery<
		SearchArtistsQuery,
		SearchArtistsQueryVariables
	>(SearchArtistsDocument, options);
}
export type SearchArtistsQueryHookResult = ReturnType<
	typeof useSearchArtistsQuery
>;
export type SearchArtistsLazyQueryHookResult = ReturnType<
	typeof useSearchArtistsLazyQuery
>;
export type SearchArtistsQueryResult = ApolloReactCommon.QueryResult<
	SearchArtistsQuery,
	SearchArtistsQueryVariables
>;
export const SearchTracksDocument = gql`
	query SearchTracks($query: String!) {
		search(query: $query) {
			results {
				tracks {
					edges {
						node {
							...Track
						}
					}
				}
			}
		}
	}
	${TrackFragmentDoc}
`;

/**
 * __useSearchTracksQuery__
 *
 * To run a query within a React component, call `useSearchTracksQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchTracksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchTracksQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useSearchTracksQuery(
	baseOptions: ApolloReactHooks.QueryHookOptions<
		SearchTracksQuery,
		SearchTracksQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return ApolloReactHooks.useQuery<
		SearchTracksQuery,
		SearchTracksQueryVariables
	>(SearchTracksDocument, options);
}
export function useSearchTracksLazyQuery(
	baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
		SearchTracksQuery,
		SearchTracksQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return ApolloReactHooks.useLazyQuery<
		SearchTracksQuery,
		SearchTracksQueryVariables
	>(SearchTracksDocument, options);
}
export type SearchTracksQueryHookResult = ReturnType<
	typeof useSearchTracksQuery
>;
export type SearchTracksLazyQueryHookResult = ReturnType<
	typeof useSearchTracksLazyQuery
>;
export type SearchTracksQueryResult = ApolloReactCommon.QueryResult<
	SearchTracksQuery,
	SearchTracksQueryVariables
>;
export const TokensDocument = gql`
	query Tokens {
		tokens {
			mediaServiceLicenseToken {
				token
				expirationDate
			}
			recToken {
				token
				expirationDate
			}
		}
	}
`;

/**
 * __useTokensQuery__
 *
 * To run a query within a React component, call `useTokensQuery` and pass it any options that fit your needs.
 * When your component renders, `useTokensQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTokensQuery({
 *   variables: {
 *   },
 * });
 */
export function useTokensQuery(
	baseOptions?: ApolloReactHooks.QueryHookOptions<
		TokensQuery,
		TokensQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return ApolloReactHooks.useQuery<TokensQuery, TokensQueryVariables>(
		TokensDocument,
		options,
	);
}
export function useTokensLazyQuery(
	baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
		TokensQuery,
		TokensQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return ApolloReactHooks.useLazyQuery<TokensQuery, TokensQueryVariables>(
		TokensDocument,
		options,
	);
}
export type TokensQueryHookResult = ReturnType<typeof useTokensQuery>;
export type TokensLazyQueryHookResult = ReturnType<typeof useTokensLazyQuery>;
export type TokensQueryResult = ApolloReactCommon.QueryResult<
	TokensQuery,
	TokensQueryVariables
>;
export const PlaylistDocument = gql`
	query Playlist($playlistId: String!) {
		playlist(playlistId: $playlistId) {
			tracks {
				edges {
					node {
						...Track
					}
				}
			}
		}
	}
	${TrackFragmentDoc}
`;

/**
 * __usePlaylistQuery__
 *
 * To run a query within a React component, call `usePlaylistQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlaylistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlaylistQuery({
 *   variables: {
 *      playlistId: // value for 'playlistId'
 *   },
 * });
 */
export function usePlaylistQuery(
	baseOptions: ApolloReactHooks.QueryHookOptions<
		PlaylistQuery,
		PlaylistQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return ApolloReactHooks.useQuery<PlaylistQuery, PlaylistQueryVariables>(
		PlaylistDocument,
		options,
	);
}
export function usePlaylistLazyQuery(
	baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
		PlaylistQuery,
		PlaylistQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return ApolloReactHooks.useLazyQuery<PlaylistQuery, PlaylistQueryVariables>(
		PlaylistDocument,
		options,
	);
}
export type PlaylistQueryHookResult = ReturnType<typeof usePlaylistQuery>;
export type PlaylistLazyQueryHookResult = ReturnType<
	typeof usePlaylistLazyQuery
>;
export type PlaylistQueryResult = ApolloReactCommon.QueryResult<
	PlaylistQuery,
	PlaylistQueryVariables
>;
export const TrackDocument = gql`
	query Track($trackId: String!) {
		track(trackId: $trackId) {
			...Track
			media {
				token {
					payload
					expiresAt
				}
			}
		}
	}
	${TrackFragmentDoc}
`;

/**
 * __useTrackQuery__
 *
 * To run a query within a React component, call `useTrackQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrackQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrackQuery({
 *   variables: {
 *      trackId: // value for 'trackId'
 *   },
 * });
 */
export function useTrackQuery(
	baseOptions: ApolloReactHooks.QueryHookOptions<
		TrackQuery,
		TrackQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return ApolloReactHooks.useQuery<TrackQuery, TrackQueryVariables>(
		TrackDocument,
		options,
	);
}
export function useTrackLazyQuery(
	baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
		TrackQuery,
		TrackQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return ApolloReactHooks.useLazyQuery<TrackQuery, TrackQueryVariables>(
		TrackDocument,
		options,
	);
}
export type TrackQueryHookResult = ReturnType<typeof useTrackQuery>;
export type TrackLazyQueryHookResult = ReturnType<typeof useTrackLazyQuery>;
export type TrackQueryResult = ApolloReactCommon.QueryResult<
	TrackQuery,
	TrackQueryVariables
>;
